import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, EmployeeContext, PositionContext, PayrollContext, FieldContext } from "../contexts";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

// https://primereact.org/datatable/
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from 'primereact/calendar';

const Payroll = () => {

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const payrollContext = useContext(PayrollContext);
  const {payroll, conditions} = payrollContext;

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  const [filters, setFilters] = useState({
    employee_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    field_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    position_id: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'employee_id', header: 'Employee', visible: true },
    { field: 'position_id', header: 'Position', visible: false },
    { field: 'base', header: 'Base', visible: true },
    { field: 'premium', header: 'Premium', visible: true },
    { field: 'benefit', header: 'Benefit', visible: false },
    { field: 'contract', header: 'Contract', visible: true },
    { field: 'htm', header: 'H(TM)', visible: false },
    { field: 'htmk', header: 'H(TMK)', visible: false },
    { field: 'htbm', header: 'H(TBM)', visible: false },
    { field: 'hbr', header: 'H(BR)', visible: false },
    { field: 'hsort', header: 'H(Sort)', visible: false },
    { field: 'hraw', header: 'H(Raw)', visible: false },
    { field: 'hxtr', header: 'H(Extra)', visible: false },
    { field: 'harvest', header: 'Harvest', visible: true },
    { field: 'lcash', header: 'L(Cash)', visible: false },
    { field: 'lpetrol', header: 'L(Petrol)', visible: false },
    { field: 'loans', header: 'Loans', visible: true },
    { field: 'total', header: 'Total', visible: true },
  ]);

  const [defaultVisibleColumns, setDefaultVisibleColumns] = useState([
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'employee_id', header: 'Employee', visible: true },
    { field: 'position_id', header: 'Position', visible: false },
    { field: 'base', header: 'Base', visible: true },
    { field: 'premium', header: 'Premium', visible: true },
    { field: 'benefit', header: 'Benefit', visible: false },
    { field: 'contract', header: 'Contract', visible: true },
    { field: 'htm', header: 'H(TM)', visible: false },
    { field: 'htmk', header: 'H(TMK)', visible: false },
    { field: 'htbm', header: 'H(TBM)', visible: false },
    { field: 'hbr', header: 'H(BR)', visible: false },
    { field: 'hsort', header: 'H(Sort)', visible: false },
    { field: 'hraw', header: 'H(Raw)', visible: false },
    { field: 'hxtr', header: 'H(Extra)', visible: false },
    { field: 'harvest', header: 'Harvest', visible: true },
    { field: 'lcash', header: 'L(Cash)', visible: false },
    { field: 'lpetrol', header: 'L(Petrol)', visible: false },
    { field: 'loans', header: 'Loans', visible: true },
    { field: 'total', header: 'Total', visible: true },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };
  
  useEffect(() => {
    async function fetchData() {
      if (!(positions?.length > 0) && refreshCall) { positionContext.refreshData(); }
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(employees?.length > 0) && refreshCall) { employeeContext.refreshData(); }
      if (!(payroll?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [payroll, positions, fields, payrollContext, positionContext, fieldContext, refreshCall]);
  
  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const employeeTemplate = (paybreak) => {
    return employees?.length > 0 && paybreak.employee_id ?
        employees?.find(i => i.id === parseInt(paybreak.employee_id))?.name + (positions?.length > 0 && paybreak.position_id ? " ( " + positions?.find(i => i.id === parseInt(paybreak.position_id))?.name + " )": "")
      : "";
  };

  const employeeFilter = () => {
    // Define the itemTemplate to display employee name with position
    const dropdownItemTemplate = (employee) => {
        const positionName = positions?.find(p => p.id === parseInt(employee.position_id))?.name || "";
        return (
            <div>
                {employee.name} {positionName && `(${positionName})`}
            </div>
        );
    };

    // Sort employees by name alphabetically
    const sortedEmployees = [...employees].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <Dropdown
            value={employees?.find(i => i.id === parseInt(filters.employee_id.value))}
            onChange={(e) => {
                const newFilters = { ...filters };
                newFilters.employee_id.value = e.value.id;
                setFilters(newFilters);
            }}
            options={sortedEmployees}
            itemTemplate={dropdownItemTemplate}
            optionLabel="name"
            placeholder="Search by Employee"
            className="w-full md:w-14rem"
        />
    );
  };

  const fieldTemplate = (paybreak) => {
    return fields?.length > 0 && paybreak.field_id ? fields?.find(i => i.id === parseInt(paybreak.field_id))?.name : "";
  };

  const fieldFilter = () => {
    return (
      <Dropdown
        value={fields?.find(i => i.id === parseInt(filters.field_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.field_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={fields}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const positionTemplate = (paybreak) => {
    return positions?.length > 0 && paybreak.position_id ? positions?.find(i => i.id === parseInt(paybreak.position_id))?.name : "";
  };

  const numberTemplate = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  const positionFilter = () => {
    return (
      <Dropdown
        value={fields?.find(i => i.id === parseInt(filters.position_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.position_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={positions}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Position"
        className="w-full md:w-14rem"
      />
    );
  }

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await payrollContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Payroll</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="visibleColumn">Column</label>
                      <MultiSelect
                        id="visibleColumn"
                        value={visibleColumns.filter((col) => col.visible).map((col) => col.field)}
                        options={visibleColumns.map((col) => ({ label: col.header, value: col.field }))}
                        onChange={(e) => toggleColumnsVisibility(e.value)}
                        placeholder="Select Columns"
                      />
                    </div>
                  </div>
                </div>
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                setVisibleColumns(defaultVisibleColumns);
                              }}
                            >
                              reset column
                            </span>
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                const newFilters = { ...filters };
                                newFilters.employee_id.value = null;
                                setFilters(newFilters);
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="link-isday">refresh</span>
                          </div>
                        </div>
                        <hr />
                        <DataTable
                          value={payroll}
                          stripedRows
                          removableSort
                          columnResizeMode="expand"
                          resizableColumns
                          tableStyle={{ minWidth: '50rem' }}
                          filters={filters}
                          dataKey="id"
                          emptyMessage="No payroll found."
                        >
                          {visibleColumns.map((col) => (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={(rowData) => (col.field === 'field_id' ? fieldFilter(rowData) : (col.field === 'employee_id' ? employeeFilter(rowData) : (col.field === 'position_id' ? positionFilter(rowData) : col.header)))}
                              body={(rowData) => (col.field === 'field_id' ? fieldTemplate(rowData) : (col.field === 'employee_id' ? employeeTemplate(rowData) : (col.field === 'position_id' ? positionTemplate(rowData) : numberTemplate(rowData[col.field]))))}
                              style={{ display: col.visible ? 'table-cell' : 'none' }}
                            />
                          ))}
                        </DataTable>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payroll;
