import React, { useContext, useEffect, useRef, useState } from "react";
import AxiosInstance from "../axios";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate, useParams } from "react-router-dom";
import { AddFileImageModal, EditFileModal, VerifyFileModal } from "./modals";
import { AllContext, FileSingleContext, FieldContext, UserAAContext, UserContext, useToastContext } from "../contexts";
import { baseURL, imageBaseURL } from "../axios";

import { Galleria } from "primereact/galleria";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const FileView = () => {
  
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const galleria = useRef(null);
  
  const userContext = useContext(UserContext);
  const {users} = userContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice} = userAAContext;
  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const fileSingleContext = useContext(FileSingleContext);
  const {fileSingle} = fileSingleContext;
  
  let { file_id } = useParams();
  const [fModal, setFModal] = useState(Math.round((new Date()).getTime()));
  const [fileImages, setFileImages] = useState([]);
  const [fileStage, setFileStage] = useState([]);
  const [activeImage, setActiveImage] = useState("");

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0)) { fieldContext.refreshData(); }
      if (!(users?.length > 0)) { userContext.refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [refreshCall]);

  useEffect(() => {
    function fetchData() {
      if ( fileSingle && fileSingle.stage ) {
        setFileImages(fileSingle?.file_images_grouped);
        setFileStage(fileSingle?.history_stage);
      }
    }
    fetchData();
  }, [fileSingle]);

  useEffect(() => {
    async function fetchData() {
      new Promise(function(resolve, reject) {
        setShowLoading(true);
        resolve(1);
      }).then(async function(result) {
        const getFileSingle = await AxiosInstance.get(
          `/file/${file_id}`,
          {
            cache: {
              ignoreCache: true,
            },
          }
        ).catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
  
        if(getFileSingle){
          fileSingleContext.addData(getFileSingle?.data);
        }
        return 1;
      }).finally(function(result) {
        setShowLoading(false);
      });
    }
    fetchData();
  }, [fModal]);

  useEffect(() => {
    async function fetchData() {
      userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
    }
    fetchData();
  }, [userDevice]);

  // Columns
  const [visibleStageColumns, setVisibleStageColumns] = useState([
    { field: 'created_at', header: 'Date', visible: true },
    { field: 'changed_to', header: 'Action', visible: true },
    { field: 'changed_by', header: 'By', visible: true },
  ]);

  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: true },
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'file_date', header: 'Date', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: true },
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'file_date', header: 'Date', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: false },
    { field: 'field_id', header: 'Field', visible: false },
    { field: 'file_date', header: 'Date', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  // Template
  const itemTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/file-images/large/${file_id}/${activeImage}`} style={{ width: '100%', display: 'block' }} />;
  }

  const thumbnailTemplate = (item) => {
      return <img src={`${imageBaseURL}uploads/file-images/thumb/${file_id}/${activeImage}`} style={{ display: 'block' }} />;
  }

  const groupedImages = {
    fileabsensi: fileImages && fileImages?.fileabsensi && fileImages?.fileabsensi?.map((image) => ({
        itemImageSrc: image.image,
        alt: image.id
    })) || [],
    fileperawatan: fileImages && fileImages?.fileperawatan && fileImages?.fileperawatan?.map((image) => ({
        itemImageSrc: image.image,
        alt: image.id
    })) || [],
    filebuah: fileImages && fileImages?.filebuah && fileImages?.filebuah?.map((image) => ({
        itemImageSrc: image.image,
        alt: image.id
    })) || [],
    filebarang: fileImages && fileImages?.filebarang && fileImages?.filebarang?.map((image) => ({
        itemImageSrc: image.image,
        alt: image.id
    })) || [],
    filepalang: fileImages && fileImages?.filepalang && fileImages?.filepalang?.map((image) => ({
        itemImageSrc: image.image,
        alt: image.id
    })) || [],
    fileuang: fileImages && fileImages?.fileuang && fileImages?.fileuang?.map((image) => ({
        itemImageSrc: image.image,
        alt: image.id
    })) || [],
    filelain: fileImages && fileImages?.filelain && fileImages?.filelain?.map((image) => ({
        itemImageSrc: image.image,
        alt: image.id
    })) || [],
    filerefisi: fileImages && fileImages?.filerefisi && fileImages?.filerefisi?.map((image) => ({
        itemImageSrc: image.image,
        alt: image.id
    })) || []
  };

  const imageGroups = [
      'fileabsensi',
      'fileperawatan',
      'filebuah',
      'filebarang',
      'filepalang',
      'fileuang',
      'filelain',
      'filerefisi'
  ];

  const defaultGroup = imageGroups.find(group => groupedImages[group]?.length > 0);

  const actionTemplate = (stage) => {
    return (
      stage && stage?.changed_to ? (
        stage?.changed_to === "approved" || stage?.changed_to === "approvedbysystem" ?
          "Approve" : (
            stage?.changed_to === "verified" || stage?.changed_to === "verifiedbysystem" ?
              "Verify" : 
                "Submit"
          )
      ) : "");
  };

  const typeTemplate = (id) => {
    return allOption["typeOfFile"]?.find(i => i.id === id)?.name;
  };

  const byTemplate = (stage) => {
    return users?.length > 0 && stage.changed_by && users?.find(i => i.id === parseInt(stage.changed_by)) ? users?.find(i => i.id === parseInt(stage.changed_by))?.name : "";
  };

  const dtTemplate = (dt) => {
    const date = new Date(dt);
    const options = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  return (
    <div className="container bg-white content-container">
      <div className="row text-left">
        <span className="link-isday" onClick={()=>navigate(-1)}>
          &lt; Back
        </span>
      </div>
      {
        showLoading
        ?
          <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
          </div>
        : 
          (
            <>
              <h2 className="h5 pt-3 pb-3 d-flex justify-content-center">File</h2>
              <div className="row d-flex justify-content-center">
              <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Information</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <EditFileModal sRC={setFModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Date</td>
                              <td>{fileSingle && fileSingle.file_date ? fileSingle.file_date : ""}</td>
                            </tr>
                            <tr>
                              <td>Field</td>
                              <td>{fileSingle && fields?.length > 0 && fileSingle.field_id && fields?.find(i => i.id === parseInt(fileSingle.field_id)) && fields?.find(i => i.id === parseInt(fileSingle.field_id))?.name}</td>
                            </tr>
                            <tr>
                              <td>Notes</td>
                              <td>{fileSingle && fields?.length > 0 && fileSingle.notes && fileSingle.notes}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-4">
                    <div className="card-body pb-0">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 container-stage d-flex align-items-center justify-content-center">
                        {
                          fileSingle.stage === "approved" || fileSingle.stage === "approvedbysystem"
                            ? <i className="pi pi-check" style={{ fontSize: "7rem", color: "green" }} />
                              : fileSingle.stage === "verified" || fileSingle.stage === "verifiedbysystem"
                                ? <i className="pi pi-eye" style={{ fontSize: "7rem", color: "orange" }} />
                                  : fileSingle.stage === "submitted"
                                    ? <i className="pi pi-eye-slash" style={{ fontSize: "7rem", color: "red" }} />
                                      : <i className="pi pi-clock" style={{ fontSize: "7rem", color: "grey" }} />
                        }
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row mb-2">
                        <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                          <h3 className="h6">Stage</h3>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-7 col-7 d-flex justify-content-end small">
                          <span className="link-secondary"><VerifyFileModal sRC={setFModal} /></span>
                        </div>
                      </div>
                      <hr />
                      <DataTable
                        value={fileStage}
                        stripedRows
                        removableSort
                        rows={20}
                        columnResizeMode="expand"
                        resizableColumns
                        dataKey="id"
                        emptyMessage="No Stage found."
                      >
                        {
                          visibleStageColumns.map((col, index) => (
                            <Column
                              key={`${index}-${col.field}`}
                              field={col.field}
                              header={col.header}
                              body={(rowData) => (
                                col.field === 'created_at' ?
                                  dtTemplate(rowData?.created_at) :
                                    (
                                      col.field === 'changed_to' ?
                                        actionTemplate(rowData) :
                                          (
                                            col.field === 'changed_by' ?
                                              byTemplate(rowData) :
                                                rowData[col.field]
                                          )
                                    )
                                )
                              }
                              style={{
                                display: col.visible ? 'table-cell' : 'none',
                                whiteSpace: 'normal',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '10px'
                              }}
                              showFilterMenu={false}
                            />
                          ))
                        }
                      </DataTable>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Image</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddFileImageModal sRC={setFModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="flex justify-content-center pt-3">
                        <Galleria
                            ref={galleria}
                            value={groupedImages?.[defaultGroup]}
                            numVisible={7}
                            style={{ maxWidth: '850px' }}
                            circular
                            fullScreen
                            showThumbnails={false}
                            item={itemTemplate}
                            thumbnail={thumbnailTemplate}
                        />
                        <div className="grid" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'flex-start' }}> {/* maxWidth: '400px',  */}
                          {Object.keys(groupedImages).map((groupName, groupIndex) => (
                            groupedImages[groupName] && groupedImages[groupName].length > 0 && (
                              <div key={groupIndex} style={{ width: '100%' }} className={`mt-1`}>
                                <h6>{typeTemplate(groupName)}</h6>
                                <div className="image-row" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'flex-start' }}>
                                    {groupedImages[groupName]?.map((image, index) => (
                                        <div key={index} className="col-3" style={{ flex: '0 0 calc(25% - 10px)', maxWidth: 'calc(25% - 10px)' }}>
                                            <div style={{ width: '100%', position: 'relative', paddingBottom: '100%', overflow: 'hidden', border: '1px solid #ccc' }}>
                                                <img
                                                    src={`${imageBaseURL}uploads/file-images/medium/${file_id}/${image.itemImageSrc}`}
                                                    alt={image.alt}
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        position: 'absolute',
                                                        top: '0',
                                                        left: '0',
                                                    }}
                                                    onClick={() => {
                                                        setActiveImage(image.itemImageSrc);
                                                        galleria.current.show();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {groupIndex !== Object.keys(fileImages).length - 1 && <hr className={`mt-4`} />}
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
      }
    </div>
  );
};

export default FileView;