import React, { createContext, useState} from "react";
// import { useToastContext } from "../contexts";
import AxiosInstance from "../axios";

export const StockShopContext = createContext();

export const StockShopState = (props) => {
  const [stockShop, setStockShop] = useState([]);

  async function refreshData() {
    const response = await StockShopEntries();
    setStockShop(response);
  }

  return (
    <StockShopContext.Provider value={{stockShop, refreshData}}>
      {props.children}
    </StockShopContext.Provider>
  )
}

const StockShopEntries = async () => {
  
  // const { showToast } = useToastContext();
  let retVal = [];

  const getStockShop = await AxiosInstance.get(
    `stockshop`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
    // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
    return false;
  });

  if (getStockShop) {
    retVal = getStockShop?.data;
  }

  return retVal;

}