import React, { useState, useContext, useEffect } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { StockMovementSingleContext, useToastContext } from "../../contexts";

const AddStockMovementImageModal = (props) => {
  
  const { showToast } = useToastContext();

  const stockMovementSingleContext = useContext(StockMovementSingleContext);
  const {stockMovementSingle} = stockMovementSingleContext;
  
  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [smId, setSMId] = useState("");
  const [smStage, setSMStage] = useState("");
  const [smImage, setSMImage] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      if ( stockMovementSingle && stockMovementSingle.id ) {
        setSMId(stockMovementSingle.id);
        setSMStage(stockMovementSingle.stage);
      }
    }
    fetchData();
  }, [stockMovementSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!smId) {
      notif = "Please refresh the page.";
    } else if (!smImage) {
      notif = "Image must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {
      let formData = new FormData();
      formData.append("stock_movement_id", smId);
      formData.append("fimage", smImage);

      const callAPI = await AxiosInstance.post("/stockmovementimage", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setSMImage("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      {
        smStage !== "approved" && smStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            add new
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="category">Image</label>
        </div>
        <div className="form-group mb-2">
          <input
            type="file"
            onChange={(_) => {
              setSMImage(_.target.files[0]);
            }} 
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddStockMovementImageModal;
