import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { BlockContext, EmployeeContext, MachineContext, WorkSingleContext, useToastContext } from "../../contexts";

const DeleteWorkListAssistanceModal = (props) => {
  
  const { showToast } = useToastContext();

  const workSingleContext = useContext(WorkSingleContext);
  const {workSingle} = workSingleContext;

  const blockContext = useContext(BlockContext);
  const {blocks} = blockContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [workStage, setWorkStage] = useState("");

  const [selectedWSL, setSelectedWSL] = useState([]);
  const [selectedWSLA, setSelectedWSLA] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setSelectedWSL(props && props.wsl ? props.wsl : []);
      setSelectedWSLA(props && props.wsla ? props.wsla : []);
    }
    fetchData();
  }, [props]);
  
  useEffect(() => {
    async function fetchData() {
      if ( workSingle && workSingle.id ) {
        setWorkStage(workSingle.stage);
      }
    }
    fetchData();
  }, [workSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedWSL && selectedWSL.id && !selectedWSLA && selectedWSLA.id && selectedWSLA.employee_id) {
      notif = "Please refresh the page.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("work_list_id", selectedWSL.id);
      formData.append("employee_id", selectedWSLA.employee_id);

      const callAPI = await AxiosInstance.post(`/worklistassistance/${selectedWSLA.id}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        props.sRC(Math.round((new Date()).getTime()));
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Yes
      </Button>
      <Button variant="primary" disabled={disableButton} onClick={() => handleClose()}>
        No
      </Button>
    </div>
  );

  return (
    <>
      {
        workStage !== "approved" && workStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            <i className="pi pi-times ms-1" style={{ fontSize: "0.8rem", color: "red" }} />
          </span>
        )
      }

      <Dialog header="Delete" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="name">Remove {selectedWSLA && selectedWSLA.employee_id && employees && employees?.length > 0 && employees?.find(i => i.id === parseInt(selectedWSLA.employee_id)) && employees?.find(i => i.id === parseInt(selectedWSLA.employee_id))?.name} from assisting {selectedWSL && selectedWSL.employee_id && employees && employees?.length > 0 && employees?.find(i => i.id === parseInt(selectedWSL.employee_id)) && employees?.find(i => i.id === parseInt(selectedWSL.employee_id))?.name} on {selectedWSL && selectedWSL.block_id && blocks && blocks?.length > 0 && blocks?.find(i => i.id === parseInt(selectedWSL.block_id)) && blocks?.find(i => i.id === parseInt(selectedWSL.block_id))?.medium}{selectedWSL && selectedWSL.machine_id && machines && machines?.length > 0 && machines?.find(i => i.id === parseInt(selectedWSL.machine_id)) && machines?.find(i => i.id === parseInt(selectedWSL.machine_id))?.name}?
          </label>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteWorkListAssistanceModal;
