import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { StockMovementContext, FieldContext, EmployeeContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const AddStockMovementModal = () => {
  
  const { showToast } = useToastContext();

  const stockMovementContext = useContext(StockMovementContext);
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const [employeesByField, setEmployeesByField] = useState([]);

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);
  
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMinDate, setSelectedMinDate] = useState("");
  const [selectedMaxDate, setSelectedMaxDate] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedReceiveBy, setSelectedReceiveBy] = useState("");
  const [typeNotes, setTypeNotes] = useState("");

  useEffect(() => {
    async function fetchData() {
      setDate();
    }
    fetchData();
  }, []);

  const setDate = () => {
    let fileDate = new Date();
    let minTime = new Date(fileDate);
    let maxTime = new Date(fileDate);

    if (minTime) {
      minTime.setDate(fileDate.getDate() - 67);
      minTime.setHours(0, 0, 0, 0);
    }
    
    if (maxTime) {
      maxTime.setHours(23, 59, 59, 0);
    }

    setSelectedDate( fileDate );
    setSelectedMinDate( minTime );
    setSelectedMaxDate( maxTime );
  }

  function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedDate) {
      notif = "Date must not be empty.";
    } else if (!selectedField) {
      notif = "Field must not be empty.";
    } else if (!selectedReceiveBy) {
      notif = "Receive By must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("ondate", formatDateToISO(selectedDate));
      formData.append("field_id", selectedField);
      formData.append("employee_id", selectedReceiveBy);
      if (typeNotes.trim()) { formData.append("notes", typeNotes.trim()); }

      const callAPI = await AxiosInstance.post("/stockmovement", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        stockMovementContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    setDisableButton(false);
    setDate();
    setSelectedField("");
    setSelectedReceiveBy("");
    setTypeNotes("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  const updateEmployeeByField = (locid) => {
    setEmployeesByField(employees.filter(obj => { return parseInt(obj.field_id) === parseInt(locid); }));
  }

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        add new
      </span>

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="ondate">Date</label>
          <Calendar
            id="inputDate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.value)}
            minDate={selectedMinDate}
            maxDate={selectedMaxDate}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="field">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setSelectedField(_.target.value);
              updateEmployeeByField(_.target.value);
              setSelectedReceiveBy("");
            }}
            value={selectedField}
            required
          >
            <option value="">Please select</option>
            {
              fields?.length > 0 && fields?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="reby">Received By</label>
          <select
            id="reby"
            name="reby"
            className="form-control"
            onChange={(_) => {
              setSelectedReceiveBy(_.target.value);
            }}
            value={selectedReceiveBy}
            required
            disabled={ selectedField ? false : true }
          >
            <option value="">Please select</option>
            {
              employeesByField?.length > 0 && employeesByField?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setTypeNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddStockMovementModal;
