import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { TripSingleContext, TripContext, useToastContext } from "../../contexts";

const EditTripPayloadModal = (props) => {
  
  const { showToast } = useToastContext();

  const tripContext = useContext(TripContext);
  const tripSingleContext = useContext(TripSingleContext);
  const {tripSingle} = tripSingleContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [tripId, setTripId] = useState("");
  const [tripStage, setTripStage] = useState("");

  const [payloadId, setPayloadId] = useState("");
  const [typeGrossWeight, setTypeGrossWeight] = useState(0);
  const [typeTareWeight, setTypeTareWeight] = useState(0);
  const [typeNetWeight, setTypeNetWeight] = useState(0);
  const [typeDeduction, setTypeDeduction] = useState(0);
  const [typeDeductionNotes, setTypeDeductionNotes] = useState("");
  const [typeNetPayload, setTypeNetPayload] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if ( tripSingle && tripSingle?.id ) {
        setTripId(tripSingle?.id);
        setTripStage(tripSingle?.stage);
        if ( tripSingle && tripSingle?.trip_payload && tripSingle?.trip_payload?.gross_weight ) {
          setPayloadId(tripSingle?.trip_payload?.id);
          setTypeGrossWeight(tripSingle?.trip_payload?.gross_weight);
          setTypeTareWeight(tripSingle?.trip_payload?.tare_weight);
          setTypeNetWeight(tripSingle?.trip_payload?.net_weight);
          setTypeDeduction(tripSingle?.trip_payload?.deduction);
          if (tripSingle?.trip_payload?.deduction_notes) { setTypeDeductionNotes(tripSingle?.trip_payload?.deduction_notes); }
          setTypeNetPayload(tripSingle?.trip_payload?.net_payload);
        }
      }      
    }
    fetchData();
  }, [tripSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!tripId) {
      notif = "Unable to continue this process";
    } else if (!typeGrossWeight) {
      notif = "Gross Weight must not be empty.";
    } else if (!typeTareWeight) {
      notif = "Tare Weight must not be empty.";
    } else if (!typeNetWeight) {
      notif = "Net Weight must not be empty.";
    } else if (!typeNetPayload) {
      notif = "Net Payload must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){

      let formData = new FormData();
      formData.append("trip_id", tripId);
      if (payloadId) { formData.append("payload_id", payloadId); }
      formData.append("gross_weight", typeGrossWeight);
      formData.append("tare_weight", typeTareWeight);
      formData.append("net_weight", typeNetWeight);
      formData.append("deduction", typeDeduction);
      formData.append("net_payload", typeNetPayload);
      if (typeDeductionNotes) { formData.append("deduction_notes", typeDeductionNotes); }

      const addWork = await AxiosInstance.post(`/trippayload`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addWork) {
        tripContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }

    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const calculateWeight = (gross, tare, deduct) => {

    let netw = gross - tare;
    setTypeNetWeight(netw);

    let netp = netw - deduct;
    setTypeNetPayload(netp);

  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      {
        tripStage !== "approved" && tripStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            edit
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="grossweight">Gross Weight ( KG )</label>
          <input
            type="number"
            className={"form-control " + (typeGrossWeight < 0 ? "border-danger" : "") }
            id="grossweight"
            name="grossweight"
            placeholder="Gross Weight"
            onChange={(_) => {
              let gross = _.target.value.replace(/[^0-9.-]/gi, "");
              setTypeGrossWeight(gross);
              calculateWeight(Number(gross), Number(typeTareWeight), Number(typeDeduction));
            }}
            value={typeGrossWeight}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="tareweight">Tare Weight ( KG )</label>
          <input
            type="number"
            className={"form-control " + (typeTareWeight < 0 ? "border-danger" : "") }
            id="tareweight"
            name="tareweight"
            placeholder="Tare Weight"
            onChange={(_) => {
              let tare = _.target.value.replace(/[^0-9.-]/gi, "");
              setTypeTareWeight(tare);
              calculateWeight(Number(typeGrossWeight), Number(tare), Number(typeDeduction));
            }}
            value={typeTareWeight}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="netweight">Net Weight ( KG )</label>
          <input
            type="number"
            className={"form-control " + (typeNetWeight < 0 ? "border-danger" : "") }
            id="netweight"
            name="netweight"
            placeholder="Net Weight"
            onChange={(_) => {
              setTypeNetWeight(_.target.value.replace(/[^0-9.-]/gi, ""));
            }}
            value={typeNetWeight}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
            disabled
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="deduction">Deduction ( KG )</label>
          <input
            type="number"
            className={"form-control " + (typeDeduction < 0 ? "border-danger" : "") }
            id="deduction"
            name="deduction"
            placeholder="Deduction"
            onChange={(_) => {
              let deduct = _.target.value.replace(/[^0-9.-]/gi, "");
              setTypeDeduction(deduct);
              calculateWeight(Number(typeGrossWeight), Number(typeTareWeight), Number(deduct));
            }}
            value={typeDeduction}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setTypeDeductionNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeDeductionNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="netpayload">Net Payload ( KG )</label>
          <input
            type="number"
            className={"form-control " + (typeNetPayload < 0 ? "border-danger" : "") }
            id="netpayload"
            name="netpayload"
            placeholder="Net Payload"
            onChange={(_) => {
              setTypeNetPayload(_.target.value.replace(/[^0-9.-]/gi, ""));
            }}
            value={typeNetPayload}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
            disabled
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditTripPayloadModal;
