import React, { useState, createContext} from "react";

export const StockMovementSingleContext = createContext();

export const StockMovementSingleState = (props) => {
  const [stockMovementSingle, setStockMovementSingle] = useState([]);

  const addData = (abc) =>{
    setStockMovementSingle(abc);
  }

  return (
    <StockMovementSingleContext.Provider value={{stockMovementSingle, addData}}>
      {props.children}
    </StockMovementSingleContext.Provider>
  )
}