import React, { useState, useContext } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { EmployeeContext, PositionContext, FieldContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const AddEmployeeModal = () => {
  
  const { showToast } = useToastContext();

  const employeeContext = useContext(EmployeeContext);
  const {fields} = useContext(FieldContext);
  const {positions} = useContext(PositionContext);

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);
  
  const [typeName, setTypeName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedJoinDate, setSelectedJoinDate] = useState(new Date());

  const handleClose = () => {
    setDisableButton(false);
    setTypeName("");
    setSelectedPosition("");
    setSelectedField("");
    setSelectedJoinDate(new Date());
    setVisible(false);
  }

  function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeName) {
      notif = "Name must not be empty.";
    } else if (!selectedPosition) {
      notif = "Position must not be empty.";
    } else if (!selectedField) {
      notif = "Field must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("field_id", selectedField);
      formData.append("name", typeName);
      formData.append("position_id", selectedPosition);
      formData.append("join_date", formatDateToISO(selectedJoinDate));

      const callAPI = await AxiosInstance.post("/employees", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        employeeContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        add new
      </span>

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Name"
            onChange={(_) => {
              setTypeName(_.target.value.replace(/[^A-Za-z0-9]/gi, ""));
            }}
            value={typeName}
            required
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="field">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setSelectedField(_.target.value);
            }}
            value={selectedField}
            required
          >
            <option value="">Please select</option>
            {
              fields?.length > 0 && fields?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="position">Position</label>
          <select
            id="position"
            name="position"
            className="form-control"
            onChange={(_) => {
              setSelectedPosition(_.target.value);
            }}
            value={selectedPosition}
            required
          >
            <option value="">Please select</option>
            {
              positions?.length > 0 && positions?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="inputDate">Join Date</label>
          <Calendar
            id="inputDate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedJoinDate}
            onChange={(e) => setSelectedJoinDate(e.value)}
            required
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddEmployeeModal;
