import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, BlockContext, EmployeeContext, MachineContext, WorkSingleContext, PositionContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const AddWorkListModal = (props) => {
  
  const { showToast } = useToastContext();

  const workSingleContext = useContext(WorkSingleContext);
  const {workSingle} = workSingleContext;

  const allContext = useContext(AllContext);
  const {allFormula} = allContext;
  const blockContext = useContext(BlockContext);
  const {blocks} = blockContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [workId, setWorkId] = useState("");
  const [workStage, setWorkStage] = useState("");
  const [workType, setWorkType] = useState("");

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedFromTime, setSelectedFromTime] = useState("");
  const [selectedToTime, setSelectedToTime] = useState("");
  const [selectedMinDate, setSelectedMinDate] = useState("");
  const [selectedMaxDate, setSelectedMaxDate] = useState("");
  const [typeGrossAmount, setTypeGrossAmount] = useState(0);
  const [typeNotes, setTypeNotes] = useState("");
  const [selectedMachine, setSelectedMachine] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedPerawatanType, setSelectedPerawatanType] = useState("");
  const [selectedHeavyMachinery, setSelectedHeavyMachinery] = useState("");

  useEffect(() => {
    async function fetchData() {
      if ( workSingle && workSingle.id ) {
        setWorkId(workSingle.id);
        setWorkStage(workSingle.stage);
        setWorkType(workSingle?.work_type);
        setDate();
      }
    }
    fetchData();
  }, [workSingle]);

  const setDate = () => {
    let workDate = new Date(workSingle.work_date);
    let fromTime = new Date(workDate);
    let toTime = new Date(workDate);
    let minTime = new Date(workDate);
    let maxTime = new Date(workDate);

    if (fromTime) {
      fromTime.setDate(workDate.getDate() - 67);
      fromTime.setHours(7, 0, 0);
    }

    if (toTime) {
      toTime.setDate(workDate.getDate() + 14);
      toTime.setHours(18, 0, 0);
    }

    if (minTime) {
      minTime.setDate(workDate.getDate() - 67);
      minTime.setHours(7, 0, 0, 0);
    }
    
    if (maxTime) {
      maxTime.setDate(workDate.getDate() + 14);
      maxTime.setHours(18, 0, 0, 0);
    }

    setSelectedFromTime( fromTime );
    setSelectedToTime( toTime );
    setSelectedMinDate( minTime );
    setSelectedMaxDate( maxTime );
  }

  function formatDateTimeToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedEmployee) {
      notif = "Employee must not be empty.";
    } else if (!selectedFromTime) {
      notif = "From must not be empty.";
    } else if (!selectedToTime) {
      notif = "To must not be empty.";
    } else if ((workType === "workforbengkel" || workType === "workforoperator") && !selectedMachine) {
      notif = "Machine must not be empty.";
    } else if (workType === "workforborongan" && !typeGrossAmount) {
      notif = "Gross Amount must not be empty.";
    } else if (workType === "workforperawatan" && !selectedBlock) {
      notif = "Machine must not be empty.";
    } else if (workType === "workforperawatan" && !selectedPerawatanType) {
      notif = "Type must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("work_id", workId);
      formData.append("work_slug", workType);
      formData.append("employee_id", selectedEmployee.id);
      formData.append("log_from", formatDateTimeToISO(selectedFromTime));
      formData.append("log_to", formatDateTimeToISO(selectedToTime));
      if (typeNotes) { formData.append("notes", typeNotes); }
      if ((workType === "workforbengkel" || workType === "workforoperator") && selectedMachine) { formData.append("machine_id", selectedMachine); }
      if (workType === "workforborongan" && typeGrossAmount) { formData.append("gross_amount", typeGrossAmount); }
      if (workType === "workforoperator" && selectedHeavyMachinery) { formData.append("heavy_machinery", selectedHeavyMachinery); }
      if (workType === "workforperawatan" && selectedBlock) { formData.append("block_id", selectedBlock); }
      if (workType === "workforperawatan" && selectedPerawatanType) { formData.append("perawatan_type", selectedPerawatanType); }
      
      const addWork = await AxiosInstance.post("/worklist", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addWork) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setSelectedEmployee("");
    setDate();
    setTypeGrossAmount(0);
    setTypeNotes("");
    setSelectedMachine("");
    setSelectedBlock("");
    setSelectedPerawatanType("");
    setSelectedHeavyMachinery("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  const valueTemplate = (employee, props) => {
    if (employee) {
      return (
        <div className="flex align-items-center">
            <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemTemplate = (employee) => {
    return (
      <div className="flex align-items-center">
          <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
      </div>
    );
  };

  return (
    <>
      {
        workStage !== "approved" && workStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            add new
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="employee">Employee</label>
          <Dropdown
            className="form-control"
            value={selectedEmployee}
            options={employees}
            onChange={(_) => {
              let ss = _.target.value;
              setSelectedEmployee(ss);
            }}
            optionLabel="name"
            filter
            filterBy="name"
            placeholder="Search by Employee"
            valueTemplate={valueTemplate} 
            itemTemplate={itemTemplate}
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="fromtime">From ( Notes : 1 Day = 8 Hours | 1/2 Day = 4 Hours )</label>
          <Calendar
            id="fromtime"
            className="w-100"
            // dateFormat="dd/mm/yy"
            value={selectedFromTime}
            onChange={(e) => setSelectedFromTime(e.value)}
            showTime
            hourFormat="24"
            minDate={selectedMinDate}
            maxDate={selectedToTime}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="totime">To ( Notes : 1 Day = 8 Hours | 1/2 Day = 4 Hours )</label>
          <Calendar
            id="fromtime"
            className="w-100"
            // dateFormat="dd/mm/yy"
            value={selectedToTime}
            onChange={(e) => setSelectedToTime(e.value)}
            showTime
            hourFormat="24"
            minDate={selectedFromTime}
            maxDate={selectedMaxDate}
            required
            readOnlyInput
          />
        </div>
        {
          workType === "workforbengkel" || workType === "workforoperator" ? (
            <>
              <div className="form-group mb-2">
                <label htmlFor="machine">Machine</label>
                <select
                  id="machine"
                  name="machine"
                  className="form-control"
                  onChange={(_) => {
                    setSelectedMachine(_.target.value);
                  }}
                  value={selectedMachine}
                  required
                >
                  <option value="">Please select</option>
                  {
                    machines?.length > 0 && workSingle && workSingle.field_id && machines?.filter(obj => parseInt(obj.field_id) === parseInt(workSingle.field_id))?.map((e, i) => (
                      <option key={e.id} value={e.id}>{e.name}</option>
                    ))
                  }
                </select>
              </div>
              {
                workType === "workforoperator" && (
                  <div className="form-group mb-2">
                    <label htmlFor="heavymachinery">Heavy Machinery ( if no, leave it blank )</label>
                    <select
                      id="heavymachinery"
                      name="heavymachinery"
                      className="form-control"
                      onChange={(_) => {
                        setSelectedHeavyMachinery(_.target.value);
                      }}
                      value={selectedHeavyMachinery}
                    >
                      <option value="">Please select</option>
                      <option key="yes" value="yes">Yes</option>
                    </select>
                  </div>
                )
              }
            </>
          ) : workType === "workforborongan" ? (
            <div className="form-group mb-2">
              <label htmlFor="grossamount">Gross Amount</label>
              <input
                type="number"
                className={"form-control " + (typeGrossAmount < 0 ? "border-danger" : "") }
                id="grossamount"
                name="grossamount"
                placeholder="Gross Amount"
                onChange={(_) => {
                  setTypeGrossAmount(_.target.value.replace(/[^0-9.-]/gi, ""));
                }}
                value={typeGrossAmount}
                onKeyDown={e => (e.key === "Enter") && apiCall()}
              />
            </div>
          ) : workType === "workforperawatan" ? (
            <>
              <div className="form-group mb-2">
                <label htmlFor="block">Block</label>
                <select
                  id="block"
                  name="block"
                  className="form-control"
                  onChange={(_) => {
                    setSelectedBlock(_.target.value);
                  }}
                  value={selectedBlock}
                  required
                >
                  <option value="">Please select</option>
                  {
                    blocks?.length > 0 && workSingle && workSingle.field_id && blocks?.filter(obj => parseInt(obj.field_id) === parseInt(workSingle.field_id))?.map((e, i) => (
                      <option key={e.id} value={e.id}>{e.medium}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="worktype">Work of Type</label>
                <select
                  id="worktype"
                  name="worktype"
                  className="form-control"
                  onChange={(_) => {
                    setSelectedPerawatanType(_.target.value);
                  }}
                  value={selectedPerawatanType}
                  required
                >
                  <option value="">Please select</option>
                  {
                    allFormula["perawatan"]?.map((lt, i) => (
                      <option key={lt.id} value={lt.id}>{lt.name}</option>
                    ))
                  }
                </select>
              </div>
            </>
          ) : <></>
        }
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setTypeNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddWorkListModal;
