import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, EmployeeContext, PositionContext, PaybreakContext, FieldContext } from "../contexts";
import { AddLoanModal, EditLoanModal, DeleteLoanModal } from "./modals";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

// https://primereact.org/datatable/
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";

const Payloan = () => {

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const paybreakContext = useContext(PaybreakContext);
  const {paybreak} = paybreakContext;

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  const [filters, setFilters] = useState({
    ondate: { value: null, matchMode: FilterMatchMode.EQUALS },
    field_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    employee_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    expense: { value: null, matchMode: FilterMatchMode.EQUALS },
    notes: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  
  useEffect(() => {
    async function fetchData() {
      if (!(positions?.length > 0) && refreshCall) { positionContext.refreshData(); }
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(employees?.length > 0) && refreshCall) { employeeContext.refreshData(); }
      if (!(paybreak?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [paybreak, positions, fields, paybreakContext, positionContext, fieldContext, refreshCall]);
  
  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const employeeColumnHeader = () => {
    return (
      <Dropdown
        value={employees?.find(i => i.id === parseInt(filters.employee_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.employee_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={employees}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Employee"
        className="w-full md:w-14rem"
      />
    );
  }

  const employeeTemplate = (paybreak) => {
    return employees?.length > 0 && paybreak.employee_id ?
        employees?.find(i => i.id === parseInt(paybreak.employee_id))?.name + (positions?.length > 0 && paybreak.position_id ? " ( " + positions?.find(i => i.id === parseInt(paybreak.position_id))?.name + " )"
        : "")
      : "";
  };

  const fieldColumnHeader = () => {
    return (
      <Dropdown
        value={fields?.find(i => i.id === parseInt(filters.field_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.field_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={fields}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const fieldTemplate = (paybreak) => {
    return fields?.length > 0 && paybreak.field_id ? fields?.find(i => i.id === parseInt(paybreak.field_id))?.name : "";
  };

  const editViewTemplate = (paybreak) => {
    return <><EditLoanModal pl={paybreak} /><DeleteLoanModal pl={paybreak} /></>;
  };

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await paybreakContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  const dateTemplate = (paybreak) => {
    const date = new Date( paybreak.ondate * 1000 );
    return date.toLocaleString();
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Loans</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-12 d-flex justify-content-end small">
                            <span
                              className="link-isday me-3"
                              onClick={() => {
                                const newFilters = { ...filters };
                                newFilters.ondate.value = null;
                                newFilters.employee_id.value = null;
                                newFilters.expense.value = null;
                                newFilters.notes.value = null;
                                setFilters(newFilters);
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddLoanModal />
                          </div>
                        </div>
                        <hr />
                        <DataTable value={paybreak?.filter(obj => obj.for === "loancash")} stripedRows removableSort paginator rows={10} filters={filters} dataKey="id" emptyMessage="No pay found.">
                          <Column field="ondate" body={dateTemplate} sortable header="Date"></Column>
                          <Column field="field_id" body={fieldTemplate} header={fieldColumnHeader}></Column>
                          <Column field="employee_id" body={employeeTemplate} header={employeeColumnHeader}></Column>
                          <Column field="expense" sortable header="Amount"></Column>
                          <Column field="notes" sortable header="Notes"></Column>
                          <Column body={editViewTemplate} header="" style={{ width: "5rem" }}></Column>
                        </DataTable>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payloan;
