import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { EmployeeContext, PositionContext, LoanSingleContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const EditLoanListModal = (props) => {
  
  const { showToast } = useToastContext();

  const loanSingleContext = useContext(LoanSingleContext);
  const {loanSingle} = loanSingleContext;

  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [loanId, setLoanId] = useState("");
  const [loanDate, setLoanDate] = useState("");
  const [loanStage, setLoanStage] = useState("");

  const [selectedLoanListId, setSelectedLoanListId] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [typeAmount, setTypeAmount] = useState("");
  const [selectedPaidDate, setSelectedPaidDate] = useState("");
  const [typeNotes, setTypeNotes] = useState("");

  useEffect(() => {
    async function fetchData() {
      setSelectedLoanListId(props && props.ll && props.ll.id ? props.ll.id : "");
      setSelectedEmployee(props && props.ll && props.ll.employee_id ? employees?.find(i => i.id === parseInt(props.ll.employee_id)) : "");
      setTypeAmount(props && props.ll && props.ll.amount ? props.ll.amount : 0);
      setSelectedPaidDate(props && props.ll && props.ll.paid_date ? new Date(props.ll.paid_date) : "");
      setTypeNotes(props && props.ll && props.ll.notes ? props.ll.notes : "");
    }
    fetchData();
  }, [props]);

  useEffect(() => {
    async function fetchData() {
      if ( loanSingle && loanSingle.id ) {
        setLoanId(loanSingle.id);
        setLoanStage(loanSingle.stage);
        setLoanDate(new Date(loanSingle.loan_date));
      }
    }
    fetchData();
  }, [loanSingle]);

  function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedEmployee) {
      notif = "Employee must not be empty.";
    } else if (!typeAmount) {
      notif = "Amount must not be empty.";
    } else if (!selectedPaidDate) {
      notif = "Paid Date must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("loan_id", loanId);
      formData.append("employee_id", selectedEmployee.id);
      formData.append("amount", typeAmount);
      formData.append("paid_date", formatDateToISO(selectedPaidDate));
      if (typeNotes) { formData.append("notes", typeNotes); }
      
      const addWork = await AxiosInstance.post(`/loanlist/${selectedLoanListId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addWork) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  const valueEmployeeTemplate = (employee, props) => {
    if (employee) {
      return (
        <div className="flex align-items-center">
            <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemEmployeeTemplate = (employee) => {
    return (
      <div className="flex align-items-center">
          <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
      </div>
    );
  };

  return (
    <>
      {
        loanStage !== "approved" && loanStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday me-3">
            edit
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="employee">Employee</label>
          <Dropdown
            className="form-control"
            value={selectedEmployee}
            options={employees}
            onChange={(_) => {
              let ss = _.target.value;
              setSelectedEmployee(ss);
            }}
            optionLabel="name"
            filter
            filterBy="name"
            placeholder="Search by Employee"
            valueTemplate={valueEmployeeTemplate} 
            itemTemplate={itemEmployeeTemplate}
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            className="form-control"
            id="amount"
            name="amount"
            placeholder="Amount"
            onChange={(_) => {
              setTypeAmount(_.target.value.replace(/[^0-9-.]/gi, ""));
            }}
            value={typeAmount}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="totime">Paid Date</label>
          <Calendar
            id="inputDate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedPaidDate}
            onChange={(e) => setSelectedPaidDate(e.value)}
            minDate={loanDate}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setTypeNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditLoanListModal;
