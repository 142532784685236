import React, { useState, createContext} from "react";

export const StockSingleContext = createContext();

export const StockSingleState = (props) => {
  const [stockSingle, setStockSingle] = useState([]);

  const addData = (abc) =>{
    setStockSingle(abc);
  }

  return (
    <StockSingleContext.Provider value={{stockSingle, addData}}>
      {props.children}
    </StockSingleContext.Provider>
  )
}