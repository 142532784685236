import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { FileSingleContext, UserAAContext, useToastContext } from "../../contexts";

const EditFileModal = (props) => {
  
  const { showToast } = useToastContext();

  const fileSingleContext = useContext(FileSingleContext);
  const {fileSingle} = fileSingleContext;
  const userAAContext = useContext(UserAAContext);
  const {userAbility} = userAAContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  // const today = new Date();
  const [fileId, setFileId] = useState("");
  const [action, setAction] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      if ( fileSingle && fileSingle.id ) {
        setFileId(fileSingle.id);
        let retVal = "";
        switch (fileSingle.stage) {
          case "approved":
            if (userAbility.indexOf("stageapprove") >= 0) {
              retVal = "withdraw approval";
            }
            break;
          case "verified":
            if (userAbility.indexOf("stageapprove") >= 0) {
              retVal = "approve";
            } else if (userAbility.indexOf("stageverify") >= 0) {
              retVal = "verified";
            }
            break;
          case "submitted":
            if (userAbility.indexOf("stageapprove") >= 0 || userAbility.indexOf("stageverify") >= 0) {
              retVal = "verified";
            }
            break;
          case "editmode":
            retVal = "submit";
            break;
          default:
            // do nothing
            break;
        }
        setAction(retVal);
      }
    }
    fetchData();
  }, [fileSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!fileId) {
      notif = "Please refresh the page.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){

      let formData = new FormData();
      const addFile = await AxiosInstance.post(`/file/verify/${fileId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addFile) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()} className="mt-4">
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span className="link-isday" onClick={handleShow}>
        {action}
      </span>

      <Dialog header="Edit" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mt-4 mb-2">
          Ready to {action}?
        </div>
      </Dialog>
    </>
  );
};

export default EditFileModal;

