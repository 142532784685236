import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { FieldContext, StockSingleContext, useToastContext } from "../../contexts";

const AddStockFieldModal = (props) => {
  
  const { showToast } = useToastContext();

  const stockSingleContext = useContext(StockSingleContext);
  const {stockSingle} = stockSingleContext;

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);
  
  const [stockId, setStockId] = useState("");
  const [stockField, setStockField] = useState([]);

  const [selectStockField, setSelectStockField] = useState("");
  const [typeStockAt, setTypeStockAt] = useState("");
  const [typeStockQty, setTypeStockQty] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if ( stockSingle && stockSingle.id ) {
        setStockId(stockSingle.id);
        setStockField(stockSingle?.field);
      }
    }
    fetchData();
  }, [stockSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!stockId) {
      notif = "Please refresh the page.";
    } else if (!selectStockField) {
      notif = "Field must not be empty.";
    } else if (!typeStockAt.trim()) {
      notif = "At must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("stock_id", stockId);
      formData.append("field_id", selectStockField);
      formData.append("at", typeStockAt.trim());
      formData.append("qty", parseInt(typeStockQty));

      const callAPI = await AxiosInstance.post("/stockfield", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setSelectStockField("");
    setTypeStockAt("");
    setTypeStockQty(0);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        add new
      </span>

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="loc">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setSelectStockField(_.target.value);
            }}
            value={selectStockField}
            required
          >
            {/* stockField */}
            <option value="">Please select</option>
            {
              fields?.length > 0 && fields?.map((e, i) => (
                stockField?.find(i => parseInt(i.field_id) === parseInt(e.id))
                  ? ""
                    : 
                    <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="at">At</label>
          <input
            type="text"
            className="form-control"
            id="at"
            name="at"
            placeholder="At"
            onChange={(_) => {
              setTypeStockAt(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeStockAt}
            required
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="qty">Qty</label>
          <input
            type="text"
            className="form-control"
            id="qty"
            name="qty"
            placeholder="Qty"
            onChange={(_) => {
              setTypeStockQty(_.target.value.replace(/[^0-9-.]/gi, ""));
            }}
            value={typeStockQty}
            required
            onKeyDown={e => (e.key === "Enter") && apiCall()}
            disabled
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddStockFieldModal;
