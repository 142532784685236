import React, { useContext, useEffect, useRef, useState } from "react";
import AxiosInstance from "../axios";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate, useParams } from "react-router-dom";
import { EditWorkModal, AddWorkListModal, EditWorkListModal, DeleteWorkListModal, AddWorkListAssistanceModal, DeleteWorkListAssistanceModal, AddWorkListStockModal, DeleteWorkListStockModal, AddWorkImageModal, VerifyWorkModal } from "./modals";
import { AllContext, BlockContext, EmployeeContext, FieldContext, MachineContext, PositionContext, UserContext, StockContext, UserAAContext, useToastContext } from "../contexts";
import { WorkSingleContext } from "../contexts";
import { baseURL, imageBaseURL } from "../axios";

// https://primereact.org/datatable/
import { Galleria } from "primereact/galleria";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const WorkView = () => {

  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const galleria = useRef(null);

  const workSingleContext = useContext(WorkSingleContext);
  const {workSingle} = workSingleContext;

  const allContext = useContext(AllContext);
  const {allOption, allFormula} = allContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const stockContext = useContext(StockContext);
  const {stocks} = stockContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;
  const blockContext = useContext(BlockContext);
  const {blocks} = blockContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const userContext = useContext(UserContext);
  const {users} = userContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice, userField} = userAAContext;

  let { work_id } = useParams();
  const [workType, setWorkType] = useState("");
  const [wModal, setWModal] = useState(Math.round((new Date()).getTime()));

  const [workImage, setWorkImage] = useState([]);
  const [workExpense, setWorkExpense] = useState([]);
  const [workList, setWorkList] = useState([]);
  const [workStage, setWorkStage] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(employees?.length > 0) && refreshCall) { employeeContext.refreshData(); }
      if (!(blocks?.length > 0) && refreshCall) { blockContext.refreshData(); }
      if (!(stocks?.length > 0) && refreshCall) { stockContext.refreshData(); }
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(machines?.length > 0) && refreshCall) { machineContext.refreshData(); }
      if (!(positions?.length > 0) && refreshCall) { positionContext.refreshData(); }
      if (!(users?.length > 0) && refreshCall) { userContext.refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [employees, blocks, stocks, fields, machines, users, employeeContext, blockContext, stockContext, fieldContext, machineContext, userContext, refreshCall]);

  useEffect(() => {
    async function fetchData() {
      if ( workSingle && workSingle?.work_type ) {
        setWorkList(workSingle?.work_list);
        setWorkImage(workSingle?.work_image);
        setWorkExpense(workSingle?.expense_employees);
        setWorkType(workSingle?.work_type);
        setWorkStage(workSingle?.history_stage);
      }
    }
    fetchData();
  }, [workSingle]);

  useEffect(() => {
    async function fetchData() {
      new Promise(function(resolve, reject) {
        setShowLoading(true);
        resolve(1);
      }).then(async function(result) {
        const getWorkSingle = await AxiosInstance.get(
          `/works/${work_id}`,
          {
            cache: {
              ignoreCache: true,
            },
          }
        ).catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
  
        if(getWorkSingle){
          workSingleContext.addData(getWorkSingle?.data);
        }
        return 1;
      }).finally(function(result) {
        setShowLoading(false);
      });
    }
    fetchData();
  }, [wModal]);

  useEffect(() => {
    async function fetchData() {
      workSingle && workSingle?.work_type && setVisibleColumns(columnConfig[workSingle?.work_type]) ;
    }
    fetchData();
  }, [workType]);

  // useEffect(() => {
  //   async function fetchData() {
  //     userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
  //   }
  //   fetchData();
  // }, [userDevice]);

  // Columns
  const [visibleColumns, setVisibleColumns] = useState([]);

  const [visiblePayColumns, setVisiblePayColumns] = useState([
    { field: 'employee_id', header: 'Employee', visible: true },
    { field: 'for', header: 'For', visible: true },
    { field: 'expense', header: 'Pay', visible: true }
  ]);

  const [visibleStageColumns, setVisibleStageColumns] = useState([
    { field: 'created_at', header: 'Date', visible: true },
    { field: 'changed_to', header: 'Action', visible: true },
    { field: 'changed_by', header: 'By', visible: true },
  ]);

  const columnConfig = {
    workforharian: [
      { field: 'fromto', header: 'Date', visible: true },
      { field: 'employeeassitance', header: 'Employee', visible: true },
      { field: 'notes', header: 'Notes', visible: true },
      { field: 'edit_view', header: '', visible: true },
    ],
    workforbengkel: [
      { field: 'fromto', header: 'Date', visible: true },
      { field: 'machine_id', header: 'Machine', visible: true },
      { field: 'employeeassitance', header: 'Employee', visible: true },
      { field: 'stocks', header: 'Stock', visible: true },
      { field: 'notes', header: 'Notes', visible: true },
      { field: 'edit_view', header: '', visible: true },
    ],
    workforoperator: [
      { field: 'fromto', header: 'Date', visible: true },
      { field: 'machine_id', header: 'Machine', visible: true },
      { field: 'employeeassitance', header: 'Employee', visible: true },
      { field: 'stocks', header: 'Stock', visible: true },
      { field: 'notes', header: 'Notes', visible: true },
      { field: 'edit_view', header: '', visible: true },
    ],
    workforborongan: [
      { field: 'fromto', header: 'Date', visible: true },
      { field: 'block', header: 'Block', visible: true },
      { field: 'employeeassitance', header: 'Employee', visible: true },
      { field: 'stocks', header: 'Stock', visible: true },
      { field: 'gross_amount', header: 'Gross Amount', visible: true },
      { field: 'notes', header: 'Notes', visible: true },
      { field: 'edit_view', header: '', visible: true },
    ],
    workforperawatan: [
      { field: 'fromto', header: 'Date', visible: true },
      { field: 'block', header: 'Block', visible: true },
      { field: 'employeeassitance', header: 'Employee', visible: true },
      { field: 'stocks', header: 'Stock', visible: true },
      { field: 'notes', header: 'Notes', visible: true },
      { field: 'edit_view', header: '', visible: true },
    ],
  };

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  // Template
  const itemTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/work-images/large/${work_id}/${item.image}`} alt="" style={{ width: "100%" }} />
  }

  const thumbnailTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/work-images/thumb/${work_id}/${item.image}`} alt={item.alt} style={{ width: "70%" }} />
  }

  const fromtoTemplate = (worklist) => {
    return (
      <>
        {datetimeTemplate(worklist.log_from)}
        <hr />
        {datetimeTemplate(worklist.log_to)}
      </>
    );
  };

  const actionTemplate = (stage) => {
    return (
      stage && stage?.changed_to ? (
        stage?.changed_to === "approved" || stage?.changed_to === "approvedbysystem" ?
          "Approve" : (
            stage?.changed_to === "verified" || stage?.changed_to === "verifiedbysystem" ?
              "Verify" : 
                "Submit"
          )
      ) : "");
  };

  const byTemplate = (stage) => {
    return users?.length > 0 && stage.changed_by && users?.find(i => i.id === parseInt(stage.changed_by)) ? users?.find(i => i.id === parseInt(stage.changed_by))?.name : "";
  };

  const dtTemplate = (dt) => {
    const date = new Date(dt);
    const options = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const datetimeTemplate = (dt) => {
    const date = new Date(dt);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}, ${hours}:${minutes}`;
  };

  const employeeTemplate = (worklist) => {
    return employees?.length > 0 && worklist.employee_id ? employees?.find(i => i.id === parseInt(worklist.employee_id))?.name : "";
  };

  const machineTemplate = (worklist) => {
    return machines?.length > 0 && worklist.machine_id ? (
      <>
        {machines?.find(i => i.id === parseInt(worklist.machine_id))?.name}
        {
          worklist && worklist.heavy_machinery && (
            <>
              <br />
              ( <i>AB</i> )
            </>
          )
        }
      </>
    ) : "";
  };

  const blockTemplate = (worklist) => {
    return allFormula["perawatan"]?.length > 0 && worklist.perawatan_type ? (
      <>
        {blocks?.find(i => i.id === parseInt(worklist.block_id)).medium}
        <br /><br />
        {allFormula["perawatan"]?.find(i => i.id === worklist.perawatan_type)?.name}
        <br />
        <span className="fw-lighter">
          {
            (worklist.perawatan_type.includes("pupuk") || worklist.perawatan_type.includes("racun")) ? (
              "(" + (
                worklist.perawatan_type.includes("pupuk")
                && worklist.used_pupuk
                  ? Math.abs(worklist.used_pupuk)
                    : (
                      worklist.perawatan_type.includes("racun")
                        && worklist.used_racun
                          ? Math.abs(worklist.used_racun)
                            : 0
                    )
              ) + " out of " + worklist.jatah + ")"
            ) : "(" + worklist.jatah + ")"
          }
        </span>
      </>
    ) : ""
  };

  const employeeassitanceTemplate = (worklist) => {
    return workType ? (
      <>
        {employeeTemplate(worklist)}
        {
          workType !== "workforharian" && workType !== "workforoperator" && worklist.assistances && (
            <>
              <br /><br />
              <i><u>Assistance</u></i>
              {
                worklist?.assistances?.length > 0 ? worklist.assistances.map((wsla, j) => (
                  <div key={j}>{employees && employees?.length > 0 && employees?.find(i => i.id === parseInt(wsla.employee_id)) && employees?.find(i => i.id === parseInt(wsla.employee_id))?.name} | {wsla.contribution_amount} <DeleteWorkListAssistanceModal wsl={worklist} wsla={wsla} sRC={setWModal} /></div>
                )) : <br />
              }
              <AddWorkListAssistanceModal wsl={worklist} sRC={setWModal} />
            </>
          )
        }
      </>
    ) : "";
  };

  const stockTemplate = (worklist) => {
    return workType && workType !== "workforharian" && worklist.stocks ? (
      <>
        {
          worklist.stocks.map((wsla, j) => (
            <div key={j}>{stocks && stocks?.length > 0 && stocks?.find(i => i.id === parseInt(wsla.stock_id)) && stocks?.find(i => i.id === parseInt(wsla.stock_id))?.name} | {wsla.qty} <DeleteWorkListStockModal wsl={worklist} wsla={wsla} sRC={setWModal} /></div>
          ))
        }
        <AddWorkListStockModal wsl={worklist} sRC={setWModal} />
      </>
    ) : "";
  };

  const editViewTemplate = (worklist) => {
    return (
      <>
        <EditWorkListModal wsl={worklist} sRC={setWModal} />
        <DeleteWorkListModal wsl={worklist} sRC={setWModal} />
      </>
    );
  };

  return (
    <div className="container bg-white content-container">
      <div className="row text-left">
        <span className="link-isday" onClick={()=>navigate(-1)}>
          &lt; Back
        </span>
      </div>
      {
        showLoading
        ?
          <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
          </div>
        : 
          (
            <>
              <h2 className="h5 pt-3 pb-3 d-flex justify-content-center">Work</h2>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6 mb-0">INFORMATION</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <EditWorkModal sRC={setWModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Date</td>
                              <td>{workSingle && workSingle.work_date ? workSingle.work_date : ""}</td>
                            </tr>
                            <tr>
                              <td>Field</td>
                              <td>{workSingle && fields?.length > 0 && workSingle.field_id && fields?.find(i => i.id === parseInt(workSingle.field_id)) && fields?.find(i => i.id === parseInt(workSingle.field_id))?.name}</td>
                            </tr>
                            <tr>
                              <td>Work Type</td>
                              <td>{workType && allOption["typeOfWork"]?.find(i => i.id === workType)?.name}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Images</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddWorkImageModal sRC={setWModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="flex justify-content-center">
                        <Galleria ref={galleria} value={workImage} numVisible={7} style={{ maxWidth: '850px' }}
                            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                            circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />

                        <div className="grid row mb-2" style={{ maxWidth: '400px' }}>
                          {
                            workImage && workImage.map((image, index) => {
                              let imgEl = <img src={`${imageBaseURL}uploads/work-images/large/${work_id}/${image.image}`} onClick={() => {setActiveIndex(index); galleria.current.show()}} style={{ width: "100%" }} />
                              return (
                                <div className="col-lg-4 mt-3" key={index}>
                                    {imgEl}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 container-stage d-flex align-items-center justify-content-center">
                        {
                          workSingle.stage === "approved" || workSingle.stage === "approvedbysystem"
                            ? <i className="pi pi-check" style={{ fontSize: "7rem", color: "green" }} />
                              : workSingle.stage === "verified" || workSingle.stage === "verifiedbysystem"
                                ? <i className="pi pi-eye" style={{ fontSize: "7rem", color: "orange" }} />
                                  : workSingle.stage === "submitted"
                                    ? <i className="pi pi-eye-slash" style={{ fontSize: "7rem", color: "red" }} />
                                      : <i className="pi pi-clock" style={{ fontSize: "7rem", color: "grey" }} />
                        }
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row mb-2">
                        <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                          <h3 className="h6">Stage</h3>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-7 col-7 d-flex justify-content-end small">
                          <span className="link-secondary"><VerifyWorkModal sRC={setWModal} /></span>
                        </div>
                        <hr />
                        <DataTable
                          value={workStage}
                          stripedRows
                          removableSort
                          rows={20}
                          columnResizeMode="expand"
                          resizableColumns
                          dataKey="id"
                          emptyMessage="No Stage found."
                        >
                          {
                            visibleStageColumns.map((col, index) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                body={(rowData) => (
                                  col.field === 'created_at' ?
                                    dtTemplate(rowData?.created_at) :
                                      (
                                        col.field === 'changed_to' ?
                                          actionTemplate(rowData) :
                                            (
                                              col.field === 'changed_by' ?
                                                byTemplate(rowData) :
                                                  rowData[col.field]
                                            )
                                      )
                                  )
                                }
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  padding: '10px'
                                }}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Work List</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddWorkListModal sRC={setWModal} />
                        </div>
                      </div>
                      <hr />
                      <DataTable
                        value={workList}
                        stripedRows
                        removableSort
                        rows={20}
                        columnResizeMode="expand"
                        resizableColumns
                        dataKey="id"
                        emptyMessage="No files found."
                      >
                        {
                          visibleColumns.map((col, index) => (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.field === 'edit_view' ? '' : col.header}
                              body={(rowData) => (
                                col.field === 'employee_id' ?
                                  employeeTemplate(rowData) :
                                    (
                                      col.field === 'employeeassitance' ?
                                        employeeassitanceTemplate(rowData) :
                                          (
                                            col.field === 'fromto' ?
                                              fromtoTemplate(rowData) : 
                                                (
                                                  col.field === 'machine_id' ?
                                                    machineTemplate(rowData) :
                                                      (
                                                        col.field === 'block' ?
                                                          blockTemplate(rowData) : 
                                                          (
                                                            col.field === 'stocks' ?
                                                              stockTemplate(rowData) : 
                                                                (
                                                                  col.field === 'edit_view' ?
                                                                    editViewTemplate(rowData) :
                                                                      rowData[col.field]
                                                                )
                                                          )
                                                      )
                                                )
                                          )
                                    )
                                )
                              }
                              style={{
                                display: col.visible ? 'table-cell' : 'none',
                                whiteSpace: 'normal',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '10px'
                              }}
                              showFilterMenu={false}
                            />
                          ))
                        }
                      </DataTable>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-12">
                          <h3 className="h6">Work List <strong>Pay</strong></h3>
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <DataTable
                          value={workExpense}
                          stripedRows
                          removableSort
                          rows={20}
                          columnResizeMode="expand"
                          resizableColumns
                          dataKey="id"
                          emptyMessage="No files found."
                        >
                          {
                            visiblePayColumns.map((col, index) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                body={(rowData) => (
                                  col.field === 'employee_id' ?
                                    employeeTemplate(rowData) :
                                      (
                                        col.field === 'for' ?
                                          ( rowData?.for && allOption["typeOfMoney"]?.find(i => i.id === rowData?.for)?.name ) : 
                                            rowData[col.field]
                                      )
                                  )
                                }
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  padding: '10px'
                                }}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
      }
    </div>
  );
};

export default WorkView;
