import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { EmployeeContext, PositionContext, LoanSingleContext, useToastContext } from "../../contexts";

const DeleteLoanListModal = (props) => {
  
  const { showToast } = useToastContext();

  const loanSingleContext = useContext(LoanSingleContext);
  const {loanSingle} = loanSingleContext;

  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [loanStage, setLoanStage] = useState("");

  const [selectedLoanListId, setSelectedLoanListId] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [typeAmount, setTypeAmount] = useState("");
  const [selectedPaidDate, setSelectedPaidDate] = useState("");

  useEffect(() => {
    async function fetchData() {
      setSelectedLoanListId(props && props.ll && props.ll.id ? props.ll.id : "");
      setSelectedEmployee(props && props.ll && props.ll.employee_id ? employees?.find(i => i.id === parseInt(props.ll.employee_id)) : "");
      setTypeAmount(props && props.ll && props.ll.amount ? props.ll.amount : 0);
      setSelectedPaidDate(props && props.ll && props.ll.paid_date ? props.ll.paid_date : "");
    }
    fetchData();
  }, [props]);

  useEffect(() => {
    async function fetchData() {
      if ( loanSingle && loanSingle.id ) {
        setLoanStage(loanSingle.stage);
      }
    }
    fetchData();
  }, [loanSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedLoanListId) {
      notif = "Please refresh the page.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("delll", "yes");

      const callAPI = await AxiosInstance.post(`/loanlist/${selectedLoanListId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Yes
      </Button>
      <Button variant="primary" disabled={disableButton} onClick={() => handleClose()}>
        No
      </Button>
    </div>
  );

  return (
    <>
      {
        loanStage !== "approved" && loanStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            del
          </span>
        )
      }

      <Dialog header="Delete" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="name">
            Remove {selectedEmployee?.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(selectedEmployee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(selectedEmployee.position_id))?.name + ' )' : '' } loan of {typeAmount} to be paid on {selectedPaidDate}?
          </label>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteLoanListModal;
