import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, BlockContext, FieldContext, useToastContext } from "../../contexts";


const EditBlockModal = (props) => {
  
  const { showToast } = useToastContext();

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const blockContext = useContext(BlockContext);
  const {fields} = useContext(FieldContext);

  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [blockId, setBlockId] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [typeBig, setTypeBig] = useState("");
  const [typeMedium, setTypeMedium] = useState("");
  const [typeSize, setTypeSize] = useState("");
  const [selectedPlantType, setSelectedPlantType] = useState("");
  const [selectedLandType, setSelectedLandType] = useState("");
  const [typePTM, setTypePTM] = useState(0);
  const [typePTMK, setTypePTMK] = useState(0);
  const [typePTBM, setTypePTBM] = useState(0);
  
  useEffect(() => {
    async function fetchData() {
      setBlockId(props && props.be && props.be.id ? props.be.id : "");
      setSelectedField(props && props.be && props.be.field_id ? props.be.field_id : "");
      setTypeBig(props && props.be && props.be.big ? props.be.big : "");
      setTypeMedium(props && props.be && props.be.medium ? props.be.medium : "");
      setTypeSize(props && props.be && props.be.sizes ? props.be.sizes : "");
      setSelectedPlantType(props && props.be && props.be.plant_type ? props.be.plant_type : "");
      setSelectedLandType(props && props.be && props.be.land_type ? props.be.land_type : "");
      setTypePTM(props && props.be && props.be.price_big ? props.be.price_big : "");
      setTypePTMK(props && props.be && props.be.price_medium ? props.be.price_medium : "");
      setTypePTBM(props && props.be && props.be.price_small ? props.be.price_small : "");
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    blockContext.refreshData();
    setDisableButton(false);
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedField) {
      notif = "Field must not be be empty.";
    } else if (!typeBig) {
      notif = "Big must not be empty.";
    } else if (!typeMedium) {
      notif = "Medium must not be empty.";
    } else if (!typeSize) {
      notif = "Size must not be empty.";
    } else if (!selectedPlantType) {
      notif = "Plant Type must not be empty.";
    } else if (!selectedLandType) {
      notif = "Land Type must not be be empty.";
    } else if (!typePTM) {
      notif = "Price TM must not be empty.";
    } else if (!typePTMK) {
      notif = "Price TMK must not be empty.";
    } else if (!typePTBM) {
      notif = "Price TBM must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {

      let formData = new FormData();
      formData.append("field_id", selectedField);
      formData.append("big", typeMedium);
      formData.append("medium", typeMedium);
      formData.append("sizes", typeSize);
      formData.append("plant_type", selectedPlantType);
      formData.append("land_type", selectedLandType);
      formData.append("price_big", typePTM);
      formData.append("price_medium", typePTMK);
      formData.append("price_small", typePTBM);

      const callAPI = await AxiosInstance.post(`/blocks/${blockId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        edit
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="field">Field</label>
            <select
              id="field"
              name="field"
              className="form-control"
              onChange={(_) => {
                setSelectedField(_.target.value);
              }}
              value={selectedField}
              required
            >
              <option value="">Please select</option>
              {
                fields?.length > 0 && fields?.map((e, i) => (
                  <option key={e.id} value={e.id}>{e.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="tbig">Big</label>
            <input
              type="text"
              className="form-control"
              id="tbig"
              name="tbig"
              placeholder="Big"
              onChange={(_) => {
                setTypeBig(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeBig}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="tmedium">Medium</label>
            <input
              type="text"
              className="form-control"
              id="tmedium"
              name="tmedium"
              placeholder="Medium"
              onChange={(_) => {
                setTypeMedium(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeMedium}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="tsize">Size</label>
            <input
              type="number"
              className={"form-control " + (typeSize < 0 ? "border-danger" : "") }
              id="tsize"
              name="tsize"
              placeholder="Size"
              onChange={(_) => {
                setTypeSize(_.target.value.replace(/[^0-9-.]/gi, ""));
              }}
              value={typeSize}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="planttype">Plant Type</label>
            <select
              id="planttype"
              name="planttype"
              className="form-control"
              onChange={(_) => {
                setSelectedPlantType(_.target.value);
              }}
              value={selectedPlantType}
              required
            >
              <option value="">Please select</option>
              {
                allOption["typeOfHarvest"]?.map((lt, i) => (
                  <option key={lt.id} value={lt.id}>{lt.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="landtype">Land Type</label>
            <select
              id="landtype"
              name="landtype"
              className="form-control"
              onChange={(_) => {
                setSelectedLandType(_.target.value);
              }}
              value={selectedLandType}
              required
            >
              <option value="">Please select</option>
              {
                allOption["typeOfLand"]?.map((lt, i) => (
                  <option key={lt.id} value={lt.id}>{lt.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="ptm">Price TM</label>
            <input
              type="number"
              className={"form-control " + (typePTM < 0 ? "border-danger" : "") }
              id="ptm"
              name="ptm"
              placeholder="TM"
              onChange={(_) => {
                setTypePTM(_.target.value.replace(/[^0-9-.]/gi, ""));
              }}
              value={typePTM}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="ptmk">Price TMK</label>
            <input
              type="number"
              className={"form-control " + (typePTMK < 0 ? "border-danger" : "") }
              id="ptmk"
              name="ptmk"
              placeholder="TMK"
              onChange={(_) => {
                setTypePTMK(_.target.value.replace(/[^0-9-.]/gi, ""));
              }}
              value={typePTMK}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="ptbm">Price TBM</label>
            <input
              type="number"
              className={"form-control " + (typePTBM < 0 ? "border-danger" : "") }
              id="ptbm"
              name="ptbm"
              placeholder="TBM"
              onChange={(_) => {
                setTypePTBM(_.target.value.replace(/[^0-9-.]/gi, ""));
              }}
              value={typePTBM}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditBlockModal;
