import React, { createContext, useState} from "react";
// import { useToastContext } from "../contexts"; // a
import AxiosInstance from "../axios";

export const StockContext = createContext();

export const StockState = (props) => {
  const [stocks, setStocks] = useState([]);

  async function refreshData() {
    const response = await StocksEntries();
    setStocks(response)
    return;
  }

  return (
    <StockContext.Provider value={{stocks, refreshData}}>
      {props.children}
    </StockContext.Provider>
  )
}

const StocksEntries = async () => {
  
  const { showToast } = [];
  // const { showToast } = useToastContext(); // b
  let retVal = [];

  const getStocks = await AxiosInstance.get(
    `stocks`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
    // showToast({severity: "info", summary: "Info", detail: notif, life: 3000}); // c
    return false;
  });

  if (getStocks) {
    retVal = getStocks?.data;
  }

  return retVal;

}