import React, { createContext, useState} from "react";
// import { useToastContext } from "../contexts";
import AxiosInstance from "../axios";

export const PaybreakContext = createContext();

export const PaybreakState = (props) => {
  const [paybreak, setPaybreak] = useState([]);

  async function refreshData() {
    const response = await PaybreakEntries();
    setPaybreak(response);
  }

  return (
    <PaybreakContext.Provider value={{paybreak, refreshData}}>
      {props.children}
    </PaybreakContext.Provider>
  )
}

const PaybreakEntries = async () => {

  // const { showToast } = useToastContext();
  let retVal = [];

  const getPaybreak = await AxiosInstance.get(
    `paybreak`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
    // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
    return false;
  });

  if (getPaybreak) {
    retVal = getPaybreak?.data;
  }

  return retVal;

}