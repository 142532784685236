import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { UserAAContext, StockMovementSingleContext, useToastContext } from "../../contexts";

const VerifyStockMovementStageModal = (props) => {
  
  const { showToast } = useToastContext();

  const stockMovementSingleContext = useContext(StockMovementSingleContext);
  const {stockMovementSingle} = stockMovementSingleContext;
  const userAAContext = useContext(UserAAContext);
  const {userAbility} = userAAContext;

  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [smid, setSmid] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [action, setAction] = useState("");

  useEffect(() => {
    async function fetchData() {
      setSmid(stockMovementSingle && stockMovementSingle.id && stockMovementSingle.id);
      setSelectedStage(stockMovementSingle && stockMovementSingle.stage && stockMovementSingle.stage);
    }
    fetchData();
  }, [stockMovementSingle]);

  useEffect(() => {
    function fetchData() {
      let retVal = "";

      switch (selectedStage) {
        case "approved":
          if (userAbility.indexOf("stageapprove") >= 0) {
            retVal = "withdraw approval";
          }
          break;
        case "verified":
          if (userAbility.indexOf("stageapprove") >= 0) {
            retVal = "approve";
          } else if (userAbility.indexOf("stageverify") >= 0) {
            retVal = "verified";
          }
          break;
        case "submitted":
          if (userAbility.indexOf("stageapprove") >= 0 || userAbility.indexOf("stageverify") >= 0) {
            retVal = "verified";
          }
          break;
        case "editmode":
          retVal = "submit";
          break;
        default:
          // do nothing
          break;
      }
      
      setAction(retVal);
    }
    fetchData();
  }, [selectedStage, userAbility]);

  const handleClose = () => {
    setDisableButton(false);
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!smid) {
      notif = "Please refresh the page.";
    } else if (!selectedStage) {
      notif = "Stage must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      const callAPI = await AxiosInstance.post(`/stockmovement/verify/${smid}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        props.sRC(Math.round((new Date()).getTime()));
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">{action}</span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Stage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="name">Ready to {action}?</label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Yes
          </Button>
          <Button variant="primary" disabled={disableButton} onClick={() => handleClose()}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VerifyStockMovementStageModal;
