import React, { createContext, useState} from "react";
// import { useToastContext } from "../contexts";
import AxiosInstance from "../axios";

export const PositionContext = createContext();

export const PositionState = (props) => {
  const [positions, setPositions] = useState([]);

  async function refreshData() {
    const response = await PositionsEntries();
    setPositions(response);
  }

  return (
    <PositionContext.Provider value={{positions, refreshData}}>
      {props.children}
    </PositionContext.Provider>
  )
}

const PositionsEntries = async () => {

  // const { showToast } = useToastContext();
  let retVal = [];

  const getPositions = await AxiosInstance.get(
    `positions`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
    // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
    return false;
  });

  if (getPositions) {
    retVal = getPositions?.data;
  }

  return retVal;

}