import React, { useState, useEffect, useContext } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, FieldContext, PayscaleContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const AddPayscaleModal = () => {
  
  const { showToast } = useToastContext();

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const payscaleContext = useContext(PayscaleContext);

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);
  
  const [selectedField, setSelectedField] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleClose = () => {
    setDisableButton(false);
    setSelectedField("");
    setDate();
    setSelectedStatus("");
    setVisible(false);
  }

  useEffect(() => {
    async function fetchData() {
      setDate();
    }
    fetchData();
  }, []);

  const setDate = () => {
    let today = new Date();
    let minTime = new Date(today);
    let maxTime = new Date(today);

    if (minTime) {
      minTime.setHours(0, 0, 0, 0);
    }
    
    if (maxTime) {
      maxTime.setHours(23, 59, 59, 0);
    }
    setSelectedStartDate( minTime );
    setSelectedEndDate( maxTime );
  }

  const intDT = (dt) => {
    const date = new Date(dt);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-indexed
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedField) {
      notif = "Field must not be empty.";
    } else if (!selectedStartDate) {
      notif = "Start Date must not be empty.";
    } else if (!selectedEndDate) {
      notif = "End Date must not be empty.";
    } else if (!selectedStatus) {
      notif = "Status must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("field_id", selectedField);
      formData.append("start_date", intDT(selectedStartDate));
      formData.append("end_date", intDT(selectedEndDate));
      formData.append("status", selectedStatus);

      const callAPI = await AxiosInstance.post("/payscale", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        payscaleContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        add new
      </span>

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="field">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setSelectedField(_.target.value);
            }}
            value={selectedField}
            required
          >
            <option value="">Please select</option>
            {
              fields?.length > 0 && fields?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="startdate">Start Date</label>
          <Calendar
            id="startdate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedStartDate}
            maxDate={selectedEndDate}
            onChange={(e) => setSelectedStartDate(e.value)}
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="enddate">End Date</label>
          <Calendar
            id="enddate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedEndDate}
            minDate={selectedStartDate}
            onChange={(e) => setSelectedEndDate(e.value)}
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="status">Status</label>
          <select
            id="status"
            name="status"
            className="form-control"
            onChange={(_) => {
              setSelectedStatus(_.target.value);
            }}
            value={selectedStatus}
            required
          >
            <option value="">Please select</option>
            {
              allOption["typeOfStatus"]?.map((p, i) => (
                <option key={p.id} value={p.id}>{p.name}</option>
              ))
            }
          </select>
        </div>
      </Dialog>
    </>
  );
};

export default AddPayscaleModal;
