import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, EmployeeContext, PositionContext, TripSingleContext, useToastContext } from "../../contexts";

const AddTripLoaderModal = (props) => {
  
  const { showToast } = useToastContext();

  const tripSingleContext = useContext(TripSingleContext);
  const {tripSingle} = tripSingleContext;

  const allContext = useContext(AllContext);
  const {allFormula} = allContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [tripId, setTripId] = useState("");
  const [tripStage, setTripStage] = useState("");
  const [tripDestination, setTripDestination] = useState("");
  
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedAction, setSelectedAction] = useState("");

  useEffect(() => {
    async function fetchData() {
      setTripId(tripSingle && tripSingle.id ? tripSingle.id : "");
      setTripDestination(tripSingle && tripSingle.destination ? tripSingle.destination : "");
      setTripStage(tripSingle && tripSingle.stage ? tripSingle.stage : "");
    }
    fetchData();
  }, [tripSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!tripId) {
      notif = "Unable to continue this process";
    } else if (!selectedEmployee) {
      notif = "Employee must not be empty.";
    } else if (!selectedAction) {
      notif = "Action must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){

      let formData = new FormData();
      formData.append("trip_id", tripId);
      formData.append("employee_id", selectedEmployee.id);
      formData.append("action", selectedAction);

      const addWork = await AxiosInstance.post(`/triploader`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addWork) {
        handleClose();
      } else {
        setDisableButton(false);
      }

    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setSelectedEmployee("");
    setSelectedAction("");
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  const valueTemplate = (employee, props) => {
    if (employee) {
      return (
        <div className="flex align-items-center">
            <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemTemplate = (employee) => {
    return (
      <div className="flex align-items-center">
          <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
      </div>
    );
  };

  return (
    <>
      {
        tripStage !== "approved" && tripStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            add new
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="employee_id">Employee</label>
          <Dropdown
            className="form-control"
            value={selectedEmployee}
            options={employees}
            onChange={(_) => {
              let ss = _.target.value;
              setSelectedEmployee(ss);
            }}
            optionLabel="name"
            filter
            filterBy="name"
            placeholder="Search by Employee"
            valueTemplate={valueTemplate} 
            itemTemplate={itemTemplate}
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="action">Action</label>
          <select
            id="action"
            name="action"
            className="form-control"
            onChange={(_) => {
              setSelectedAction(_.target.value);
            }}
            value={selectedAction}
            required
          >
            <option value="">Please select</option>
            {
              tripDestination && allFormula[tripDestination]?.map((lt, i) => (
                <option key={lt.id} value={lt.id}>{lt.name} ({lt.formulaone}{lt.formulatwo})</option>
              ))
            }
          </select>
        </div>
      </Dialog>
    </>
  );
};

export default AddTripLoaderModal;
