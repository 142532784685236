import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AddStockCategoryModal, EditStockCategoryModal } from "./modals";
import { AllContext, StockCategoryContext, UserAAContext } from "../contexts";
import Header from "./layout/Header";

// https://primereact.org/datatable/
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';

const StockCategory = () => {

  const stockCategoryContext = useContext(StockCategoryContext);
  const {stockCategory} = stockCategoryContext;

  const allContext = useContext(AllContext);
  const {allOption} = allContext;

  const userAAContext = useContext(UserAAContext);
  const {userDevice} = userAAContext;
  
  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(stockCategory?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [stockCategory, stockCategoryContext, refreshCall]);

  useEffect(() => {
    async function fetchData() {
      userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
    }
    fetchData();
  }, [userDevice]);

  // Columns
  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'name', header: 'Name', visible: true },
    { field: 'type', header: 'Type', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'name', header: 'Name', visible: true },
    { field: 'type', header: 'Type', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'name', header: 'Name', visible: true },
    { field: 'type', header: 'Type', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  // Filters
  const [filters, setFilters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  const nameFilter = (options) => {
    return (
      <InputText
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.name.value = e.target.value;
          setFilters(newFilters);
        }}
        value={filters.name.value ? filters.name.value : ""}
        placeholder="Search by Notes"
      />
    );
  }

  const typeFilter = (options) => {
    return (
      <Dropdown
        value={allOption["typeOfStockCategory"]?.find(i => i.id === filters.type.value)}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.type.value = e.value.slug;
          setFilters(newFilters);
        }}
        options={allOption["typeOfStockCategory"]}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Type"
        className="w-full md:w-14rem"
      />
    );
  }

  // Pagination

  // Data
  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await stockCategoryContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  // Template
  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const typeTemplate = (sc) => {
    return sc.type && allOption["typeOfStockCategory"]?.find(i => i.id === sc.type)?.name;
  };

  const editViewTemplate = (sc) => {
    return <EditStockCategoryModal sc={sc} />;
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Stock Category</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="visibleColumn">Column</label>
                      <MultiSelect
                        id="visibleColumn"
                        className="w-100"
                        value={visibleColumns.filter((col) => col.visible).map((col) => col.field)}
                        options={visibleColumns.map((col) => ({ label: col.header, value: col.field }))}
                        onChange={(e) => toggleColumnsVisibility(e.value)}
                        placeholder="Select Columns"
                      />
                    </div>
                  </div>
                </div>
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
                              }}
                            >
                              reset column
                            </span>
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                const newFilters = { ...filters };
                                newFilters.name.value = null;
                                newFilters.type.value = null;
                                setFilters(newFilters);
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddStockCategoryModal />
                          </div>
                        </div>
                        <hr />
                        <div className="table-responsive">
                          <DataTable
                            value={stockCategory}
                            stripedRows
                            removableSort
                            paginator
                            rows={10}
                            columnResizeMode="expand"
                            resizableColumns
                            filters={filters}
                            filterDisplay="row"
                            filterClearIcon={null}
                            dataKey="id"
                            emptyMessage="No stocks found."
                            loading={showLoading} 
                          >
                            {
                              visibleColumns.map((col) => (
                                <Column
                                  key={col.field}
                                  field={col.field}
                                  header={col.field === 'edit_view' ? '' : col.header}
                                  filter={col.field === 'name' || col.field === 'type'}
                                  filterElement={(rowData) => (
                                    col.field === 'name' ? 
                                      nameFilter(rowData) : 
                                        (
                                          col.field === 'type' ?
                                            typeFilter(rowData) : 
                                              undefined
                                        )
                                    )
                                  }
                                  body={(rowData) => ( 
                                      col.field === 'type' ?
                                      typeTemplate(rowData) :
                                          (
                                            col.field === 'edit_view' ?
                                              editViewTemplate(rowData) :
                                                rowData[col.field]
                                          )
                                    )
                                  }
                                  style={{
                                    display: col.visible ? 'table-cell' : 'none',
                                    whiteSpace: 'normal',
                                    maxWidth: '200px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                  sortable={col.field !== 'edit_view'}
                                  showFilterMenu={false}
                                />
                              ))
                            }
                          </DataTable>
                        </div>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockCategory;
