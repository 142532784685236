import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AddStockShopModal, EditStockShopModal } from "./modals";
import { StockShopContext } from "../contexts";
import Header from "./layout/Header";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

const StockShop = () => {

  const stockShopContext = useContext(StockShopContext);
  const {stockShop} = stockShopContext;
  
  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(stockShop?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [stockShop, stockShopContext, refreshCall]);

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await stockShopContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Stock Shop</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  :
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddStockShopModal />
                          </div>
                        </div>
                        <hr />
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Phone</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                stockShop?.length > 0 && stockShop?.map((s, i) => (
                                  <tr key={i}>
                                    <td>{s.name}</td>
                                    <td>{s.address}</td>
                                    <td>{s.phone}</td>
                                    <td>
                                      <EditStockShopModal ss={s} />
                                    </td>                          
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockShop;
