import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { AddFileModal } from "./modals";
import Header from "./layout/Header";
import { FileContext, FieldContext, UserAAContext } from "../contexts";

// https://primereact.org/datatable/
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Paginator } from "primereact/paginator";

const Files = () => {

  const navigate = useNavigate();

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const fileContext = useContext(FileContext);
  const {files, conditions} = fileContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice, userField} = userAAContext;

  const [selectedPage, setSelectedPage] = useState(conditions && conditions["page"] !== undefined && conditions["page"] !== "" ? conditions["page"] : 1);
  const [startFromRecord, setStartFromRecord] = useState(0);
  const [rowsToFetch, setRowsToFetch] = useState(conditions && conditions["limit"] !== undefined && conditions["limit"] !== "" ? conditions["limit"] : 10);
  const [sortField, setSortField] = useState(conditions && conditions["sort_by"] !== undefined && conditions["sort_by"] !== "" ? conditions["sort_by"] : "");
  const [sortOrder, setSortOrder] = useState(conditions && conditions["sort_order"] !== undefined && conditions["sort_order"] !== "" ? conditions["sort_order"] : "");
  const [selectedFilterField, setSelectedFilterField] = useState(conditions && conditions["field_id"] !== undefined && conditions["field_id"] !== "" ? fields?.find(i => i.id === parseInt(conditions["field_id"])) : []);
  const [typeFilterNotes, setTypeFilterNotes] = useState(conditions && conditions["notes"] !== undefined && conditions["notes"] !== "" ? fields.notes : "");

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0)) { fieldContext.refreshData(); }
      if (!(files?.length > 0)) { refreshData(); }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      let cdtns = [];
      cdtns["page"] = selectedPage;
      cdtns["limit"] = rowsToFetch;
      cdtns["sort_by"] = sortField;
      cdtns["sort_order"] = sortOrder;
      cdtns["field_id"] = selectedFilterField && selectedFilterField.id ? selectedFilterField.id : "";
      cdtns["notes"] = typeFilterNotes && typeFilterNotes ? typeFilterNotes : "";
      fileContext.applyConditions(cdtns);
    }
    fetchData();
  }, [selectedPage, rowsToFetch, sortField, sortOrder, selectedFilterField, typeFilterNotes]);

  useEffect(() => {
    async function fetchData() {
      userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
    }
    fetchData();
  }, [userDevice]);

  // Columns
  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: true },
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'file_date', header: 'Date', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: true },
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'file_date', header: 'Date', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: false },
    { field: 'field_id', header: 'Field', visible: false },
    { field: 'file_date', header: 'Date', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  const fieldFilter = () => {
    return (
      <Dropdown
        value={fields?.find(i => i.id === parseInt(selectedFilterField.id))}
        onChange={(e) => {
          setSelectedFilterField(e.value);
        }}
        options={( userField? fields?.filter(obj => parseInt(obj.id) === parseInt(userField)): fields )}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const notesFilter = (options) => {
    return (
      <InputText
        onInput={(e) => {
          setTypeFilterNotes(e.target.value);
        }}
        value={typeFilterNotes}
        placeholder="Search by Notes"
      />
    );
  }

  // Pagination
  const updateSorting = (e) => {
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
  }

  const onPageChange = (event) => {
    setRowsToFetch(event.rows);
    setSelectedPage(parseInt(event.page) + 1);
    setStartFromRecord(event.first);
  };

  // Data
  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await fileContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  // Template
  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const stageTemplate = (files) => {
    return files.stage ? 
              files.stage === "approved" || files.stage === "approvedbysystem"
                ? <i className="pi pi-check" style={{ fontSize: "1rem", color: "green" }} />
                  : files.stage === "verified" || files.stage === "verifiedbysystem"
                    ? <i className="pi pi-eye" style={{ fontSize: "1rem", color: "orange" }} />
                      : files.stage === "submitted"
                        ? <i className="pi pi-eye-slash" style={{ fontSize: "1rem", color: "red" }} />
                          : <i className="pi pi-clock" style={{ fontSize: "1rem", color: "grey" }} />
            : ""
  };

  const fieldTemplate = (b) => {
    return fields?.length > 0 && b.field_id ? fields?.find(i => i.id === parseInt(b.field_id))?.name : "";
  };

  const editViewTemplate = (file) => {
    return (
      <>
        <span onClick={() => {navigate(`/file/${file.id}`);}} className="link-isday ms-3">view</span>
      </>
    );
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Files</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="visibleColumn">Column</label>
                      <MultiSelect
                        id="visibleColumn"
                        className="w-100"
                        value={visibleColumns.filter((col) => col.visible).map((col) => col.field)}
                        options={visibleColumns.map((col) => ({ label: col.header, value: col.field }))}
                        onChange={(e) => toggleColumnsVisibility(e.value)}
                        placeholder="Select Columns"
                      />
                    </div>
                  </div>
                </div>
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
                              }}
                            >
                              reset column
                            </span>
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                setSelectedFilterField("");
                                setTypeFilterNotes("");
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddFileModal />
                          </div>
                        </div>
                        <hr />
                        <DataTable
                          value={files?.data}
                          stripedRows
                          removableSort
                          rows={10}
                          columnResizeMode="expand"
                          resizableColumns
                          // filters={filters}
                          filterDisplay="row"
                          filterClearIcon={null}
                          dataKey="id"
                          emptyMessage="No files found."
                          sortField={sortField}
                          sortOrder={sortOrder}
                          onSort={updateSorting}
                        >
                          {
                            visibleColumns.map((col) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                filter={col.field === 'field_id' || col.field === 'notes'}
                                filterElement={(rowData) => (col.field === 'field_id' ? fieldFilter(rowData) : (col.field === 'notes' ? notesFilter(rowData) : undefined))}
                                body={(rowData) => (col.field === 'stage' ? stageTemplate(rowData) : (col.field === 'field_id' ? fieldTemplate(rowData) : (col.field === 'edit_view' ? editViewTemplate(rowData) : rowData[col.field])))}
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                                sortable={col.field !== 'stage' && col.field !== 'edit_view'}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                        <Paginator first={startFromRecord} rows={rowsToFetch} totalRecords={files && files?.pagination ? files?.pagination.total_records : 10 } rowsPerPageOptions={[5, 10]} onPageChange={onPageChange} template={{ layout: "PrevPageLink PageLinks CurrentPageReport NextPageLink RowsPerPageDropdown" }} />
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Files;
