import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, FieldContext, UserContext, PayscaleContext, UserAAContext } from "../contexts";
import { AddPayscaleModal, EditPayscaleModal } from "./modals";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

// https://primereact.org/datatable/
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";

const Payscale = () => {

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const payscaleContext = useContext(PayscaleContext);
  const {payscale, conditions} = payscaleContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const userContext = useContext(UserContext);
  const {users} = userContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice, userField} = userAAContext;
  
  const [selectedPage, setSelectedPage] = useState(conditions && conditions["page"] !== undefined && conditions["page"] !== "" ? conditions["page"] : 1);
  const [startFromRecord, setStartFromRecord] = useState(0);
  const [rowsToFetch, setRowsToFetch] = useState(conditions && conditions["limit"] !== undefined && conditions["limit"] !== "" ? conditions["limit"] : 10);
  const [sortField, setSortField] = useState(conditions && conditions["sort_by"] !== undefined && conditions["sort_by"] !== "" ? conditions["sort_by"] : "");
  const [sortOrder, setSortOrder] = useState(conditions && conditions["sort_order"] !== undefined && conditions["sort_order"] !== "" ? conditions["sort_order"] : "");
  const [selectedField, setSelectedField] = useState(conditions && conditions["field_id"] !== undefined && conditions["field_id"] !== "" ? fields?.find(i => i.id === parseInt(conditions["field_id"])) : []);
  const [selectedStatus, setSelectedStatus] = useState(conditions && conditions["status"] !== undefined && conditions["status"] !== "" ? conditions["status"] : "");

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);
  
  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(payscale?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [payscale, users, payscaleContext, userContext, refreshCall]);
  
  const onPageChange = (event) => {
    setRowsToFetch(event.rows);
    setSelectedPage(parseInt(event.page) + 1);
    setStartFromRecord(event.first);
  };

  useEffect(() => {
    async function fetchData() {
      let cdtns = [];
      cdtns["page"] = selectedPage;
      cdtns["limit"] = rowsToFetch;
      cdtns["sort_by"] = sortField;
      cdtns["sort_order"] = sortOrder;
      cdtns["field_id"] = selectedField && selectedField.id ? selectedField.id : "";
      cdtns["status"] = selectedStatus && selectedStatus.slug ? selectedStatus.slug : "";
      payscaleContext.applyConditions(cdtns);
    }
    fetchData();
  }, [selectedPage, /*startFromRecord,*/ rowsToFetch, selectedField, selectedStatus, sortField, sortOrder]);

  // Columns
  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'start_date', header: 'Start Date', visible: true },
    { field: 'end_date', header: 'End Date', visible: true },
    { field: 'status', header: 'Status', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);
  
  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const statusFilter = () => {
    return (
      <Dropdown
        value={selectedStatus}
        onChange={(e) => setSelectedStatus(e.value)}
        options={allOption["typeOfStatus"]}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="Field"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const statusTemplate = (p) => {
    return p.status ? allOption["typeOfStatus"]?.find(i => i.id === p.status)?.name : "";
  };

  const fieldFilter = () => {
    return (
      <Dropdown
        value={selectedField}
        onChange={(e) => setSelectedField(e.value)}
        options={( userField? fields?.filter(obj => obj.id === parseInt(userField)): fields )}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="Fields"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const fieldTemplate = (p) => {
    return fields?.length > 0 && p.field_id ? fields?.find(i => i.id === parseInt(p.field_id))?.name : "";
  };

  const editViewTemplate = (p) => {
    return <EditPayscaleModal ps={p} />;
  };

  const updateSorting = (e) => {
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
  }

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await payscaleContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Payscale</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-12 d-flex justify-content-end small">
                            <span
                              className="link-isday me-3"
                              onClick={() => {
                                setSelectedField("");
                                setSelectedStatus("");
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddPayscaleModal />
                          </div>
                        </div>
                        <hr />
                        <DataTable value={payscale.data} stripedRows removableSort sortField={sortField} sortOrder={sortOrder} onSort={updateSorting} >
                          {
                            visibleColumns.map((col) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                filter={col.field === 'field_id' || col.field === 'work_type'}
                                filterElement={(rowData) => (col.field === 'field_id' ? fieldFilter(rowData) : (col.field === 'status' ? statusFilter(rowData) : undefined))}
                                body={(rowData) => (col.field === 'field_id' ? fieldTemplate(rowData) : (col.field === 'status' ? statusTemplate(rowData) : (col.field === 'edit_view' ? editViewTemplate(rowData) : rowData[col.field])))}
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                                sortable={col.field !== 'stage' && col.field !== 'edit_view'}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                        <Paginator first={startFromRecord} rows={rowsToFetch} totalRecords={payscale && payscale.pagination ? payscale.pagination.total_records : 10 } rowsPerPageOptions={[5, 10, 25]} onPageChange={onPageChange} template={{ layout: "PrevPageLink PageLinks CurrentPageReport NextPageLink RowsPerPageDropdown" }} />
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payscale;
