import React, { createContext, useState} from "react";
import AxiosInstance from "../axios";

export const FieldContext = createContext();

export const FieldState = (props) => {
  const [fields, setFields] = useState([]);

  async function refreshData() {
    const response = await FieldsEntries();
    setFields(response);
  }

  return (
    <FieldContext.Provider value={{fields, refreshData}}>
      {props.children}
    </FieldContext.Provider>
  )
}

const FieldsEntries = async () => {
  let retVal = [];
  const getFields = await AxiosInstance.get(
    `fields`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    return false;
  });

  if (getFields) {
    retVal = getFields?.data;
  }

  return retVal;

}