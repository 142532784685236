import React, { createContext, useState} from "react";
// import { useToastContext } from "../contexts";
import AxiosInstance from "../axios";

export const MachineContext = createContext();

export const MachineState = (props) => {
  const [machines, setMachines] = useState([]);

  async function refreshData() {
    const response = await MachineEntries();
    setMachines(response);
  }

  return (
    <MachineContext.Provider value={{machines, refreshData}}>
      {props.children}
    </MachineContext.Provider>
  )
}

const MachineEntries = async () => {
  
  // const { showToast } = useToastContext();
  let retVal = [];

  const getMachine = await AxiosInstance.get(
    `machines`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
    // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
    return false;
  });

  if (getMachine) {
    retVal = getMachine?.data;
  }

  return retVal;

}