import React, { useContext, useEffect, useRef, useState } from "react";
import AxiosInstance from "../axios";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate, useParams } from "react-router-dom";
import { EditTripModal, AddTripHarvesterModal, EditTripHarvesterModal, DeleteTripHarvesterModal, EditTripPayloadModal, AddTripLoaderModal, EditTripLoaderModal, DeleteTripLoaderModal, VerifyTripModal, AddTripImageModal } from "./modals";
import { AllContext, BlockContext, EmployeeContext, FieldContext, MachineContext, PositionContext, UserContext, UserAAContext, StockContext, useToastContext } from "../contexts";
import { TripSingleContext } from "../contexts";
import { baseURL, imageBaseURL } from "../axios";

import { Galleria } from "primereact/galleria";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const TripView = () => {

  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const galleria = useRef(null);

  const tripSingleContext = useContext(TripSingleContext);
  const {tripSingle} = tripSingleContext;

  const allContext = useContext(AllContext);
  const {allOption, allFormula} = allContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const stockContext = useContext(StockContext);
  const {stocks} = stockContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const blockContext = useContext(BlockContext);
  const {units} = blockContext;
  const userContext = useContext(UserContext);
  const {users} = userContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice, userField} = userAAContext;

  let { trip_id } = useParams();
  const [tModal, setTModal] = useState(Math.round((new Date()).getTime()));
  
  const [tripImage, setTripImage] = useState([]);
  const [tripHarvester, setTripHarvester] = useState([]);
  const [tripPayload, setTripPayload] = useState([]);
  const [tripLoader, setTripLoader] = useState([]);
  const [tripExpense, setTripExpense] = useState([]);
  const [tripStage, setTripStage] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [refreshCall, setRefreshCall] = useState(true);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!(employees?.length > 0) && refreshCall) { employeeContext.refreshData(); }
      if (!(units?.length > 0) && refreshCall) { blockContext.refreshData(); }
      if (!(stocks?.length > 0) && refreshCall) { stockContext.refreshData(); }
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(machines?.length > 0) && refreshCall) { machineContext.refreshData(); }
      if (!(positions?.length > 0) && refreshCall) { positionContext.refreshData(); }
      if (!(users?.length > 0) && refreshCall) { userContext.refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [employees, units, stocks, fields, machines, users, employeeContext, blockContext, stockContext, fieldContext, machineContext, userContext, refreshCall]);

  useEffect(() => {
    function fetchData() {
      if ( tripSingle && tripSingle?.id ) {
        setTripHarvester(tripSingle?.trip_harvester);
        setTripImage(tripSingle?.trip_image);
        setTripPayload(tripSingle?.trip_payload);
        setTripLoader(tripSingle?.trip_loaders);
        setTripExpense(tripSingle?.expense_employees);
        setTripStage(tripSingle?.history_stage);
      }
    }
    fetchData();
  }, [tripSingle]);

  useEffect(() => {
    async function fetchData() {
      new Promise(function(resolve, reject) {
        setShowLoading(true);
        resolve(1);
      }).then(async function(result) {
        const getWorkSingle = await AxiosInstance.get(
          `/trips/${trip_id}`,
          {
            cache: {
              ignoreCache: true,
            },
          }
        ).catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
  
        if(getWorkSingle){
          tripSingleContext.addData(getWorkSingle?.data);
        }
        return 1;
      }).finally(function(result) {
        setShowLoading(false);
      });
    }
    fetchData();
  }, [tModal]);

  // useEffect(() => {
  //   async function fetchData() {
  //     userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
  //   }
  //   fetchData();
  // }, [userDevice]);

  // Columns
  const [visiblePayColumns, setVisiblePayColumns] = useState([
    { field: 'employee_id', header: 'Employee', visible: true },
    { field: 'for', header: 'For', visible: true },
    { field: 'expense', header: 'Pay', visible: true }
  ]);

  const [visibleLoaderColumns, setVisibleLoaderColumns] = useState([
    { field: 'employee_id', header: 'Employee', visible: true },
    { field: 'action', header: 'Action', visible: true },
    { field: 'edit_view_loader', header: '', visible: true },
  ]);

  const [visibleStageColumns, setVisibleStageColumns] = useState([
    { field: 'created_at', header: 'Date', visible: true },
    { field: 'changed_to', header: 'Action', visible: true },
    { field: 'changed_by', header: 'By', visible: true },
  ]);

  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'employee_id', header: 'Employee', visible: true },
    { field: 'harvest_date', header: 'Date', visible: true },
    { field: 'block_unit_id', header: 'Unit', visible: true },
    { field: 'type', header: 'Type', visible: true },
    { field: 'qty', header: 'Qty', visible: true },
    { field: 'weight', header: 'Weight', visible: true },
    { field: 'extra', header: 'Extra', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'employee_id', header: 'Employee', visible: true },
    { field: 'harvest_date', header: 'Date', visible: true },
    { field: 'block_unit_id', header: 'Unit', visible: true },
    { field: 'type', header: 'Type', visible: true },
    { field: 'qty', header: 'Qty', visible: true },
    { field: 'weight', header: 'Weight', visible: true },
    { field: 'extra', header: 'Extra', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'employee_id', header: 'Employee', visible: true },
    { field: 'harvest_date', header: 'Date', visible: false },
    { field: 'block_unit_id', header: 'Unit', visible: true },
    { field: 'type', header: 'Type', visible: true },
    { field: 'qty', header: 'Qty', visible: true },
    { field: 'weight', header: 'Weight', visible: true },
    { field: 'extra', header: 'Extra', visible: true },
    { field: 'notes', header: 'Notes', visible: false },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  // Template
  const itemTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/trip-images/large/${trip_id}/${item.image}`} alt="" style={{ width: "100%" }} />
  }

  const thumbnailTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/trip-images/thumb/${trip_id}/${item.image}`} alt={item.alt} style={{ width: "70%" }} />
  }

  const actionTemplate = (stage) => {
    return (
      stage && stage?.changed_to ? (
        stage?.changed_to === "approved" || stage?.changed_to === "approvedbysystem" ?
          "Approve" : (
            stage?.changed_to === "verified" || stage?.changed_to === "verifiedbysystem" ?
              "Verify" : 
                "Submit"
          )
      ) : "");
  };

  const byTemplate = (stage) => {
    return users?.length > 0 && stage.changed_by && users?.find(i => i.id === parseInt(stage.changed_by)) ? users?.find(i => i.id === parseInt(stage.changed_by))?.name : "";
  };

  const dtTemplate = (dt) => {
    const date = new Date(dt);
    const options = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const employeeTemplate = (trip) => {
    return employees?.length > 0 && trip.employee_id ? employees?.find(i => i.id === parseInt(trip.employee_id))?.name + (positions?.length > 0 && positions?.find(i => i.id === parseInt(employees?.find(i => i.id === parseInt(trip.employee_id))?.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employees?.find(i => i.id === parseInt(trip.employee_id))?.position_id))?.name + ' )' : '' ) : "";
  };

  const actTemplate = (loader) => {
    return loader.action &&
      (
        allFormula[tripSingle.destination]?.find(i => i.id === loader.action)?.name
        + ' ('
        +
          (
            allFormula[tripSingle.destination]?.find(i => i.id === loader.action).formulaone ? allFormula[tripSingle.destination]?.find(i => i.id === loader.action).formulaone : ''
          )
        +
          (
            allFormula[tripSingle.destination]?.find(i => i.id === loader.action).formulatwo ? allFormula[tripSingle.destination]?.find(i => i.id === loader.action).formulatwo : ''
          )
        + ')'
      );
  };

  const unitTemplate = (trip) => {
    return units?.length > 0 && trip.block_unit_id ? (
      <>
        {units?.find(i => i.id === parseInt(trip.block_unit_id))?.name + ' ( ' + units?.find(i => i.id === parseInt(trip.block_unit_id)).medium + ' )'}
        <br />
        {trip?.land_type && allOption["typeOfHarvest"]?.find(i => i.id === trip?.land_type)?.name}
      </>
    ): ""
  };

  const typeTemplate = (trip) => {
    let retVal = "";
    if ( trip?.type ) {
      if ( trip?.calculate_weight ) {
        let ea = trip.calculate_weight["w" + trip.type] ? trip.calculate_weight["w" + trip.type] : 1;
        retVal = (
          <>
            {trip?.type}
            <br />
            <i>@{ea}</i>
          </>
        );
      } else {
        retVal = (<>{trip?.type}</>);
      }
    }
    return retVal;
  };

  const weightTemplate = (trip) => {
    if ( trip?.type && trip?.calculate_weight ) {
      let ea = trip.calculate_weight["w" + trip.type] ? trip.calculate_weight["w" + trip.type] : 1;
      let total = (trip.qty * ea).toFixed(2);;
      return total ? total : ""
    }
    return "";
  };

  const editViewLoaderTemplate = (loader) => {
    return (
      <>
        <EditTripLoaderModal tl={loader} sRC={setTModal} />
        <DeleteTripLoaderModal tl={loader} sRC={setTModal} />
      </>
    );
  };

  const editViewTemplate = (trip) => {
    return (
      <>
        <EditTripHarvesterModal hs={trip} sRC={setTModal} />
        <DeleteTripHarvesterModal hs={trip} sRC={setTModal} />
      </>
    );
  };

  return (
    <div className="container bg-white content-container">
      <div className="row text-left">
        <span className="link-isday" onClick={()=>navigate(-1)}>
          &lt; Back
        </span>
      </div>
      {
        showLoading
        ?
          <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
          </div>
        : 
          (
            <>
              <h2 className="h5 pt-3 pb-3 d-flex justify-content-center">Trip</h2>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">INFORMATION</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <EditTripModal sRC={setTModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Field</td>
                              <td>{tripSingle && fields?.length > 0 && tripSingle.field_id && fields?.find(i => i.id === parseInt(tripSingle.field_id)) && fields?.find(i => i.id === parseInt(tripSingle.field_id))?.name}</td>
                            </tr>
                            <tr>
                              <td>Date</td>
                              <td>{tripSingle && tripSingle.trip_date ? tripSingle.trip_date : ""}</td>
                            </tr>
                            <tr>
                              <td>Destination</td>
                              <td>{tripSingle && tripSingle.destination && allOption["typeOfTripDestination"]?.find(i => i.id === tripSingle.destination)?.name}</td>
                            </tr>
                            <tr>
                              <td>Machine</td>
                              <td>{tripSingle && machines?.length > 0 && tripSingle.machine_id && machines?.find(i => i.id === parseInt(tripSingle.machine_id)) && machines?.find(i => i.id === parseInt(tripSingle.machine_id))?.name}</td>
                            </tr>
                            <tr>
                              <td>Driver</td>
                              <td>{tripSingle && employees?.length > 0 && tripSingle.drive_by && employees?.find(i => i.id === parseInt(tripSingle.drive_by)) && employees?.find(i => i.id === parseInt(tripSingle.drive_by))?.name} {positions?.length > 0 && positions?.find(i => i.id === parseInt(employees?.find(i => i.id === parseInt(tripSingle.drive_by))?.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employees?.find(i => i.id === parseInt(tripSingle.drive_by))?.position_id))?.name + ' )' : '' }</td>
                            </tr>
                            <tr>
                              <td>Scale By</td>
                              <td>{tripSingle && employees?.length > 0 && tripSingle.scale_by && employees?.find(i => i.id === parseInt(tripSingle.scale_by)) && employees?.find(i => i.id === parseInt(tripSingle.scale_by))?.name} {positions?.length > 0 && positions?.find(i => i.id === parseInt( employees?.find(i => i.id === parseInt(tripSingle.scale_by))?.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employees?.find(i => i.id === parseInt(tripSingle.scale_by))?.position_id))?.name + ' )' : '' }</td>
                            </tr>
                            <tr>
                              <td>Scale At</td>
                              <td>{tripSingle && tripSingle.scale_at ? tripSingle.scale_at : ""}</td>
                            </tr>
                            <tr>
                              <td>Notes</td>
                              <td>{tripSingle && tripSingle.notes ? tripSingle.notes : ""}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <div className="row mb-2">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                              <h3 className="h6">Image</h3>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                              <AddTripImageModal sRC={setTModal} />
                            </div>
                          </div>
                          <hr />
                          <div className="flex justify-content-center">
                            <Galleria ref={galleria} value={tripImage} numVisible={7} style={{ maxWidth: '850px' }}
                                activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                                circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />

                            <div className="grid row mb-2" style={{ maxWidth: '400px' }}>
                              {
                                tripImage && tripImage.map((image, index) => {
                                  let imgEl = <img src={`${imageBaseURL}uploads/trip-images/large/${trip_id}/${image.image}`} onClick={() => {setActiveIndex(index); galleria.current.show()}} style={{ width: "100%" }} />
                                  return (
                                    <div className="col-lg-4 mt-3" key={index}>
                                        {imgEl}
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body pb-0">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 container-stage d-flex align-items-center justify-content-center">
                        {
                          tripSingle.stage === "approved" || tripSingle.stage === "approvedbysystem"
                            ? <i className="pi pi-check" style={{ fontSize: "7rem", color: "green" }} />
                              : tripSingle.stage === "verified" || tripSingle.stage === "verifiedbysystem"
                                ? <i className="pi pi-eye" style={{ fontSize: "7rem", color: "orange" }} />
                                  : tripSingle.stage === "submitted"
                                    ? <i className="pi pi-eye-slash" style={{ fontSize: "7rem", color: "red" }} />
                                      : <i className="pi pi-clock" style={{ fontSize: "7rem", color: "grey" }} />
                        }
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row mb-2">
                        <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                          <h3 className="h6">Stage</h3>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-7 col-7 d-flex justify-content-end small">
                          <span className="link-secondary"><VerifyTripModal sRC={setTModal} /></span>
                        </div>
                      </div>
                      <hr />
                      <DataTable
                        value={tripStage}
                        stripedRows
                        removableSort
                        rows={20}
                        columnResizeMode="expand"
                        resizableColumns
                        dataKey="id"
                        emptyMessage="No Stage found."
                      >
                        {
                          visibleStageColumns.map((col, index) => (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.header}
                              body={(rowData) => (
                                col.field === 'created_at' ?
                                  dtTemplate(rowData?.created_at) :
                                    (
                                      col.field === 'changed_to' ?
                                        actionTemplate(rowData) :
                                          (
                                            col.field === 'changed_by' ?
                                              byTemplate(rowData) :
                                                rowData[col.field]
                                          )
                                    )
                                )
                              }
                              style={{
                                display: col.visible ? 'table-cell' : 'none',
                                whiteSpace: 'normal',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '10px'
                              }}
                              showFilterMenu={false}
                            />
                          ))
                        }
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Payload</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <EditTripPayloadModal sRC={setTModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Gross Weigh ( KG )</td>
                              <td>{tripPayload && tripPayload.gross_weight && tripPayload.gross_weight}</td>
                            </tr>
                            <tr>
                              <td>Tare Weight ( KG )</td>
                              <td>{tripPayload && tripPayload.tare_weight && tripPayload.tare_weight}</td>
                            </tr>
                            <tr>
                              <td>Net Weight ( KG )</td>
                              <td>{tripPayload && tripPayload.net_weight && tripPayload.net_weight}</td>
                            </tr>
                            <tr>
                              <td>Deduction ( KG )</td>
                              <td>{tripPayload && tripPayload.deduction && tripPayload.deduction}</td>
                            </tr>
                            <tr>
                              <td>Deduction Notes</td>
                              <td>{tripPayload && tripPayload.deduction_notes && tripPayload.deduction_notes}</td>
                            </tr>
                            <tr>
                              <td>Net Payload ( KG )</td>
                              <td>{tripPayload && tripPayload.net_payload && tripPayload.net_payload}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Loader / Supir Premi</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddTripLoaderModal sRC={setTModal} />
                        </div>
                      </div>
                      <hr />
                      <DataTable
                        value={tripLoader}
                        stripedRows
                        removableSort
                        rows={20}
                        columnResizeMode="expand"
                        resizableColumns
                        dataKey="id"
                        emptyMessage="No loader found."
                      >
                        {
                          visibleLoaderColumns.map((col, index) => (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.field === 'edit_view' ? '' : col.header}
                              body={(rowData) => (
                                col.field === 'employee_id' ?
                                  employeeTemplate(rowData) :
                                    (
                                      col.field === 'action' ?
                                        actTemplate(rowData) : 
                                          (
                                            col.field === 'edit_view_loader' ?
                                              editViewLoaderTemplate(rowData) :
                                                rowData[col.field]
                                          )
                                    )
                                )
                              }
                              style={{
                                display: col.visible ? 'table-cell' : 'none',
                                whiteSpace: 'normal',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '10px'
                              }}
                              showFilterMenu={false}
                            />
                          ))
                        }
                      </DataTable>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h3 className="h6">Loader / Supir Premi <strong>Pay</strong></h3>
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <DataTable
                          value={tripExpense?.filter(obj => !obj.for.includes("salaryharv"))}
                          stripedRows
                          removableSort
                          rows={20}
                          columnResizeMode="expand"
                          resizableColumns
                          dataKey="id"
                          emptyMessage="No files found."
                        >
                          {
                            visiblePayColumns.map((col, index) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                body={(rowData) => (
                                  col.field === 'employee_id' ?
                                    employeeTemplate(rowData) :
                                      (
                                        col.field === 'for' ?
                                          ( rowData?.for && allOption["typeOfMoney"]?.find(i => i.id === rowData?.for)?.name ) : 
                                            rowData[col.field]
                                      )
                                  )
                                }
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  padding: '10px'
                                }}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Harvester</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddTripHarvesterModal sRC={setTModal} />
                        </div>
                      </div>
                      <hr />
                      <DataTable
                        value={tripHarvester}
                        stripedRows
                        removableSort
                        rows={20}
                        columnResizeMode="expand"
                        resizableColumns
                        dataKey="id"
                        emptyMessage="No files found."
                      >
                        {
                          visibleColumns.map((col, index) => (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.field === 'edit_view' ? '' : col.header}
                              body={(rowData) => (
                                col.field === 'employee_id' ?
                                  employeeTemplate(rowData) :
                                    (
                                      col.field === 'block_unit_id' ?
                                        unitTemplate(rowData) :
                                          (
                                            col.field === 'type' ?
                                              typeTemplate(rowData) :
                                                (
                                                  col.field === 'weight' ?
                                                    weightTemplate(rowData) :
                                                    (
                                                      col.field === 'edit_view' ?
                                                        editViewTemplate(rowData) :
                                                          rowData[col.field]
                                                    )
                                                )
                                          )
                                    )
                                )
                              }
                              style={{
                                display: col.visible ? 'table-cell' : 'none',
                                whiteSpace: 'normal',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '10px'
                              }}
                              showFilterMenu={false}
                            />
                          ))
                        }
                      </DataTable>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h3 className="h6">Harvesters <strong>Pay</strong></h3>
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <DataTable
                          value={tripExpense?.filter(obj => obj.for.includes("salaryharv"))}
                          stripedRows
                          removableSort
                          rows={20}
                          columnResizeMode="expand"
                          resizableColumns
                          dataKey="id"
                          emptyMessage="No files found."
                        >
                          {
                            visiblePayColumns.map((col, index) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                body={(rowData) => (
                                  col.field === 'employee_id' ?
                                    employeeTemplate(rowData) :
                                      (
                                        col.field === 'for' ?
                                          ( rowData?.for && allOption["typeOfMoney"]?.find(i => i.id === rowData?.for)?.name ) : 
                                            rowData[col.field]
                                      )
                                  )
                                }
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  padding: '10px'
                                }}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
      }
    </div>
  );
};

export default TripView;
