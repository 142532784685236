import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { UserAAContext, EmployeeContext, PositionContext, FieldContext, useToastContext } from "../../contexts";

const EditEmployeeSalaryModal = (props) => {
  
  const { showToast } = useToastContext();

  const employeeContext = useContext(EmployeeContext);
  const userAAContext = useContext(UserAAContext);
  const {userAbility} = userAAContext;

  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [employeeId, setEmployeeId] = useState("");
  const [typeName, setTypeName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [employeeSalaryId, setEmployeeSalaryId] = useState("");
  const [typeBase, setTypeBase] = useState(0);
  const [typePremium, setTypePremium] = useState(0);
  const [typeBenefit, setTypeBenefit] = useState(0);
  
  const {fields} = useContext(FieldContext);
  const {positions} = useContext(PositionContext);
  
  useEffect(() => {
    async function fetchData() {
      setEmployeeId(props && props.ee && props.ee.id ? props.ee.id : "");
      setTypeName(props && props.ee && props.ee.name ? props.ee.name : "");
      setSelectedPosition(props && props.ee && props.ee.position_id ? props.ee.position_id : "");
      setSelectedField(props && props.ee && props.ee.field_id ? props.ee.field_id : "");

      if (props && props.ee && props.ee.salary && JSON.parse(props.ee.salary)) {
        let sal = JSON.parse(props.ee.salary);
        setEmployeeSalaryId(sal[0].id);
        setTypeBase(sal[0].base);
        setTypePremium(sal[0].premium);
        setTypeBenefit(sal[0].benefit);
      }
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    employeeContext.refreshData();
    setDisableButton(false);
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeBase) {
      notif = "Salary Base must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {

      let formData = new FormData();
      formData.append("employee_id", parseInt(employeeId));
      formData.append("base", parseInt(typeBase));
      formData.append("premium", parseInt(typePremium));
      formData.append("benefit", parseInt(typeBenefit));

      const callAPI = await AxiosInstance.post(`/esalary${employeeSalaryId && "/" + employeeSalaryId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };


  return (
    <>
      { userAbility && userAbility.indexOf("addeditsalary") >= 0 && <span onClick={handleShow} className="link-isday ms-3">salary</span> }

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="field">Field</label>
            <select
              id="field"
              name="field"
              className="form-control"
              onChange={(_) => {
                setSelectedField(_.target.value);
              }}
              value={selectedField}
              disabled
            >
              <option value="">Please select</option>
              {
                fields?.length > 0 && fields?.map((e, i) => (
                  <option key={e.id} value={e.id}>{e.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              onChange={(_) => {
                setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeName}
              disabled
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="position">Position</label>
            <select
              id="position"
              name="position"
              className="form-control"
              onChange={(_) => {
                setSelectedPosition(_.target.value);
              }}
              value={selectedPosition}
              disabled
            >
              <option value="">Please select</option>
              {/* {
                positions?.length > 0 && positions?.filter(obj => obj.use_for !== "[]").map((p, i) => (
                  p.use_for && JSON.parse(p.use_for)?.length > 0 && Object.entries(JSON.parse(p.use_for)) && Object.entries(JSON.parse(p.use_for)).map(([key, value]) => (
                    value === "listofemployeeposition" && <option key={p.id} value={p.id}>{p.name}</option>
                  ))
                ))
              } */}
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="base">Base Salary</label>
            <input
              type="number"
              className={"form-control " + (typeBase < 0 ? "border-danger" : "") }
              id="base"
              name="base"
              placeholder="Base"
              onChange={(_) => {
                setTypeBase(_.target.value.replace(/[^0-9.-]/gi, ""));
              }}
              value={typeBase}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="premium">Premium</label>
            <input
              type="number"
              className={"form-control " + (typePremium < 0 ? "border-danger" : "") }
              id="premium"
              name="premium"
              placeholder="Premium"
              onChange={(_) => {
                setTypePremium(_.target.value.replace(/[^0-9.-]/gi, ""));
              }}
              value={typePremium}
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="benefit">Benefit</label>
            <input
              type="number"
              className={"form-control " + (typeBenefit < 0 ? "border-danger" : "") }
              id="benefit"
              name="benefit"
              placeholder="Benefit"
              onChange={(_) => {
                setTypeBenefit(_.target.value.replace(/[^0-9.-]/gi, ""));
              }}
              value={typeBenefit}
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditEmployeeSalaryModal;