import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, BlockContext, EmployeeContext, TripSingleContext, useToastContext } from "../../contexts";

const DeleteTripHarvesterModal = (props) => {
  
  const { showToast } = useToastContext();

  const tripSingleContext = useContext(TripSingleContext);
  const {tripSingle} = tripSingleContext;

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const blockContext = useContext(BlockContext);
  const {units} = blockContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [tripId, setTripId] = useState("");
  const [tripStage, setTripStage] = useState("");

  const [hid, sethid] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedUnitBlock, setSelectedUnitBlock] = useState("");
  const [selectedPlantType, setSelectedPlantType] = useState("");
  const [typeQty, setTypeQty] = useState(0);

  useEffect(() => {
    async function fetchData() {
      sethid(props && props.hs && props.hs.id ? props.hs.id : "");
      setSelectedEmployee(props && props.hs && props.hs.employee_id ? props.hs.employee_id : "");
      setSelectedUnitBlock(props && props.hs && props.hs.block_unit_id ? props.hs.block_unit_id : 0);
      setSelectedPlantType(props && props.hs && props.hs.type ? props.hs.type : "");
      setTypeQty(props && props.hs && props.hs.qty ? props.hs.qty : "");
    }
    fetchData();
  }, [props, props.hs]);

  useEffect(() => {
    async function fetchData() {
      setTripId(tripSingle && tripSingle.id ? tripSingle.id : "");
      setTripStage(tripSingle && tripSingle.stage ? tripSingle.stage : "");
    }
    fetchData();
  }, [tripSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!hid) {
      notif = "Please refresh the page.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("delh", "yes");
      formData.append("trip_id", tripId);

      const callAPI = await AxiosInstance.post(`/harvesters/${hid}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      {
        tripStage !== "approved" && tripStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            del
          </span>
        )
      }

      <Dialog header="Delete" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="name">Remove {employees && selectedEmployee && employees?.find(i => i.id === parseInt(selectedEmployee)) && employees?.find(i => i.id === parseInt(selectedEmployee))?.name} of {units && selectedUnitBlock && units?.find(i => i.id === parseInt(selectedUnitBlock)) && (units?.find(i => i.id === parseInt(selectedUnitBlock))?.name + ' ( ' + units?.find(i => i.id === parseInt(selectedUnitBlock)).medium + ' )')} with {typeQty} {allOption["typeOfHarvest"]?.find(i => i.id === selectedPlantType)?.name}?
          </label>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteTripHarvesterModal;
