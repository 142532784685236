import React, { createContext, useEffect, useRef, useState} from "react";
// import { useToastContext } from "../contexts";
import AxiosInstance from "../axios";

export const WorkContext = createContext();

export const WorkState = (props) => {
  
  // const { showToast } = useToastContext();
  const mounted = useRef(true);
  let conditionDefault = [];
      conditionDefault["page"] = 1;
      conditionDefault["limit"] = 10;
      conditionDefault["sort_by"] = "";
      conditionDefault["sort_order"] = "";
      conditionDefault["field_id"] = "";
      conditionDefault["work_type"] = "";
      conditionDefault["work_list"] = "";
  const [works, setWorks] = useState([]);
  const [conditions, setConditions] = useState(conditionDefault);

  async function refreshData() {

    // const { showToast } = useToastContext();
    let response = []; 
    let cdtns = "";

    cdtns = conditions && conditions["page"] !== undefined && conditions["page"] !== "" ? cdtns + "&page=" + conditions["page"] : cdtns;
    cdtns = conditions && conditions["limit"] !== undefined && conditions["limit"] !== "" ? cdtns + "&limit=" + conditions["limit"] : cdtns;
    cdtns = conditions && conditions["sort_by"] !== undefined && conditions["sort_by"] !== "" && conditions["sort_by"] !== null ? cdtns + "&sort_by=" + conditions["sort_by"] : cdtns;
    cdtns = conditions && conditions["sort_order"] !== undefined && conditions["sort_order"] !== "" ? (conditions["sort_order"] === 1 ? cdtns + `&sort_order=asc` : cdtns + `&sort_order=desc`) : cdtns;
    cdtns = conditions && conditions["field_id"] !== undefined && conditions["field_id"] !== "" ? cdtns + "&field_id=" + conditions["field_id"] : cdtns;
    cdtns = conditions && conditions["work_type"] !== undefined && conditions["work_type"] !== "" ? cdtns + "&work_type=" + conditions["work_type"] : cdtns;
    cdtns = cdtns.charAt(0) === "&" ? "?" + cdtns.substring(1) : "" ;

    const getStock = await AxiosInstance.get(
      `works` + cdtns,
      {
        cache: {
          ignoreCache: true,
        },
      }
    ).catch((error) => {
      // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
      // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      return false;
    });

    if (getStock) {
      response = getStock?.data;
    }

    setWorks(response);
  }

  useEffect(() => {
    async function fetchData() {
      if (!mounted.current) {
        refreshData();
      }
      mounted.current = false;
    }
    fetchData();
  }, [conditions]);

  const applyConditions = (cond = null) =>{

    let newData = false;
    for (const [key, value] of Object.entries(cond)) {
      if (conditions[key] === undefined || conditions[key] !== value) {
        newData = true;
      }
    }
    if (newData) {
      setConditions(cond);
    }
  }

  return (
    <WorkContext.Provider value={{works, conditions, applyConditions, refreshData}}>
      {props.children}
    </WorkContext.Provider>
  )
}