import React, { useContext, useEffect, useRef, useState } from "react";
import AxiosInstance from "../axios";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate, useParams } from "react-router-dom";
import { EditStockModal, EditStockPriceModal, AddStockImageModal, AddStockFieldModal, EditStockFieldModal } from "./modals";
import { UserAAContext, StockCategoryContext, StockSingleContext, StockShopContext, FieldContext, useToastContext, UserContext } from "../contexts";
import { baseURL, imageBaseURL } from "../axios";

import { Galleria } from "primereact/galleria";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const StockView = () => {
  
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const galleria = useRef(null);
  
  const stockCategoryContext = useContext(StockCategoryContext);
  const {stockCategory} = stockCategoryContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const userContext = useContext(UserContext);
  const {users} = userContext;
  const stockShopContext = useContext(StockShopContext);
  const {stockShop} = stockShopContext;
  const userAAContext = useContext(UserAAContext);
  const {userAbility} = userAAContext;

  const stockSingleContext = useContext(StockSingleContext);
  const {stockSingle} = stockSingleContext;
  
  let { stock_id } = useParams();
  const [sModal, setSModal] = useState(Math.round((new Date()).getTime()));
  
  const [stockId, setStockId] = useState("");
  const [stockImage, setStockImage] = useState([]);
  const [stockField, setStockField] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(stockCategory?.length > 0)) { stockCategoryContext.refreshData(); }
      if (!(fields?.length > 0)) { fieldContext.refreshData(); }
      if (!(users?.length > 0)) { userContext.refreshData(); }
      if (!(stockShop?.length > 0)) { stockShopContext.refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [stockCategory, fields, users, stockShop, stockCategoryContext, fieldContext, userContext, stockShopContext, refreshCall]);

  useEffect(() => {
    async function fetchData() {
      new Promise(function(resolve, reject) {
        setShowLoading(true);
        resolve(1);
      }).then(async function(result) {
        const getStockSingle = await AxiosInstance.get(
          `/stocks/${stock_id}`,
          {
            cache: {
              ignoreCache: true,
            },
          }
        ).catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
  
        if(getStockSingle){
          stockSingleContext.addData(getStockSingle?.data);
        }
        return 1;
      }).finally(function(result) {
        setShowLoading(false);
      });
    }
    fetchData();
  }, [sModal, stock_id]);

  useEffect(() => {
    async function fetchData() {
      if ( stockSingle && stockSingle.id ) {
        setStockId(stockSingle?.id);
        setStockImage(stockSingle?.image);
        setStockField(stockSingle?.field);
      }
    }
    fetchData();
  }, [stockSingle]);

  // Columns
  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'at', header: 'At', visible: true },
    { field: 'qty', header: 'Qty', visible: true },
    { field: 'edit_view', header: '', visible: true }
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'at', header: 'At', visible: true },
    { field: 'qty', header: 'Qty', visible: true },
    { field: 'edit_view', header: '', visible: true }
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'at', header: 'At', visible: true },
    { field: 'qty', header: 'Qty', visible: true },
    { field: 'edit_view', header: '', visible: true }
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  // Template
  const itemTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/stock-images/large/${item.image}`} alt="" style={{ width: "100%" }} />
  }

  const thumbnailTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/stock-images/thumb/${item.image}`} alt={item.alt} style={{ width: "70%" }} />
  }

  const fieldTemplate = (stock) => {
    return fields?.length > 0 && stock.field_id ? fields?.find(i => i.id === parseInt(stock.field_id))?.name : "";
  };

  const editViewTemplate = (stock) => {
    return <EditStockFieldModal stock={stock} sRC={setSModal} />;
  };

  return (
    <div className="container bg-white content-container">
      <div className="row text-left">
        <span className="link-isday" onClick={()=>navigate(-1)}>
          &lt; Back
        </span>
      </div>
      {
        showLoading && showLoading && showLoading
        ?
          <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
          </div>
        : 
          (
            <>
              <h2 className="h5 pt-3 pb-3 d-flex justify-content-center">Stock</h2>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Information</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <EditStockModal sRC={setSModal} />
                          <EditStockPriceModal sRC={setSModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>{stockSingle.name}</td>
                            </tr>
                            <tr>
                              <td>Keyword</td>
                              <td>{stockSingle.keyword}</td>
                            </tr>
                            <tr>
                              <td>Stock Category</td>
                              <td>{stockCategory?.length > 0 && stockSingle.stock_category_id && stockCategory?.find(i => i.id === parseInt(stockSingle.stock_category_id)) ? stockCategory?.find(i => i.id === parseInt(stockSingle.stock_category_id))?.name : ""}</td>
                            </tr>
                            { 
                              userAbility && userAbility.indexOf("addeditstock") >= 0 && (
                                <tr>
                                  <td>Price</td>
                                  <td>{stockSingle.price}</td>
                                </tr>
                              )
                            }
                            <tr>
                              <td>Shop</td>
                              <td>{stockShop?.length > 0 && stockSingle.stock_shop_id && stockShop?.find(i => i.id === parseInt(stockSingle.stock_shop_id)) ? stockShop?.find(i => i.id === parseInt(stockSingle.stock_shop_id))?.name : ""}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Image</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddStockImageModal sRC={setSModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="flex justify-content-center">
                        <Galleria ref={galleria} value={stockImage} numVisible={7} style={{ maxWidth: '850px' }}
                            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                            circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />

                        <div className="grid row mb-2" style={{ maxWidth: '400px' }}>
                          {
                            stockImage && stockImage.map((image, index) => {
                              let imgEl = <img src={`${imageBaseURL}uploads/stock-images/large/${image.image}`} onClick={() => {setActiveIndex(index); galleria.current.show()}} style={{ width: "100%" }} />
                              return (
                                <div className="col-lg-4 mt-3" key={index}>
                                    {imgEl}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Count</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddStockFieldModal sRC={setSModal} />
                        </div>
                      </div>
                      <hr />
                      <DataTable
                        value={stockField}
                        stripedRows
                        removableSort
                        rows={20}
                        columnResizeMode="expand"
                        resizableColumns
                        dataKey="id"
                        emptyMessage="No Stage found."
                      >
                        {
                          visibleColumns.map((col, index) => (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.header}
                              body={(rowData) => (
                                col.field === 'field_id' ?
                                  fieldTemplate(rowData) :
                                    (
                                      col.field === 'edit_view' ?
                                        editViewTemplate(rowData) :
                                          rowData[col.field]
                                    )
                                )
                              }
                              style={{
                                display: col.visible ? 'table-cell' : 'none',
                                whiteSpace: 'normal',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '10px'
                              }}
                              showFilterMenu={false}
                            />
                          ))
                        }
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
      }
    </div>
  );
};

export default StockView;