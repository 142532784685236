import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { EditBlockModal, ViewBlockModal } from "./modals";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { AllContext, BlockContext, FieldContext } from "../contexts";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';

const Blocks = () => {

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const blockContext = useContext(BlockContext);
  const {blocks} = blockContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  
  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(blocks?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [blocks, fields, allContext, blockContext, fieldContext, refreshCall]);

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await blockContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const [filterTypeNotes, setFilterTypeNotes] = useState("");
  const [filters, setFilters] = useState({
    field_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const notesFilter = (options) => {
    return (
      <InputText
        onInput={(e) => {
          const newFilters = { ...filters };
          newFilters.notes.value = e.target.value;
          setFilters(newFilters);
          setFilterTypeNotes(e.target.value);
        }}
        value={filterTypeNotes}
        placeholder="Search by Notes"
      />
    );
  }

  const fieldFilter = () => {
    return (
      <Dropdown
        value={fields?.find(i => i.id === parseInt(filters.field_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.field_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={fields}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const fieldBody = (b) => {
    return fields?.length > 0 && b.field_id ? fields?.find(i => i.id === parseInt(b.field_id))?.name : "";
  };

  const plantTypeBody = (b) => {
    return b.plant_type ? allOption["typeOfHarvest"]?.find(i => i.id === b.plant_type)?.name : "";
  };

  const landTypeBody = (b) => {
    return b.land_type ? allOption["typeOfLand"]?.find(i => i.id === b.land_type)?.name : "";
  };

  const unitBody = (b) => {
    let retVal = "";
    if (b.units) {
      let aUnits = b.units;
  
      if (aUnits?.length > 0) {
        aUnits.forEach((a, index) => {
          retVal = retVal + a["name"];
          if (index < aUnits.length - 1) {
            retVal = retVal + ', ';
          }
        });
      }
    }
    return retVal;
  };

  const editViewBody = (b) => {
    return <>
      <EditBlockModal be={b} /><ViewBlockModal eb={b.expenses ? b.expenses : []} />
    </>
    ;
  };

  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'field_id', header: 'Fields', visible: true },
    { field: 'big', header: 'Big', visible: true },
    { field: 'medium', header: 'Medium', visible: true },
    { field: 'sizes', header: 'Sizes', visible: true },
    { field: 'plant_type', header: 'Plant Type', visible: true },
    { field: 'land_type', header: 'Land Type', visible: true },
    { field: 'units', header: 'Units', visible: true },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const [defaultVisibleColumns, setDefaultVisibleColumns] = useState([
    { field: 'field_id', header: 'Fields', visible: true },
    { field: 'big', header: 'Big', visible: true },
    { field: 'medium', header: 'Medium', visible: true },
    { field: 'sizes', header: 'Sizes', visible: true },
    { field: 'plant_type', header: 'Plant Type', visible: true },
    { field: 'land_type', header: 'Land Type', visible: true },
    { field: 'units', header: 'Units', visible: true },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Blocks List</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="visibleColumn">Column</label>
                      <MultiSelect
                        id="visibleColumn"
                        value={visibleColumns.filter((col) => col.visible).map((col) => col.field)}
                        options={visibleColumns.map((col) => ({ label: col.header, value: col.field }))}
                        onChange={(e) => toggleColumnsVisibility(e.value)}
                        placeholder="Select Columns"
                      />
                    </div>
                  </div>
                </div>
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  :
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                setVisibleColumns(defaultVisibleColumns);
                              }}
                            >
                              reset column
                            </span>
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                const newFilters = { ...filters };
                                newFilters.field_id.value = null;
                                setFilters(newFilters);
                                setFilterTypeNotes("");
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            {/* <AddBlockModal /> */}
                          </div>
                        </div>
                        <hr />
                        <DataTable
                          value={blocks}
                          stripedRows
                          removableSort
                          paginator
                          rows={10}
                          columnResizeMode="expand"
                          resizableColumns
                          filters={filters}
                          filterDisplay="row"
                          filterClearIcon={null}
                          dataKey="id"
                          emptyMessage="No block found."
                        >
                          {
                            visibleColumns.map((col) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                filter={col.field === 'name' || col.field === 'field_id' || col.field === 'position_id'}
                                filterElement={col.field === 'notes' ? notesFilter : undefined}
                                body={(rowData) => (col.field === 'field_id' ? fieldBody(rowData) : (col.field === 'plant_type' ? plantTypeBody(rowData) : (col.field === 'land_type' ? landTypeBody(rowData) : (col.field === 'units' ? unitBody(rowData) : (col.field === 'edit_view' ? editViewBody(rowData) : rowData[col.field])))))}
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: col.field === 'units' ? 'normal' : 'nowrap',
                                  maxWidth: col.field === 'units' ? '12rem' : 'none',
                                }}
                                sortable={col.field !== 'units' && col.field !== 'edit_view'}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blocks;
