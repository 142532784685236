import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { AddStockModal } from "./modals";
import Header from "./layout/Header";
import { StockShopContext, StockCategoryContext, StockContext, MachineContext, UserAAContext } from "../contexts";
import { baseURL, imageBaseURL } from "../axios";

// https://primereact.org/datatable/
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';

const Stocks = () => {

  const navigate = useNavigate();

  const stockContext = useContext(StockContext);
  const {stocks} = stockContext;

  const stockCategoryContext = useContext(StockCategoryContext);
  const {stockCategory} = stockCategoryContext;
  const stockShopContext = useContext(StockShopContext);
  const {stockShop} = stockShopContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice, userField} = userAAContext;

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(stockCategory?.length > 0) && refreshCall) { stockCategoryContext.refreshData(); }
      if (!(stockShop?.length > 0) && refreshCall) { stockShopContext.refreshData(); }
      if (!(machines?.length > 0) && refreshCall) { machineContext.refreshData(); }
      if (!(stocks?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [stockCategory, stocks, refreshCall]);

  useEffect(() => {
    async function fetchData() {
      userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
    }
    fetchData();
  }, [userDevice]);

  // Columns
  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'image', header: 'Image', visible: true },
    { field: 'id', header: 'ID', visible: true },
    { field: 'name', header: 'Name', visible: true },
    { field: 'keyword', header: 'Keyword', visible: true },
    { field: 'stock_category_id', header: 'Category', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'image', header: 'Image', visible: true },
    { field: 'id', header: 'ID', visible: true },
    { field: 'name', header: 'Name', visible: true },
    { field: 'keyword', header: 'Keyword', visible: true },
    { field: 'stock_category_id', header: 'Category', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'image', header: 'Image', visible: true },
    { field: 'id', header: 'ID', visible: true },
    { field: 'name', header: 'Name', visible: true },
    { field: 'keyword', header: 'Keyword', visible: true },
    { field: 'stock_category_id', header: 'Category', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  // Filters
  const [filters, setFilters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    keyword: { value: null, matchMode: FilterMatchMode.CONTAINS },
    stock_category_id: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  const nameFilter = (options) => {
    return (
      <InputText
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.name.value = e.target.value;
          setFilters(newFilters);
        }}
        value={filters.name.value ? filters.name.value : ""}
        placeholder="Search by Notes"
      />
    );
  }

  const keywordFilter = (options) => {
    return (
      <InputText
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.keyword.value = e.target.value;
          setFilters(newFilters);
        }}
        value={filters.keyword.value ? filters.keyword.value : ""}
        placeholder="Search by Notes"
      />
    );
  }

  const stockCategoryFilter = (options) => {
    return (
      <Dropdown
        value={stockCategory?.find(i => i.id === parseInt(filters.stock_category_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.stock_category_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={stockCategory}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Stock Category"
        className="w-full md:w-14rem"
      />
    );
  }

  // Pagination

  // Data
  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await stockContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  // Template
  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const stockCategoryTemplate = (stock) => {
    return stockCategory?.length > 0 && stock.stock_category_id ? stockCategory?.find(i => i.id === parseInt(stock.stock_category_id))?.name : "";
  };

  const imageTemplate = (stock) => {
    return stock && stock?.image[0] && stock?.image[0]?.image ? <span className="stock-images"><img alt="" src={`${imageBaseURL}uploads/stock-images/thumb/${stock?.image[0]?.image}`} /></span> : "";
  };

  const editViewTemplate = (stock) => {
    return <span onClick={() => {navigate(`/stock/${stock.id}`);}} className="link-isday ms-3">view</span>;
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Stocks</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="visibleColumn">Column</label>
                      <MultiSelect
                        id="visibleColumn"
                        className="w-100"
                        value={visibleColumns.filter((col) => col.visible).map((col) => col.field)}
                        options={visibleColumns.map((col) => ({ label: col.header, value: col.field }))}
                        onChange={(e) => toggleColumnsVisibility(e.value)}
                        placeholder="Select Columns"
                      />
                    </div>
                  </div>
                </div>
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
                              }}
                            >
                              reset column
                            </span>
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                const newFilters = { ...filters };
                                newFilters.name.value = null;
                                newFilters.keyword.value = null;
                                newFilters.stock_category_id.value = null;
                                setFilters(newFilters);
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddStockModal />
                          </div>
                        </div>
                        <hr />
                        <DataTable
                          value={stocks}
                          stripedRows
                          removableSort
                          paginator
                          rows={10}
                          columnResizeMode="expand"
                          resizableColumns
                          filters={filters}
                          filterDisplay="row"
                          filterClearIcon={null}
                          dataKey="id"
                          emptyMessage="No stocks found."
                          loading={showLoading} 
                        >
                          {
                            visibleColumns.map((col) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                filter={col.field === 'name' || col.field === 'keyword' || col.field === 'stock_category_id'}
                                filterElement={(rowData) => (
                                  col.field === 'name' ? 
                                    nameFilter(rowData) : 
                                      (
                                        col.field === 'keyword' ?
                                          keywordFilter(rowData) : 
                                            (
                                              col.field === 'stock_category_id' ?
                                                stockCategoryFilter(rowData) :
                                                  undefined
                                            )
                                      )
                                  )
                                }
                                body={(rowData) => ( 
                                    col.field === 'image' ?
                                      imageTemplate(rowData) :
                                        (
                                          col.field === 'stock_category_id' ?
                                            stockCategoryTemplate(rowData) :
                                              (
                                                col.field === 'edit_view' ?
                                                  editViewTemplate(rowData) :
                                                    rowData[col.field]
                                              )
                                        )
                                  )
                                }
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                                sortable={col.field !== 'edit_view'}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stocks;
