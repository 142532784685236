import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, WorkContext, FieldContext, UserAAContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const AddWorkModal = () => {
  
  const { showToast } = useToastContext();

  const workContext = useContext(WorkContext);

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const userAAContext = useContext(UserAAContext);
  const {userField} = userAAContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [selectedField, setSelectedField] = useState("");
  const [selectedWorkDate, setSelectedWorkDate] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [selectedMinDate, setSelectedMinDate] = useState("");
  const [selectedMaxDate, setSelectedMaxDate] = useState("");

  useEffect(() => {
    async function fetchData() {
      setDate();
    }
    fetchData();
  }, []);

  const setDate = () => {
    let workDate = new Date();
    let minTime = new Date(workDate);
    let maxTime = new Date(workDate);

    if (minTime) {
      minTime.setDate(workDate.getDate() - 67);
      minTime.setHours(0, 0, 0, 0);
    }
    
    if (maxTime) {
      maxTime.setHours(23, 59, 59, 0);
    }

    setSelectedWorkDate( workDate );
    setSelectedMinDate( minTime );
    setSelectedMaxDate( maxTime );
  }

  function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedField) {
      notif = "Field must not be empty.";
    } else if (!selectedWorkDate) {
      notif = "Date must not be empty.";
    } else if (!selectedWorkType) {
      notif = "Type must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){

      let formData = new FormData();
      formData.append("field_id", selectedField);
      formData.append("work_date", formatDateToISO(selectedWorkDate));
      formData.append("work_type", selectedWorkType);

      const addWork = await AxiosInstance.post("/works", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addWork) {
        workContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    setDisableButton(false);
    setSelectedField("");
    setDate();
    setSelectedWorkType("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span className="link-isday" onClick={handleShow}>
        add new
      </span>

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="field">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setSelectedField(_.target.value);
            }}
            value={selectedField}
            required
          >
            <option value="">Please select</option>
            {
              fields?.length > 0 && (userField
                ? fields?.filter(obj => obj.id === parseInt(userField))?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                : fields?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
              )
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="inputDate">Date</label>
          <Calendar
            id="inputDate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedWorkDate}
            onChange={(e) => setSelectedWorkDate(e.value)}
            minDate={selectedMinDate}
            maxDate={selectedMaxDate}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="type_of_work">Work Type</label>
          <select
            id="type_of_work"
            name="type_of_work"
            className="form-control"
            onChange={(_) => {
              setSelectedWorkType(_.target.value);
            }}
            value={selectedWorkType}
            required
          >
            <option value="">Please select</option>
            {
              allOption["typeOfWork"]?.map((p, i) => (
                <option key={p.id} value={p.id}>{p.name}</option>
              ))
            }
          </select>
        </div>
      </Dialog>
    </>
  );
};

export default AddWorkModal;
