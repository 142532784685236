import React, { useContext, useEffect, useRef, useState } from "react";
import AxiosInstance from "../axios";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate, useParams } from "react-router-dom";
import { EditLoanModal, AddLoanListModal, EditLoanListModal, DeleteLoanListModal, VerifyLoanModal } from "./modals";
import { EmployeeContext, FieldContext, UserContext, useToastContext } from "../contexts";
import { LoanSingleContext } from "../contexts";
import { imageBaseURL } from "../axios";

// https://primereact.org/datatable/
import { Galleria } from "primereact/galleria";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const LoanView = () => {

  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const galleria = useRef(null);

  const loanSingleContext = useContext(LoanSingleContext);
  const {loanSingle} = loanSingleContext;

  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const userContext = useContext(UserContext);
  const {users} = userContext;

  let { loan_id } = useParams();
  const [lModal, setLModal] = useState(Math.round((new Date()).getTime()));

  const [loanImage, setLoanImage] = useState([]);
  const [loanList, setLoanList] = useState([]);
  const [loanStage, setLoanStage] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(employees?.length > 0) && refreshCall) { employeeContext.refreshData(); }
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(users?.length > 0) && refreshCall) { userContext.refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [employees, fields, users, employeeContext, fieldContext, userContext, refreshCall]);

  useEffect(() => {
    async function fetchData() {
      if ( loanSingle && loanSingle?.loan_list ) {
        setLoanList(loanSingle?.loan_list);
        setLoanImage(loanSingle?.loan_image);
        setLoanStage(loanSingle?.history_stage);
      }
    }
    fetchData();
  }, [loanSingle]);

  useEffect(() => {
    async function fetchData() {
      new Promise(function(resolve, reject) {
        setShowLoading(true);
        resolve(1);
      }).then(async function(result) {
        const getLoanSingle = await AxiosInstance.get(
          `/loans/${loan_id}`,
          {
            cache: {
              ignoreCache: true,
            },
          }
        ).catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
  
        if(getLoanSingle){
          loanSingleContext.addData(getLoanSingle?.data);
        }
        return 1;
      }).finally(function(result) {
        setShowLoading(false);
      });
    }
    fetchData();
  }, [lModal]);

  const [visibleLoanListColumns, setVisibleLoanListColumns] = useState([
    { field: 'employee_id', header: 'Employee', visible: true },
    { field: 'amount', header: 'Amount', visible: true },
    { field: 'paid_date', header: 'Paid Date', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: true }
  ]);

  const [visibleStageColumns, setVisibleStageColumns] = useState([
    { field: 'created_at', header: 'Date', visible: true },
    { field: 'changed_to', header: 'Action', visible: true },
    { field: 'changed_by', header: 'By', visible: true },
  ]);

  // Template
  const itemTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/loan-images/large/${loan_id}/${item.image}`} alt="" style={{ width: "100%" }} />
  }

  const thumbnailTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/loan-images/thumb/${loan_id}/${item.image}`} alt={item.alt} style={{ width: "70%" }} />
  }

  const actionTemplate = (stage) => {
    return (
      stage && stage?.changed_to ? (
        stage?.changed_to === "approved" || stage?.changed_to === "approvedbysystem" ?
          "Approve" : (
            stage?.changed_to === "verified" || stage?.changed_to === "verifiedbysystem" ?
              "Verify" : 
                "Submit"
          )
      ) : "");
  };

  const byTemplate = (stage) => {
    return users?.length > 0 && stage.changed_by && users?.find(i => i.id === parseInt(stage.changed_by)) ? users?.find(i => i.id === parseInt(stage.changed_by))?.name : "";
  };

  const dtTemplate = (dt) => {
    const date = new Date(dt);
    const options = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const employeeTemplate = (loanlist) => {
    return employees?.length > 0 && loanlist.employee_id ? employees?.find(i => i.id === parseInt(loanlist.employee_id))?.name : "";
  };

  const editViewTemplate = (loanlist) => {
    return (
      <>
        <EditLoanListModal ll={loanlist} sRC={setLModal} />
        <DeleteLoanListModal ll={loanlist} sRC={setLModal} />
      </>
    );
  };

  return (
    <div className="container bg-white content-container">
      <div className="row text-left">
        <span className="link-isday" onClick={()=>navigate(-1)}>
          &lt; Back
        </span>
      </div>
      {
        showLoading
        ?
          <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
          </div>
        : 
          (
            <>
              <h2 className="h5 pt-3 pb-3 d-flex justify-content-center">Loan</h2>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6 mb-0">INFORMATION</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <EditLoanModal sRC={setLModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>Date</td>
                              <td>{loanSingle && loanSingle.loan_date}</td>
                            </tr>
                            <tr>
                              <td>Field</td>
                              <td>{loanSingle && fields?.length > 0 && loanSingle.field_id && fields?.find(i => i.id === parseInt(loanSingle.field_id)) && fields?.find(i => i.id === parseInt(loanSingle.field_id))?.name}</td>
                            </tr>
                            <tr>
                              <td>Notes</td>
                              <td>{loanSingle && loanSingle.notes }</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 container-stage d-flex align-items-center justify-content-center">
                        {
                          loanSingle.stage === "approved" || loanSingle.stage === "approvedbysystem"
                            ? <i className="pi pi-check" style={{ fontSize: "7rem", color: "green" }} />
                              : loanSingle.stage === "verified" || loanSingle.stage === "verifiedbysystem"
                                ? <i className="pi pi-eye" style={{ fontSize: "7rem", color: "orange" }} />
                                  : loanSingle.stage === "submitted"
                                    ? <i className="pi pi-eye-slash" style={{ fontSize: "7rem", color: "red" }} />
                                      : <i className="pi pi-clock" style={{ fontSize: "7rem", color: "grey" }} />
                        }
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row mb-2">
                        <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                          <h3 className="h6">Stage</h3>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-7 col-7 d-flex justify-content-end small">
                          <span className="link-secondary"><VerifyLoanModal sRC={setLModal} /></span>
                        </div>
                        <hr />
                        <DataTable
                          value={loanStage}
                          stripedRows
                          removableSort
                          rows={20}
                          columnResizeMode="expand"
                          resizableColumns
                          dataKey="id"
                          emptyMessage="No Stage found."
                        >
                          {
                            visibleStageColumns.map((col, index) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                body={(rowData) => (
                                  col.field === 'created_at' ?
                                    dtTemplate(rowData?.created_at) :
                                      (
                                        col.field === 'changed_to' ?
                                          actionTemplate(rowData) :
                                            (
                                              col.field === 'changed_by' ?
                                                byTemplate(rowData) :
                                                  rowData[col.field]
                                            )
                                      )
                                  )
                                }
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  padding: '10px'
                                }}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Loan List</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddLoanListModal sRC={setLModal} />
                        </div>
                      </div>
                      <hr />
                      <DataTable
                        value={loanList}
                        stripedRows
                        removableSort
                        rows={20}
                        columnResizeMode="expand"
                        resizableColumns
                        dataKey="id"
                        emptyMessage="No files found."
                      >
                        {
                          visibleLoanListColumns.map((col, index) => (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.field === 'edit_view' ? '' : col.header}
                              body={(rowData) => (
                                col.field === 'employee_id' ?
                                  employeeTemplate(rowData) :
                                  (
                                    col.field === 'edit_view' ?
                                      editViewTemplate(rowData) :
                                        rowData[col.field]
                                  )
                                )
                              }
                              style={{
                                display: col.visible ? 'table-cell' : 'none',
                                whiteSpace: 'normal',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '10px'
                              }}
                              showFilterMenu={false}
                            />
                          ))
                        }
                      </DataTable>
                    </div>
                  </div>
                  <div className="card mb-8">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Images</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          {/* <AddLoanImageModal sRC={setWModal} /> */}
                        </div>
                      </div>
                      <hr />
                      <div className="flex justify-content-center">
                        <Galleria ref={galleria} value={loanImage} numVisible={7} style={{ maxWidth: '850px' }}
                            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                            circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />

                        <div className="grid row mb-2" style={{ maxWidth: '400px' }}>
                          {
                            loanImage && loanImage.map((image, index) => {
                              let imgEl = <img src={`${imageBaseURL}uploads/loan-images/large/${loan_id}/${image.image}`} onClick={() => {setActiveIndex(index); galleria.current.show()}} style={{ width: "100%" }} />
                              return (
                                <div className="col-lg-4 mt-3" key={index}>
                                    {imgEl}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
      }
    </div>
  );
};

export default LoanView;
