import React, { createContext } from "react";

// Create a context
export const AllContext = createContext();

// Context provider component
export const AllState = (props) => {

  const allOption = {
    "typeOfStatus": [
        { "id": "active", "name": "Active" },
        { "id": "inactive", "name": "Inactive / Archive" }
    ],
    2: [
        { "id": "edmode", "name": "Edit Mode" },
        { "id": "trangress", "name": "In Progress / In Transit" },
        { "id": "completed", "name": "Completed" }
    ],
    3: [
        { "id": "editmode", "name": "Edit Mode" },
        { "id": "submitted", "name": "Submitted" },
        { "id": "verified", "name": "Verified" },
        { "id": "approved", "name": "Approved" },
        { "id": "approvedbysystem", "name": "Approved By System" }
    ],
    4: [
        { "id": "viewforall", "name": "View For all" },
        { "id": "viewrestricted", "name": "Restricted" }
    ],
    "typeOfLand": [
        { "id": "tanahputih", "name": "Tanah Putih" },
        { "id": "gambut", "name": "Gambut" }
    ],
    "typeOfUserAbility": [ // 6
        { "id": "addeditstock", "name": "Add / edit Stock" },
        { "id": "addeditsalary", "name": "Add / edit Salary" },
        { "id": "stageapprove", "name": "Stage Approve" },
        { "id": "stageverify", "name": "Stage Verify" },
        { "id": "datazero", "name": "Zero Visibility" },
        { "id": "dataninetydays", "name": "90 Days Visibility" },
        { "id": "dataall", "name": "Unlimited Visibility" }
    ],
    "typeOfUserAccess": [
        { "id": "users", "name": "Users" },
        { "id": "blocks", "name": "Blocks" },
        { "id": "employees", "name": "Employees" },
        { "id": "positions", "name": "Positions" },
        { "id": "machines", "name": "Machines" },
        { "id": "stocks", "name": "Stocks" },
        { "id": "works", "name": "Works" },
        { "id": "trips", "name": "Trips" },
        { "id": "history", "name": "History" },
        { "id": "pays", "name": "Pays" },
        { "id": "files", "name": "Files" }
    ],
    "typeOfStockPetrolFor": [ // 8
        { "id": "employees", "name": "Employees" },
        { "id": "machines", "name": "Machines" }
    ],
    "typeOfStockMovementFor": [ // 8
        { "id": "stockmovementforpetrol", "name": "Petrol" },
        { "id": "stockmovementfortransfer", "name": "Transfer" },
        { "id": "stockmovementforrepair", "name": "Send for Repair" }
    ],
    "typeOfWork": [
        { "id": "workforbengkel", "name": "Bengkel" },
        { "id": "workforborongan", "name": "Borongan" },
        { "id": "workforharian", "name": "Harian" },
        { "id": "workforoperator", "name": "Operator" },
        { "id": "workforperawatan", "name": "Perawatan" }
    ],
    "typeOfStockCategory": [ // 10
        { "id": "stockcategorypetrol", "name": "Petrol" },
        { "id": "stockcategoryracun", "name": "Racun" },
        { "id": "stockcategorypupuk", "name": "Pupuk" },
        { "id": "stockcategorytruck", "name": "Truck" },
        { "id": "stockcategorymotor", "name": "Motor" },
        { "id": "stockcategoryalatberat", "name": "Alat Berat" },
        { "id": "stockcategorymesinlain", "name": "Mesin Lain" },
        { "id": "stockcategoryothers", "name": "Others" }
    ],
    "typeOfMoney": [
        { "id": "salarytypebase", "name": "Base" },
        { "id": "salarytypepremium", "name": "Premium" },
        { "id": "salarytypebenefit", "name": "Benefit" },
        { "id": "salarytypecontract", "name": "Contract" },
        { "id": "salarytypebenefitattendance", "name": "Benefit Attendance" },
        { "id": "salaryharvtm", "name": "Harvest TM" },
        { "id": "salaryharvtmk", "name": "Harvest TMK" },
        { "id": "salaryharvtbm", "name": "Harvest TBM" },
        { "id": "salaryharvbr", "name": "Harvest BR" },
        { "id": "salaryharvsort", "name": "Harvest SORT" },
        { "id": "salaryharvraw", "name": "Harvest RAW" },
        { "id": "salaryharvxtr", "name": "Harvest Extra" },
        { "id": "loancash", "name": "Loan Cash" },
        { "id": "loanpetrol", "name": "Loan Petrol" },
        { "id": "loansalary", "name": "Loan Salary" }
    ],
    "typeOfHarvest": [ // 12
        { "id": "TM", "name": "TM" },
        { "id": "TMK", "name": "TMK" },
        { "id": "TBM", "name": "TBM" },
        { "id": "BR", "name": "BR" },
        { "id": "SORT", "name": "SORT" },
        { "id": "RAW", "name": "RAW" }
    ],
    "typeOfHarvestObstacle": [
        { "id": "BJ", "name": "BJ" },
        { "id": "LG", "name": "LG" },
        { "id": "BJLG", "name": "BJLG" }
    ],
    15: [ 
        { "id": "yes", "name": "Yes" }
    ],
    "typeOfTripDestination": [
        { "id": "destinationkebunsendiri", "name": "Kebun Sendiri" },
        { "id": "destinationpulogambut", "name": "Pulo Gambut" },
        { "id": "destinationtanjungpasir", "name": "Tanjung Pasir" },
        { "id": "destinationgrahadura", "name": "Grahadura" },
        { "id": "destinationperahu", "name": "Perahu" }
    ],
    "typeOfHistory": [
        { "id": "historyblock", "name": "Block" },
        { "id": "historyemployee", "name": "Employee" },
        { "id": "historyfile", "name": "File" },
        { "id": "historymachine", "name": "Machine" },
        { "id": "historystock", "name": "Stock" },
        { "id": "historystockmovement", "name": "Stock Movement" },
        { "id": "historytrip", "name": "Trip" },
        { "id": "historywork", "name": "Work" },
        { "id": "historyloan", "name": "Loan" }
    ],
    "typeOfFile": [
        { "id": "fileabsensi", "name": "Laporan Absensi " },
        { "id": "fileperawatan", "name": "Laporan Perawatan" },
        { "id": "filebuah", "name": "Laporan Buah" },
        { "id": "filebarang", "name": "Laporan Barang" },
        { "id": "filepalang", "name": "Laporan Palang" },
        { "id": "fileuang", "name": "Laporan Uang" },
        { "id": "filelain", "name": "Laporan Lain" },
        { "id": "filerefisi", "name": "Laporan Refisi" }
    ],
    19: [
        { "id": "moneyoperations", "name": "Operations" },
        { "id": "moneyemployees", "name": "Employees" },
        { "id": "moneytrips", "name": "Trips" },
        { "id": "moneystocks", "name": "Stocks" }
    ]
  };

  const allFormula = {
    "perawatan": [
        { "id": "pupukquarter", "name": "Pupuk 1/4 KG", "formulaone": "0.69", "formulatwo": "10000.00", "notes": "hectare (per) amount-sak (per) price" },
        { "id": "pupukhalf", "name": "Pupuk 1/2 KG", "formulaone": "1.38", "formulatwo": "7000.00", "notes": "hectare (per) amount-sak (per) price" },
        { "id": "pupukone", "name": "Pupuk 1 KG", "formulaone": "2.76", "formulatwo": "5500.00", "notes": "hectare (per) amount-sak (per) price" },
        { "id": "pupukonehalf", "name": "Pupuk 1.5 KG", "formulaone": "4.14", "formulatwo": "4825.00", "notes": "hectare (per) amount-sak (per) price" },
        { "id": "pupuktwo", "name": "Pupuk 2 KG", "formulaone": "5.52", "formulatwo": "4375.00", "notes": "hectare (per) amount-sak (per) price" },
        { "id": "pupukdolop", "name": "Pupuk Dolop", "formulaone": "12.42", "formulatwo": "0.00", "notes": "hectare (per) amount-sak (per) price" },
        { "id": "pupukdolog", "name": "Pupuk Dolog", "formulaone": "13.80", "formulatwo": "0.00", "notes": "hectare (per) amount-sak (per) price" },
        { "id": "pupukabutanahputih", "name": "Pupuk ABUP", "formulaone": "27.60", "formulatwo": "0.00", "notes": "hectare (per) amount-sak (per) price" },
        { "id": "pupukabugambut", "name": "Pupuk ABUG", "formulaone": "41.40", "formulatwo": "0.00", "notes": "hectare (per) amount-sak (per) price" },
        { "id": "racunpiringan", "name": "Racun Piringan", "formulaone": "0.45", "formulatwo": "18000.00", "notes": "hectare (per) amount-litre (per) price" },
        { "id": "racunjalurparet", "name": "Racun Jalur Paret", "formulaone": "0.63", "formulatwo": "25000.00", "notes": "hectare (per) amount-litre (per) price" },
        { "id": "racunjalurtotal", "name": "Racun Jalur Total", "formulaone": "1.25", "formulatwo": "50000.00", "notes": "hectare (per) amount-litre (per) price" },
        { "id": "racuntotal", "name": "Racun Total", "formulaone": "1.75", "formulatwo": "75000.00", "notes": "hectare (per) amount-litre (per) price" },
        { "id": "racunmarshal", "name": "Racun Marshal", "formulaone": "1.38", "formulatwo": "10000.00", "notes": "hectare (per) amount-litre (per) price" },
        { "id": "dongkelgambut", "name": "Dongkel Gambut", "formulaone": "0.78", "formulatwo": "55000.00", "notes": "hectare (per) amount-litre (per) price" },
        { "id": "dongkeltanahputih", "name": "Dongkel Tanah Putih", "formulaone": "0.78", "formulatwo": "70000.00", "notes": "hectare (per) amount-litre (per) price" }
    ],
    "destinationkebunsendiri": [
        { "id": "ksbase", "name": "Base KS", "formulaone": "8.5" },
        { "id": "ksmuatbongkar", "name": "Muat Bongkar KS", "formulatwo": "7.5" },
        { "id": "kssupirtemp", "name": "Supir Sementara KS", "formulatwo": "3" },
        { "id": "kspremisupir", "name": "Premi Supir KS" }
    ],
    "destinationpulogambut": [
        { "id": "pgbase", "name": "Base PG", "formulaone": "8.5" },
        { "id": "pgmuatbongkar", "name": "Muat Bongkar PG", "formulatwo": "10" },
        { "id": "pgsupirtemp", "name": "Supir Sementara PG", "formulatwo": "3" },
        { "id": "pgpremisupir", "name": "Premi Supir PG", "formulatwo": "5" }
    ],
    "destinationtanjungpasir": [
        { "id": "tpbase", "name": "Base TP", "formulaone": "8.5" },
        { "id": "tpmuatbongkar", "name": "Muat Bongkar TP", "formulatwo": "13" },
        { "id": "tpsupirtemp", "name": "Supir Sementara TP", "formulatwo": "3" },
        { "id": "tppremisupir", "name": "Premi Supir TP", "formulatwo": "10" }
    ],
    "destinationgrahadura": [
        { "id": "gdbase", "name": "Base GD", "formulaone": "8.5" },
        { "id": "gdmuatbongkar", "name": "Muat Bongkar GD", "formulatwo": "3" },
        { "id": "gdsupirtemp", "name": "Supir Sementara GD", "formulatwo": "3" },
        { "id": "gdpremisupirharian", "name": "Premi Supir Harian GD", "formulatwo": "7" },
        { "id": "gdpremisupirtemp", "name": "Premi Supir Sementara GD", "formulatwo": "10" }
    ],
    "destinationperahu": [
        { "id": "phbase", "name": "Base PH", "formulaone": "8.5" },
        { "id": "phmuatbongkar", "name": "Muat Bongkar PH", "formulatwo": "7.5" },
        { "id": "phsupirtemp", "name": "Supir Sementara PH", "formulatwo": "3" }
    ],
    "targetgaji": [
        { "id": "targetgajisupir", "name": "Target Gaji Supir", "formulaone": "125000", "notes": "Target Gaji naik 5000 per tahun" },
        { "id": "targetgajipemanen", "name": "Target Gaji Pemanen", "formulaone": "150000", "notes": "Target Gaji naik 5000 per tahun" },
        { "id": "targetgajiperawatan", "name": "Target Gaji Perawatan", "formulaone": "85000", "notes": "Target Gaji naik 5000 per tahun" }
    ]
  }

  return (
    <AllContext.Provider value={{allOption, allFormula}}>
      {props.children}
    </AllContext.Provider>
  )

};