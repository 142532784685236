import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { UserAAContext, TripContext, TripSingleContext, useToastContext } from "../../contexts";

const VerifyTripModal = (props) => {
  
  const { showToast } = useToastContext();

  const tripContext = useContext(TripContext);
  const tripSingleContext = useContext(TripSingleContext);
  const {tripSingle} = tripSingleContext;

  const userAAContext = useContext(UserAAContext);
  const {userAbility} = userAAContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [tripId, setTripId] = useState("");
  const [tripStage, setTripStage] = useState("");
  const [action, setAction] = useState("");

  useEffect(() => {
    async function fetchData() {
      if ( tripSingle && tripSingle.id ) {
        setTripId(tripSingle.id);
        setTripStage(tripSingle.stage);
      }
    }
    fetchData();
  }, [tripSingle]);

  useEffect(() => {
    function fetchData() {
      let retVal = "";

      switch (tripStage) {
        case "approved":
          if (userAbility.indexOf("stageapprove") >= 0) {
            retVal = "withdraw approval";
          }
          break;
        case "verified":
          if (userAbility.indexOf("stageapprove") >= 0) {
            retVal = "approve";
          } else if (userAbility.indexOf("stageverify") >= 0) {
            retVal = "verified";
          }
          break;
        case "submitted":
          if (userAbility.indexOf("stageapprove") >= 0 || userAbility.indexOf("stageverify") >= 0) {
            retVal = "verified";
          }
          break;
        case "editmode":
          retVal = "submit";
          break;
        default:
          // do nothing
          break;
      }
      
      setAction(retVal);
    }
    fetchData();
  }, [tripStage, userAbility]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!tripId) {
      notif = "Please refresh the page.";
    } else if (!tripStage) {
      notif = "Stage must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      const callAPI = await AxiosInstance.post(`/trips/verify/${tripId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        props.sRC(Math.round((new Date()).getTime()));
        tripContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Yes
      </Button>
      <Button variant="primary" disabled={disableButton} onClick={() => handleClose()}>
        No
      </Button>
    </div>
  );

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        {action}
      </span>

      <Dialog header="Delete" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="name">Confirm to {action}?</label>
        </div>
      </Dialog>
    </>
  );
};

export default VerifyTripModal;
