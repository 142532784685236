import React, { createContext, useEffect, useRef, useState} from "react";
import AxiosInstance from "../axios";

export const PaybakoContext = createContext();

export const PaybakoState = (props) => {
  
  const mounted = useRef(true);
  let conditionDefault = [];
      conditionDefault["allowance"] = "";

  const [paybako, setPaybako] = useState([]);
  const [conditions, setConditions] = useState(conditionDefault);

  async function refreshData() {
    let response = []; 
    let cdtns = "";

    cdtns = conditions && conditions["allowance"] !== undefined && conditions["allowance"] !== "" ? cdtns + "&allowance=" + conditions["allowance"] : cdtns;
    cdtns = conditions && conditions["minoil"] !== undefined && conditions["minoil"] !== "" ? cdtns + "&minoil=" + conditions["minoil"] : cdtns;
    cdtns = conditions && conditions["maxoil"] !== undefined && conditions["maxoil"] !== "" ? cdtns + "&maxoil=" + conditions["maxoil"] : cdtns;
    cdtns = conditions && conditions["minsugar"] !== undefined && conditions["minsugar"] !== "" ? cdtns + "&minsugar=" + conditions["minsugar"] : cdtns;
    cdtns = conditions && conditions["maxsugar"] !== undefined && conditions["maxsugar"] !== "" ? cdtns + "&maxsugar=" + conditions["maxsugar"] : cdtns;
    cdtns = conditions && conditions["minmilk"] !== undefined && conditions["minmilk"] !== "" ? cdtns + "&minmilk=" + conditions["minmilk"] : cdtns;
    cdtns = conditions && conditions["maxmilk"] !== undefined && conditions["maxmilk"] !== "" ? cdtns + "&maxmilk=" + conditions["maxmilk"] : cdtns;
    cdtns = conditions && conditions["minrice"] !== undefined && conditions["minrice"] !== "" ? cdtns + "&minrice=" + conditions["minrice"] : cdtns;
    cdtns = conditions && conditions["maxrice"] !== undefined && conditions["maxrice"] !== "" ? cdtns + "&maxrice=" + conditions["maxrice"] : cdtns;
    cdtns = cdtns.charAt(0) === "&" ? "?" + cdtns.substring(1) : "" ;

    const getStock = await AxiosInstance.get(
      `paybako` + cdtns,
      {
        cache: {
          ignoreCache: true,
        },
      }
    ).catch((error) => {
      return false;
    });

    if (getStock) {
      response = getStock?.data;
    }

    setPaybako(response);
  }

  useEffect(() => {
    async function fetchData() {
      if (!mounted.current) {
        refreshData();
      }
      mounted.current = false;
    }
    fetchData();
  }, [conditions]);

  const applyConditions = (cond = null) =>{

    let newData = false;
    for (const [key, value] of Object.entries(cond)) {
      if (conditions[key] === undefined || conditions[key] !== value) {
        newData = true;
      }
    }
    if (newData) {
      setConditions(cond);
    }
  }

  return (
    <PaybakoContext.Provider value={{paybako, conditions, applyConditions, refreshData}}>
      {props.children}
    </PaybakoContext.Provider>
  )
}