import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, BlockContext, EmployeeContext, PositionContext, TripSingleContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const EditTripHarvesterModal = (props) => {
  
  const { showToast } = useToastContext();

  const tripSingleContext = useContext(TripSingleContext);
  const {tripSingle} = tripSingleContext;

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const blockContext = useContext(BlockContext);
  const {units} = blockContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [tripId, setTripId] = useState("");
  const [tripStage, setTripStage] = useState("");

  const [hid, sethid] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedHarvestDate, setSelectedHarvestDate] = useState("");
  const [selectedMinDate, setSelectedMinDate] = useState("");
  const [selectedMaxDate, setSelectedMaxDate] = useState("");
  const [selectedUnitBlock, setSelectedUnitBlock] = useState("");
  const [selectedPlantType, setSelectedPlantType] = useState("");
  const [selectedExtra, setSelectedExtra] = useState("");
  const [typeQty, setTypeQty] = useState(0);
  const [typeNotes, setTypeNotes] = useState("");

  useEffect(() => {
    async function fetchData() {
      sethid(props && props.hs && props.hs.id ? props.hs.id : "");
      setSelectedEmployee(props && props.hs && props.hs.employee_id ? employees?.find(i => i.id === parseInt(props.hs.employee_id)) : "");
      setSelectedHarvestDate(props && props.hs && props.hs.harvest_date ? new Date(props.hs.harvest_date) : "");
      setSelectedUnitBlock(props && props.hs && props.hs.block_unit_id ? props.hs.block_unit_id : 0);
      setSelectedPlantType(props && props.hs && props.hs.type ? props.hs.type : "");
      setSelectedExtra(props && props.hs && props.hs.extra ? props.hs.extra : "");
      setTypeQty(props && props.hs && props.hs.qty ? props.hs.qty : "");
      setTypeNotes(props && props.hs && props.hs.notes ? props.hs.notes : "");
    }
    fetchData();
  }, [props, props.hs]);

  useEffect(() => {
    async function fetchData() {
      setTripId(tripSingle && tripSingle.id ? tripSingle.id : "");
      setTripStage(tripSingle && tripSingle.stage ? tripSingle.stage : "");
    }
    fetchData();
  }, [tripSingle]);

  useEffect(() => {
    async function fetchData() {
      setDate();
    }
    fetchData();
  }, [selectedHarvestDate]);

  const setDate = () => {
    let fileDate = new Date(selectedHarvestDate);
    let minTime = new Date(fileDate);
    let maxTime = new Date(fileDate);

    if (minTime) {
      minTime.setDate(fileDate.getDate() - 67);
      minTime.setHours(0, 0, 0, 0);
    }
    
    if (maxTime) {
      maxTime.setDate(fileDate.getDate() + 3);
      maxTime.setHours(23, 59, 59, 0);
    }

    setSelectedMinDate( minTime );
    setSelectedMaxDate( maxTime );
  }

  function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!tripId && !hid) {
      notif = "Unable to continue this process";
    } else if (!selectedEmployee) {
      notif = "Employee must not be empty.";
    } else if (!selectedHarvestDate) {
        notif = "Harvest Date must not be empty.";
    } else if (!selectedUnitBlock) {
        notif = "Unit Block must not be empty.";
    } else if (!selectedPlantType) {
        notif = "Plant Type must not be empty.";
    } else if (!typeQty) {
        notif = "Qty must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("employee_id", selectedEmployee.id);
      formData.append("harvest_date", formatDateToISO(selectedHarvestDate));
      formData.append("block_unit_id", selectedUnitBlock);
      formData.append("type", selectedPlantType);
      formData.append("qty", typeQty);
      formData.append("extra", selectedExtra);
      formData.append("trip_id", tripId);
      if (typeNotes) { formData.append("notes", typeNotes); }
      
      const addWork = await AxiosInstance.post(`/harvesters/${hid}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addWork) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  const valueTemplate = (employee, props) => {
    if (employee) {
      return (
        <div className="flex align-items-center">
            <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemTemplate = (employee) => {
    return (
      <div className="flex align-items-center">
          <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
      </div>
    );
  };

  return (
    <>
      {
        tripStage !== "approved" && tripStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday me-3">
            edit
          </span>
        )
      }

      <Dialog header="Edit" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="employee">Employee</label>
          <Dropdown
            className="form-control"
            value={selectedEmployee}
            options={employees}
            onChange={(_) => {
              let ss = _.target.value;
              setSelectedEmployee(ss);
            }}
            optionLabel="name"
            filter
            filterBy="name"
            placeholder="Search by Employee"
            valueTemplate={valueTemplate} 
            itemTemplate={itemTemplate}
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="harvestdate">Harvest Date</label>
          <Calendar
            id="harvestdate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedHarvestDate}
            minDate={selectedMinDate}
            maxDate={selectedMaxDate}
            onChange={(e) => setSelectedHarvestDate(e.value)}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="blockunit">Unit ( Block )</label>
          <select
            id="blockunit"
            name="blockunit"
            className="form-control"
            onChange={(_) => {
              setSelectedUnitBlock(_.target.value);
            }}
            value={selectedUnitBlock}
            required
          >
            <option value="">Please select</option>
            {
              units?.length > 0 && tripSingle && tripSingle.field_id && units?.filter(obj => parseInt(obj.field_id) === parseInt(tripSingle.field_id))?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name + ' ( ' + e.medium + ' )'}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="planttype">Plant Type</label>
          <select
            id="planttype"
            name="planttype"
            className="form-control"
            onChange={(_) => {
              setSelectedPlantType(_.target.value);
            }}
            value={selectedPlantType}
            required
          >
            <option value="">Please select</option>
            {
              allOption["typeOfHarvest"]?.map((lt, i) => (
                <option key={lt.id} value={lt.id}>{lt.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="qty">Qty</label>
          <input
            type="number"
            className={"form-control " + (typeQty < 0 ? "border-danger" : "") }
            id="qty"
            name="qty"
            placeholder="Qty"
            onChange={(_) => {
              setTypeQty(_.target.value.replace(/[^0-9.-]/gi, ""));
            }}
            value={typeQty}
            required
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="extra">Extra</label>
          <select
            id="extra"
            name="extra"
            className="form-control"
            onChange={(_) => {
              setSelectedExtra(_.target.value);
            }}
            value={selectedExtra}
          >
            <option value="">Please select</option>
            {
              allOption["typeOfHarvestObstacle"]?.map((lt, i) => (
                <option key={lt.id} value={lt.id}>{lt.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setTypeNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditTripHarvesterModal;
