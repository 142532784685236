import React, { useContext, useEffect, useRef, useState } from "react";
import AxiosInstance from "../axios";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate, useParams } from "react-router-dom";
import { EditStockMovementModal, AddStockMovementListModal, EditStockMovementListModal, DeleteStockMovementListModal, VerifyStockMovementStageModal, AddStockMovementImageModal } from "./modals";
import { AllContext, EmployeeContext, FieldContext, StockContext, StockShopContext, UserContext, MachineContext, UserAAContext, useToastContext } from "../contexts";
import { StockMovementSingleContext } from "../contexts";
import { baseURL, imageBaseURL } from "../axios";

import { Galleria } from "primereact/galleria";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const StockMovementView = () => {
  
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const galleria = useRef(null);

  const stockMovementSingleContext = useContext(StockMovementSingleContext);
  const {stockMovementSingle} = stockMovementSingleContext;
  
  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const stockContext = useContext(StockContext);
  const {stocks} = stockContext;
  const stockShopContext = useContext(StockShopContext);
  const {stockShop} = stockShopContext;
  const userContext = useContext(UserContext);
  const {users} = userContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice, userField} = userAAContext;

  let { stock_movement_id } = useParams();
  const [smModal, setSMModal] = useState(Math.round((new Date()).getTime()));

  const [stockMovementImage, setStockMovementImage] = useState([]);
  const [stockMovementList, setStockMovementList] = useState([]);
  const [stockMovementStage, setStockMovementStage] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [refreshCall, setRefreshCall] = useState(true);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(stocks?.length > 0) && refreshCall) { stockContext.refreshData(); }
      if (!(employees?.length > 0) && refreshCall) { employeeContext.refreshData(); }
      if (!(users?.length > 0) && refreshCall) { userContext.refreshData(); }
      if (!(stockShop?.length > 0) && refreshCall) { stockShopContext.refreshData(); }
      if (!(machines?.length > 0) && refreshCall) { machineContext.refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [fields, stocks, employees, users, stockShop, machines, fieldContext, employeeContext, stockContext, userContext, stockShopContext, machineContext, refreshCall]);

  useEffect(() => {
    async function fetchData() {
      if ( stockMovementSingle && stockMovementSingle?.stock_movement_list ) {
        setStockMovementStage(stockMovementSingle?.history_stage);
        setStockMovementList(stockMovementSingle?.stock_movement_list);
        setStockMovementImage(stockMovementSingle?.stock_movement_image);
      }
    }
    fetchData();
  }, [stockMovementSingle]);

  useEffect(() => {
    async function fetchData() {
      new Promise(function(resolve, reject) {
        setShowLoading(true);
        resolve(1);
      }).then(async function(result) {
        const getStockMovementSingle = await AxiosInstance.get(
          `/stockmovement/${stock_movement_id}`,
          {
            cache: {
              ignoreCache: true,
            },
          }
        ).catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
  
        if(getStockMovementSingle){
          stockMovementSingleContext.addData(getStockMovementSingle?.data);
        }
        return 1;
      }).finally(function(result) {
        setShowLoading(false);
      });
    }
    fetchData();
  }, [smModal]);

  // useEffect(() => {
  //   async function fetchData() {
  //     userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
  //   }
  //   fetchData();
  // }, [userDevice]);

  // Columns
  const [visibleStageColumns, setVisibleStageColumns] = useState([
    { field: 'created_at', header: 'Date', visible: true },
    { field: 'changed_to', header: 'Action', visible: true },
    { field: 'changed_by', header: 'By', visible: true },
  ]);

  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'stock_id', header: 'Stock', visible: true },
    { field: 'qty', header: 'Qty', visible: true },
    { field: 'for', header: 'For', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'stock_id', header: 'Stock', visible: true },
    { field: 'qty', header: 'Qty', visible: true },
    { field: 'for', header: 'For', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'stock_id', header: 'Stock', visible: true },
    { field: 'qty', header: 'Qty', visible: true },
    { field: 'for', header: 'For', visible: true },
    { field: 'notes', header: 'Notes', visible: true },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  // Template
  const itemTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/stock-movement-images/large/${stock_movement_id}/${item.image}`} alt="" style={{ width: "100%" }} />
  }

  const thumbnailTemplate = (item) => {
    return <img src={`${imageBaseURL}uploads/stock-movement-images/thumb/${stock_movement_id}/${item.image}`} alt={item.alt} style={{ width: "70%" }} />
  }

  const actionTemplate = (stage) => {
    return (
      stage && stage?.changed_to ? (
        stage?.changed_to === "approved" || stage?.changed_to === "approvedbysystem" ?
          "Approve" : (
            stage?.changed_to === "verified" || stage?.changed_to === "verifiedbysystem" ?
              "Verify" : 
                "Submit"
          )
      ) : "");
  };

  const byTemplate = (stage) => {
    return users?.length > 0 && stage.changed_by && users?.find(i => i.id === parseInt(stage.changed_by)) ? users?.find(i => i.id === parseInt(stage.changed_by))?.name : "";
  };

  const dtTemplate = (dt) => {
    const date = new Date(dt);
    const options = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const stockTemplate = (sml) => {
    return stocks?.length > 0 && sml.stock_id && stocks?.find(i => i.id === parseInt(sml.stock_id)) ? stocks?.find(i => i.id === parseInt(sml.stock_id))?.name : "";
  };

  const qtyTemplate = (sml) => {
    return (
      <span>
          {sml.qty}
          {sml.barbut && (
              <i className="pi pi-trash" style={{ fontSize: ".8rem", color: "grey", marginLeft: '5px' }} />
          )}
      </span>
    );
  };
  
  const forTemplate = (sml) => {
    let retVal = "";
    switch (sml?.for) {
      case "stockmovementforpetrol":
        retVal = (
          sml && sml?.stock_petrol && sml?.stock_petrol?.forp ? (
            sml?.stock_petrol?.forp === "employees" && sml?.stock_petrol?.employee_id ? 
              ( "Petrol for " + employees?.find(i => i.id === parseInt(sml?.stock_petrol?.employee_id))?.name ) :
                (
                  sml?.stock_petrol?.forp === "machines" && sml?.stock_petrol?.machine_id ? 
                    ( "Petrol for " + machines?.find(i => i.id === parseInt(sml?.stock_petrol?.machine_id))?.name ) :
                      ""
                )
          ) : ""
        );
        break;
      case "stockmovementfortransfer":
        retVal = (
          sml && sml?.stock_transfer && sml?.stock_transfer?.field_id_to ?
            allOption["typeOfStockMovementFor"]?.find(i => i.id === sml?.for)?.name + " to " + fields?.find(i => i.id === parseInt(sml?.stock_transfer?.field_id_to))?.name
              : ""
        );
        break;
      case "stockmovementforrepair":
        retVal = (
          sml && sml?.stock_repair && sml?.stock_repair?.stock_shop_id ?
            allOption["typeOfStockMovementFor"]?.find(i => i.id === sml?.for)?.name + " at " + stockShop?.find(i => i.id === parseInt(sml?.stock_repair?.stock_shop_id))?.name
              : ""
        );
        break;
      default:
        retVal = "Others";
        break;
    }
    return retVal;
  };

  const editViewTemplate = (sml) => {
    return (
      <>
        <EditStockMovementListModal sml={sml} sRC={setSMModal} />
        <DeleteStockMovementListModal sml={sml} sRC={setSMModal} />
      </>
    );
  };

  return (
    <div className="container bg-white content-container">
      <div className="row text-left">
        <span className="link-isday" onClick={()=>navigate(-1)}>
          &lt; Back
        </span>
      </div>
      {
        showLoading
        ?
          <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
          </div>
        : 
          (
            <>
              <h2 className="h5 pt-3 pb-3 d-flex justify-content-center">Stock Movement</h2>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Information</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <EditStockMovementModal sRC={setSMModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Label</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Field</td>
                              <td>{fields?.length > 0 && stockMovementSingle.field_id && fields?.find(i => i.id === parseInt(stockMovementSingle.field_id)) ? fields?.find(i => i.id === parseInt(stockMovementSingle.field_id))?.name : ""}</td>
                            </tr>
                            <tr>
                              <td>Received Date</td>
                              <td>{stockMovementSingle.ondate}</td>
                            </tr>
                            <tr>
                              <td>Receive By</td>
                              <td>{employees?.length > 0 && stockMovementSingle.employee_id && employees?.find(i => i.id === parseInt(stockMovementSingle.employee_id)) ? employees?.find(i => i.id === parseInt(stockMovementSingle.employee_id))?.name : ""}</td>
                            </tr>
                            <tr>
                              <td>Notes</td>
                              <td>{stockMovementSingle.notes}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">Images</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddStockMovementImageModal sRC={setSMModal} />
                        </div>
                      </div>
                      <hr />
                      <div className="flex justify-content-center">
                        <Galleria ref={galleria} value={stockMovementImage} numVisible={7} style={{ maxWidth: '850px' }}
                            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                            circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />

                        <div className="grid row mb-2" style={{ maxWidth: '400px' }}>
                          {
                            stockMovementImage && stockMovementImage.map((image, index) => {
                              let imgEl = <img src={`${imageBaseURL}uploads/stock-movement-images/large/${stock_movement_id}/${image.image}`} onClick={() => {setActiveIndex(index); galleria.current.show()}} style={{ width: "100%" }} />
                              return (
                                <div className="col-lg-4 mt-3" key={index}>
                                    {imgEl}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 container-stage d-flex align-items-center justify-content-center">
                        {
                          stockMovementSingle.stage === "approved" || stockMovementSingle.stage === "approvedbysystem"
                            ? <i className="pi pi-check" style={{ fontSize: "7rem", color: "green" }} />
                              : stockMovementSingle.stage === "verified" || stockMovementSingle.stage === "verifiedbysystem"
                                ? <i className="pi pi-eye" style={{ fontSize: "7rem", color: "orange" }} />
                                  : stockMovementSingle.stage === "submitted"
                                    ? <i className="pi pi-eye-slash" style={{ fontSize: "7rem", color: "red" }} />
                                      : <i className="pi pi-clock" style={{ fontSize: "7rem", color: "grey" }} />
                        }
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row mb-2">
                        <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                          <h3 className="h6">Stage</h3>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-7 col-7 d-flex justify-content-end small">
                          <VerifyStockMovementStageModal sRC={setSMModal} />
                        </div>
                      </div>
                      <hr />
                      <DataTable
                        value={stockMovementStage}
                        stripedRows
                        removableSort
                        rows={20}
                        columnResizeMode="expand"
                        resizableColumns
                        dataKey="id"
                        emptyMessage="No Stage found."
                      >
                        {
                          visibleStageColumns.map((col, index) => (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.header}
                              body={(rowData) => (
                                col.field === 'created_at' ?
                                  dtTemplate(rowData?.created_at) :
                                    (
                                      col.field === 'changed_to' ?
                                        actionTemplate(rowData) :
                                          (
                                            col.field === 'changed_by' ?
                                              byTemplate(rowData) :
                                                rowData[col.field]
                                          )
                                    )
                                )
                              }
                              style={{
                                display: col.visible ? 'table-cell' : 'none',
                                whiteSpace: 'normal',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '10px'
                              }}
                              showFilterMenu={false}
                            />
                          ))
                        }
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h3 className="h6">List</h3>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-end small">
                          <AddStockMovementListModal sRC={setSMModal} />
                        </div>
                      </div>
                      <hr />
                      <DataTable
                        value={stockMovementList}
                        stripedRows
                        removableSort
                        rows={20}
                        columnResizeMode="expand"
                        resizableColumns
                        dataKey="id"
                        emptyMessage="No files found."
                      >
                        {
                          visibleColumns.map((col, index) => (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.field === 'edit_view' ? '' : col.header}
                              body={(rowData) => (
                                col.field === 'stock_id' ?
                                  stockTemplate(rowData) :
                                    (
                                      col.field === 'for' ?
                                        forTemplate(rowData) :
                                          (
                                            col.field === 'qty' ?
                                              qtyTemplate(rowData) :
                                                (
                                                  col.field === 'edit_view' ?
                                                    editViewTemplate(rowData) :
                                                      rowData[col.field]
                                                )
                                          )
                                    )
                                )
                              }
                              style={{
                                display: col.visible ? 'table-cell' : 'none',
                                whiteSpace: 'normal',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '10px'
                              }}
                              showFilterMenu={false}
                            />
                          ))
                        }
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
      }
    </div>
  );
};

export default StockMovementView;
