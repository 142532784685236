import React, { createContext, useState} from "react";
import AxiosInstance from "../axios";

export const EmployeeContext = createContext();

export const EmployeeState = (props) => {
  const [employees, setEmployees] = useState([]);

  async function refreshData() {
    const response = await EmployeesEntries();
    setEmployees(response);
  }

  return (
    <EmployeeContext.Provider value={{employees, refreshData}}>
      {props.children}
    </EmployeeContext.Provider>
  )
}

const EmployeesEntries = async () => {

  // const { showToast } = useToastContext();
  let retVal = [];

  const getEmployee = await AxiosInstance.get(
    `employees`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
    // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
    return false;
  });

  if (getEmployee) {
    retVal = getEmployee?.data;
  }

  return retVal;
  
}