import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { AddWorkModal } from "./modals";
import { AllContext, FieldContext, WorkContext, MachineContext, BlockContext, UserAAContext } from "../contexts";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

// https://primereact.org/datatable/
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from "primereact/dropdown";

const WorkLogs = () => {

  const navigate = useNavigate();
  
  const workContext = useContext(WorkContext);
  const {works, conditions} = workContext;

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;
  const blockContext = useContext(BlockContext);
  const {blocks} = blockContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice, userField} = userAAContext;
  
  const [selectedPage, setSelectedPage] = useState(conditions && conditions["page"] !== undefined && conditions["page"] !== "" ? conditions["page"] : 1);
  const [startFromRecord, setStartFromRecord] = useState(0);
  const [rowsToFetch, setRowsToFetch] = useState(conditions && conditions["limit"] !== undefined && conditions["limit"] !== "" ? conditions["limit"] : 10);
  const [sortField, setSortField] = useState(conditions && conditions["sort_by"] !== undefined && conditions["sort_by"] !== "" ? conditions["sort_by"] : "");
  const [sortOrder, setSortOrder] = useState(conditions && conditions["sort_order"] !== undefined && conditions["sort_order"] !== "" ? conditions["sort_order"] : "");
  
  const [selectedFilterField, setSelectedFilterField] = useState(conditions && conditions["field_id"] !== undefined && conditions["field_id"] !== "" ? fields?.find(i => i.id === parseInt(conditions["field_id"])) : []);
  const [selectedFilterWorkType, setSelectedFilterWorkType] = useState(conditions && conditions["work_type"] !== undefined && conditions["work_type"] !== "" ? allOption["typeOfWork"]?.find(i => i.id === parseInt(conditions["work_type"])) : []);
  
  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);
  
  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(blocks?.length > 0) && refreshCall) { blockContext.refreshData(); }
      if (!(machines?.length > 0) && refreshCall) { machineContext.refreshData(); }
      if (!(works?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [works, fields, workContext, fieldContext, allContext, refreshCall]);

  useEffect(() => {
    async function fetchData() {
      let cdtns = [];
      cdtns["page"] = selectedPage;
      cdtns["limit"] = rowsToFetch;
      cdtns["sort_by"] = sortField;
      cdtns["sort_order"] = sortOrder;
      cdtns["field_id"] = selectedFilterField && selectedFilterField.id ? selectedFilterField.id : "";
      cdtns["work_type"] = selectedFilterWorkType && selectedFilterWorkType.id ? selectedFilterWorkType.id : "";
      workContext.applyConditions(cdtns);
    }
    fetchData();
  }, [selectedPage, rowsToFetch, sortField, sortOrder, selectedFilterField, selectedFilterWorkType]);

  useEffect(() => {
    async function fetchData() {
      userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
    }
    fetchData();
  }, [userDevice]);

  // Columns
  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: true },
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'work_date', header: 'Date', visible: true },
    { field: 'work_type', header: 'Type', visible: true },
    { field: 'work_list', header: 'List', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: true },
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'work_date', header: 'Date', visible: true },
    { field: 'work_type', header: 'Type', visible: true },
    { field: 'work_list', header: 'List', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: false },
    { field: 'field_id', header: 'Field', visible: false },
    { field: 'work_date', header: 'Date', visible: true },
    { field: 'work_type', header: 'Type', visible: true },
    { field: 'work_list', header: 'List', visible: false },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  const fieldFilter = () => {
    return (
      <Dropdown
        value={selectedFilterField}
        onChange={(e) => setSelectedFilterField(e.value)}
        options={( userField? fields?.filter(obj => obj.id === parseInt(userField)): fields )}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="Fields"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const workTypeFilter = () => {
    return (
      <Dropdown
        value={selectedFilterWorkType}
        onChange={(e) => setSelectedFilterWorkType(e.value)}
        options={allOption["typeOfWork"]}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="Received By"
        placeholder="Search by Received By"
        className="w-full md:w-14rem"
      />
    );
  }

  // Pagination
  const updateSorting = (e) => {
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
  }

  const onPageChange = (event) => {
    setRowsToFetch(event.rows);
    setSelectedPage(parseInt(event.page) + 1);
    setStartFromRecord(event.first);
  };

  // Data
  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await workContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }
  
  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const stageTemplate = (work) => {
    return work.stage ? 
              work.stage === "approved" || work.stage === "approvedbysystem"
                ? <i className="pi pi-check" style={{ fontSize: "1rem", color: "green" }} />
                  : work.stage === "verified" || work.stage === "verifiedbysystem"
                    ? <i className="pi pi-eye" style={{ fontSize: "1rem", color: "orange" }} />
                      : work.stage === "submitted"
                        ? <i className="pi pi-eye-slash" style={{ fontSize: "1rem", color: "red" }} />
                          : <i className="pi pi-clock" style={{ fontSize: "1rem", color: "grey" }} />
            : ""
  };

  const fieldTemplate = (work) => {
    return fields?.length > 0 && work.field_id ? fields?.find(i => i.id === parseInt(work.field_id))?.name : "";
  };

  const workTypeTemplate = (work) => {
    return work.work_type ? allOption["typeOfWork"]?.find(i => i.id === work.work_type)?.name : "";
  };

  const worklistTemplate = (work) => {
    let retVal = "";
    let retValArray = [];
    if (work.work_list) {
      let wl = work.work_list;
      if (wl?.length > 0) {
        wl.forEach((item, index) => {
          if (item.block_id != null) {
            const foundBlock = blocks?.find(i => i.id === parseInt(item.block_id));
            if (foundBlock && !retValArray.includes(foundBlock.medium)) {
              retValArray.push(foundBlock.medium);
            }
          }
          if (item.machine_id != null) {
            const foundMachine = machines?.find(i => i.id === parseInt(item.machine_id));
            if (foundMachine && !retValArray.includes(foundMachine.name)) {
              retValArray.push(foundMachine.name);
            }
          }
        });
      }
    }
    
    if (retValArray.length === 0) {
      retVal = '';
    } else {
      retVal = retValArray.join(', ');
    }

    return retVal;
  };

  const editViewTemplate = (work) => {
    return <>
      <span onClick={() => {navigate(`/work/${work.id}`);}} className="link-isday ms-3">view</span>
    </>
    ;
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Works</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="visibleColumn">Column</label>
                      <MultiSelect
                        id="visibleColumn"
                        className="w-100"
                        value={visibleColumns.filter((col) => col.visible).map((col) => col.field)}
                        options={visibleColumns.map((col) => ({ label: col.header, value: col.field }))}
                        onChange={(e) => toggleColumnsVisibility(e.value)}
                        placeholder="Select Columns"
                      />
                    </div>
                  </div>
                </div>
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
                              }}
                            >
                              reset column
                            </span>
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                setSelectedFilterField("");
                                setSelectedFilterWorkType("");
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddWorkModal />
                          </div>
                        </div>
                        <hr />
                        <DataTable
                          value={works?.data}
                          stripedRows
                          removableSort
                          rows={10}
                          columnResizeMode="expand"
                          resizableColumns
                          // filters={filters}
                          filterDisplay="row"
                          filterClearIcon={null}
                          dataKey="id"
                          emptyMessage="No works found."
                          sortField={sortField}
                          sortOrder={sortOrder}
                          onSort={updateSorting}
                        >
                          {
                            visibleColumns.map((col) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                filter={col.field === 'field_id' || col.field === 'work_type'}
                                filterElement={(rowData) => (col.field === 'field_id' ? fieldFilter(rowData) : (col.field === 'work_type' ? workTypeFilter(rowData) : undefined))}
                                body={(rowData) => (col.field === 'stage' ? stageTemplate(rowData) : (col.field === 'field_id' ? fieldTemplate(rowData) : (col.field === 'work_type' ? workTypeTemplate(rowData) : (col.field === 'work_list' ? worklistTemplate(rowData) : (col.field === 'edit_view' ? editViewTemplate(rowData) : rowData[col.field])))))}
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                                sortable={col.field !== 'stage' && col.field !== 'edit_view'}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                        <Paginator first={startFromRecord} rows={rowsToFetch} totalRecords={works && works?.pagination ? works?.pagination.total_records : 10 } rowsPerPageOptions={[5, 10]} onPageChange={onPageChange} template={{ layout: "PrevPageLink PageLinks CurrentPageReport NextPageLink RowsPerPageDropdown" }} />
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkLogs;
