import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AddUserModal, EditUserModal } from "./modals";
import Header from "./layout/Header";
import { PositionContext, UserContext, FieldContext } from "../contexts";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

const Users = () => {

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const userContext = useContext(UserContext);
  const {users} = userContext;
  
  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(positions?.length > 0) && refreshCall) { positionContext.refreshData(); }
      if (!(users?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [fields, positions, users, fieldContext, positionContext, userContext, refreshCall]);

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await userContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }
  
  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>User List</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddUserModal />
                          </div>
                        </div>
                        <hr />
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Position</th>
                                <th scope="col">Field</th>
                                <th scope="col">Join Date</th>
                                <th scope="col">Username</th>
                                <th scope="col">Password</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                users?.length > 0 && users?.map((e, i) => (
                                  <tr key={i}>
                                    <td>{e.name}</td>
                                    <td>{positions?.length > 0 && e.position_id ? positions?.find(i => i.id === parseInt(e.position_id))?.name : ""}</td>
                                    <td>{fields?.length > 0 && e.field_id ? fields?.find(i => i.id === parseInt(e.field_id))?.name : ""}</td>
                                    <td>{e.join_at}</td>
                                    <td>{e.username}</td>
                                    <td>****</td>
                                    <td>
                                      <EditUserModal ue={e} />
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
