import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./layout/Header";

const Dashboard = () => {
  let { wLoc } = useParams();

  const targetDate = new Date('2024-01-01T00:00:00Z');
  const now = new Date();
  const timeDifference = targetDate.getTime() - now.getTime();

  const initialDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const initialHours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const initialMinutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const initialSeconds = Math.floor((timeDifference / 1000) % 60);

  const [days, setDays] = useState(initialDays);
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          if (hours > 0) {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          } else {
            if (days > 0) {
              setDays(days - 1);
              setHours(23);
              setMinutes(59);
              setSeconds(59);
            } else {
              clearInterval(countdown);
            }
          }
        }
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [days, hours, minutes, seconds]);

  const calculatePercentage = (value, total) => {
    return `${(value / total) * 100}%`;
  };

  return (
    <>
      <Header wLoc={wLoc} />
      <div className="container bg-white content-container">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <div style={{ marginBottom: '50px' }}>
                        <h1>Upcoming Unveiling</h1>
                      </div>
                      <div className="countdown-timer">
                        <div className="countdown-section">
                          <div
                            className="circle days"
                            style={{
                              background: `conic-gradient(#000 0%, #000 ${calculatePercentage( days, 30 )}, #fff ${calculatePercentage( days, 30 )}, #fff 100%)`,
                            }}
                          >
                            <div className="circle-container">
                              <span className="countdown-digit">{days}</span>
                            </div>
                          </div>
                          <div className="countdown-label">Days</div>
                        </div>

                        <div className="countdown-section">
                          <div
                            className="circle hours"
                            style={{
                              background: `conic-gradient(#000 0%, #000 ${calculatePercentage( hours, 24 )}, #fff ${calculatePercentage( hours, 24 )}, #fff 100%)`,
                            }}
                          >
                            <div className="circle-container">
                              <span className="countdown-digit">{hours}</span>
                            </div>
                          </div>
                          <div className="countdown-label">Hours</div>
                        </div>

                        <div className="countdown-section">
                          <div
                            className="circle minutes"
                            style={{
                              background: `conic-gradient(#000 0%, #000 ${calculatePercentage( minutes, 60 )}, #fff ${calculatePercentage( minutes, 60 )}, #fff 100%)`,
                            }}
                          >
                            <div className="circle-container">
                              <span className="countdown-digit">{minutes}</span>
                            </div>
                          </div>
                          <div className="countdown-label">Minutes</div>
                        </div>

                        <div className="countdown-section">
                          <div
                            className="circle seconds"
                            style={{
                              background: `conic-gradient(#000 0%, #000 ${calculatePercentage( seconds, 60 )}, #fff ${calculatePercentage( seconds, 60 )}, #fff 100%)`,
                            }}
                          >
                            <div className="circle-container">
                              <span className="countdown-digit">{seconds}</span>
                            </div>
                          </div>
                          <div className="countdown-label">Seconds</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

// return (
//   
// );
