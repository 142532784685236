import React, { createContext, useEffect, useState } from "react";

export const UserAAContext = createContext();

export const UserAAState = (props) => {
  const [userAccess, setUserAccess] = useState([]);
  const [userAbility, setUserAbility] = useState([]);
  const [userField, setUserField] = useState("");
  const [userDevice, setUserDevice] = useState("");

  useEffect(() => {
    async function fetchData() {
      if(localStorage.getItem("lue") && localStorage.getItem("lue") !== null && localStorage.getItem("lue") !== "") {
        let lue = JSON.parse(localStorage.getItem("lue"));
        setUserAccess(lue && lue["access"] && lue["access"] ? lue["access"] : []);
        setUserAbility(lue && lue["ability"] && lue["ability"] ? lue["ability"] : []);
        setUserField(lue && lue["field_id"] && lue["field_id"] ? lue["field_id"] : "");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setUserDevice("Mobile");
    } else {
      setUserDevice("Desktop");
    }
  }, []);

  return (
    <UserAAContext.Provider value={{userAccess, userAbility, userDevice, userField}}>
      {props.children}
    </UserAAContext.Provider>
  )
}