import React, { createContext, useState} from "react";
// import { useToastContext } from "../contexts";
import AxiosInstance from "../axios";

export const BlockContext = createContext();

export const BlockState = (props) => {
  const [blocks, setBlocks] = useState([]);
  const [units, setUnits] = useState([]);

  async function refreshData() {

    const responseBlocks = await BlocksEntries();
    setBlocks(responseBlocks);

    const responseUnits = await UnitsEntries();
    setUnits(responseUnits);

  }

  return (
    <BlockContext.Provider value={{blocks, units, refreshData}}>
      {props.children}
    </BlockContext.Provider>
  )
}

const BlocksEntries = async () => {
  
  //const { showToast } = useToastContext();
  let retVal = [];

  const getBlock = await AxiosInstance.get(
    `blocks/all`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
    // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
    return false;
  });

  if (getBlock) {
    retVal = getBlock?.data;
  }

  return retVal;

}

const UnitsEntries = async () => {
  
  //const { showToast } = useToastContext();
  let retVal = [];

  const getUnit = await AxiosInstance.get(
    `blocks/units`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
    // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
    return false;
  });

  if (getUnit) {
    retVal = getUnit?.data;
  }

  return retVal;

}