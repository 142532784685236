import React, { useState, useContext, useEffect } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { TripSingleContext, useToastContext } from "../../contexts";

const AddTripImageModal = (props) => {
  
  const { showToast } = useToastContext();

  const tripSingleContext = useContext(TripSingleContext);
  const {tripSingle} = tripSingleContext;
  
  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);
  
  const [tripId, setTripId] = useState("");
  const [tripStage, setTripStage] = useState("");
  const [tripImage, setTripImage] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      if ( tripSingle && tripSingle.id ) {
        setTripId(tripSingle.id);
        setTripStage(tripSingle.stage);
      }
    }
    fetchData();
  }, [tripSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!tripId) {
      notif = "Please refresh the page.";
    } else if (!tripImage) {
      notif = "Image must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {
      let formData = new FormData();
      formData.append("trip_id", tripId);
      formData.append("fimage", tripImage);

      const callAPI = await AxiosInstance.post("/tripimage", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setTripImage("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      { tripStage !== "approved" && tripStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            add new
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="category">Image</label>
        </div>
        <div className="form-group mb-2">
          <input
            type="file"
            onChange={(_) => {
              setTripImage(_.target.files[0]);
            }} 
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddTripImageModal;
