import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { StockShopContext, useToastContext } from "../../contexts";

const EditStockShopModal = (props) => {
  
  const { showToast } = useToastContext();

  const stockShopContext = useContext(StockShopContext);
  
  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [shId, setSHId] = useState("");
  const [typeName, setTypeName] = useState("");
  const [typeAddress, setTypeAddress] = useState("");
  const [typePhone, setTypePhone] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      setSHId(props && props.ss && props.ss.id ? props.ss.id : "");
      setTypeName(props && props.ss && props.ss.name ? props.ss.name : "");
      setTypeAddress(props && props.ss && props.ss.address ? props.ss.address : "");
      setTypePhone(props && props.ss && props.ss.phone ? props.ss.phone : "");
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    stockShopContext.refreshData();
    setDisableButton(false);
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeName) {
      notif = "Name must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {

      let formData = new URLSearchParams();
      formData.append("name", typeName);
      formData.append("taddress", typeAddress.trim() ? typeAddress.trim() : "");
      formData.append("tphone", typePhone.trim() ? typePhone.trim() : "");

      const callAPI = await AxiosInstance.put(`/stockshop/${shId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        edit
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              onChange={(_) => {
                setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeName}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              className="form-control"
              id="address"
              name="address"
              placeholder="Address"
              onChange={(_) => {
                setTypeAddress(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeAddress}
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="Phone"
              onChange={(_) => {
                setTypePhone(_.target.value);
              }}
              value={typePhone}
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditStockShopModal;
