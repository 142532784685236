import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { FieldContext, useToastContext } from "../../contexts";

const EditStockFieldModal = (props) => {
  
  const { showToast } = useToastContext();

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [stockFieldId, setStockFieldId] = useState("");
  const [stockId, setStockId] = useState("");
  const [selectStockField, setSelectStockField] = useState("");
  const [typeStockAt, setTypeStockAt] = useState("");
  const [typeStockQty, setTypeStockQty] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      setStockFieldId(props && props.stock && props.stock.id ? props.stock.id : "");
      setStockId(props && props.stock && props.stock.stock_id ? props.stock.stock_id : "");
      setSelectStockField(props && props.stock && props.stock.field_id ? props.stock.field_id : "");
      setTypeStockAt(props && props.stock && props.stock.at ? props.stock.at : "");
      setTypeStockQty(props && props.stock && props.stock.qty ? props.stock.qty : "");
    }
    fetchData();
  }, [props]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeStockAt) {
      notif = "At must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new URLSearchParams();
      formData.append("stock_id", stockId);
      formData.append("field_id", selectStockField);
      formData.append("at", typeStockAt);
      formData.append("qty", typeStockQty);

      const callAPI = await AxiosInstance.put(`/stockfield/${stockFieldId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        edit
      </span>

      <Dialog header="Edit" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="loc">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setSelectStockField(_.target.value);
            }}
            value={selectStockField}
            required
            disabled
          >
            <option value="">Please select</option>
            {
              fields?.length > 0 && fields?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="at">At</label>
          <input
            type="text"
            className="form-control"
            id="at"
            name="at"
            placeholder="At"
            onChange={(_) => {
              setTypeStockAt(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeStockAt}
            required
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        {/* <div className="form-group mb-2">
          <label htmlFor="qty">Qty</label>
          <input
            type="text"
            className="form-control"
            id="qty"
            name="qty"
            placeholder="Qty"
            onChange={(_) => {
              setTypeStockQty(_.target.value);
            }}
            value={typeStockQty}
            required
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div> */}
      </Dialog>
    </>
  );
};

export default EditStockFieldModal;
