import React, { useState } from "react";
import AxiosInstance from "../axios";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../useAuth";
import { useToastContext } from "../contexts";

const Login = () => {
  
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  
  const { login } = useAuth();
  const { state } = useLocation();
  
  const [disableButton, setDisableButton] = useState(false);

  const [typeUsername, setTypeUsername] = useState("");
  const [typePassword, setTypePassword] = useState("");

  const submitWorkLog = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeUsername) {
      notif = "Username must not be empty.";
    } else if (!typePassword) {
      notif = "Password must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {
      let formData = new FormData();
      formData.append("username", typeUsername.trim());
      formData.append("password", typePassword.trim());

      const getLogin = await AxiosInstance.post(
        "/users/login",
        formData
      ).catch((error) => {
        let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
        showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
        return false;
      });

      if (getLogin) {
        let e = getLogin?.data;
        localStorage.setItem("lue", JSON.stringify(e));
        login().then(() => {
          navigate(state?.path || "/");
        });
      }

      setDisableButton(false);

    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  return (
    <div className="container content-container d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
      <div className="row bg-white rounded d-flex justify-content-center" style={{ minWidth: '375px', maxWidth: '500px', width: '100%' }}>
        <div className="col-lg-12">
          <div className="card-body p-5">
            <h2 className="h5 mb-3 d-flex justify-content-center">
              {window.location.hostname.includes("uat") ? (
                <>
                  <span className="fw-bolder text-danger me-1">UAT</span>- Login Page
                </>
              ) : (
                <>Login Page</>
              )}
            </h2>
            <div className="form-group mb-2" id="moreDetailsOfOtherWorkDiv">
              <label htmlFor="moredetails">Username</label>
              <input
                type="text"
                className="form-control"
                id="moredetails"
                name="moredetails"
                placeholder="Username"
                onChange={(event) => {
                  setTypeUsername(event.target.value.replace(/[^A-Za-z0-9]/gi, ""));
                }}
                value={typeUsername}
                required
                onKeyDown={(e) => e.key === "Enter" && submitWorkLog()}
              />
            </div>
            <div className="form-group mb-2" id="moreDetailsOfOtherWorkDiv">
              <label htmlFor="moredetails">Password</label>
              <input
                type="password"
                className="form-control"
                id="moredetails"
                name="moredetails"
                placeholder="Password"
                onChange={(event) => {
                  setTypePassword(event.target.value);
                }}
                value={typePassword}
                required
                onKeyDown={(e) => e.key === "Enter" && submitWorkLog()}
              />
            </div>
            <div className="form-group text-center mt-4 d-flex justify-content-center">
              <Button className="form-control" variant="primary" disabled={disableButton} onClick={() => submitWorkLog()}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
