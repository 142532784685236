import React, { createContext, useEffect, useRef, useState} from "react";
import AxiosInstance from "../axios";

export const PayrollContext = createContext();

export const PayrollState = (props) => {
  
  const mounted = useRef(true);

  const [payroll, setPayroll] = useState([]);

  async function refreshData() {
    let response = []; 
    let cdtns = "";

    // cdtns = cdtns.charAt(0) === "&" ? "?" + cdtns.substring(1) : "" ;

    const getStock = await AxiosInstance.get(
      `payroll` + cdtns,
      {
        cache: {
          ignoreCache: true,
        },
      }
    ).catch((error) => {
      return false;
    });

    if (getStock) {
      response = getStock?.data;
    }

    setPayroll(response);
  }

  return (
    <PayrollContext.Provider value={{payroll, refreshData}}>
      {props.children}
    </PayrollContext.Provider>
  )
}