import React, { createContext, useEffect, useRef, useState} from "react";
// import { useToastContext } from "../contexts";
import AxiosInstance from "../axios";

export const StockPetrolContext = createContext();

export const StockPetrolState = (props) => {
  
  // const { showToast } = useToastContext();
  const mounted = useRef(true);
  let conditionDefault = [];
      conditionDefault["page"] = 1;
      conditionDefault["limit"] = 10;
      conditionDefault["sort_by"] = "";
      conditionDefault["sort_order"] = "";
      conditionDefault["forp"] = "employees";
      conditionDefault["employee_id"] = "";
      conditionDefault["machine_id"] = "";
  const [stockPetrol, setStockPetrol] = useState([]);
  const [conditions, setConditions] = useState(conditionDefault);

  async function refreshData() {

    let response = []; 
    let cdtns = "";

    cdtns = conditions && conditions["page"] !== undefined && conditions["page"] !== "" ? cdtns + "&page=" + conditions["page"] : cdtns;
    cdtns = conditions && conditions["limit"] !== undefined && conditions["limit"] !== "" ? cdtns + "&limit=" + conditions["limit"] : cdtns;
    cdtns = conditions && conditions["sort_by"] !== undefined && conditions["sort_by"] !== "" && conditions["sort_by"] !== null ? cdtns + "&sort_by=" + conditions["sort_by"] : cdtns;
    cdtns = conditions && conditions["sort_order"] !== undefined && conditions["sort_order"] !== "" ? (conditions["sort_order"] === 1 ? cdtns + `&sort_order=asc` : cdtns + `&sort_order=desc`) : cdtns;
    cdtns = conditions && conditions["forp"] !== undefined && conditions["forp"] !== "" ? cdtns + "&forp=" + conditions["forp"] : cdtns;
    cdtns = conditions && conditions["forp"] !== undefined && conditions["forp"] !== "" && conditions["forp"] === "employees" && conditions["employee_id"] ? cdtns + "&employee_id=" + conditions["employee_id"] : cdtns;
    cdtns = conditions && conditions["forp"] !== undefined && conditions["forp"] !== "" && conditions["forp"] === "machines" && conditions["machine_id"] ? cdtns + "&machine_id=" + conditions["machine_id"] : cdtns;
    // cdtns = conditions && conditions["stage"] !== undefined && conditions["stage"] !== "" ? cdtns + "&stage=" + conditions["stage"] : cdtns;
    cdtns = cdtns.charAt(0) === "&" ? "?" + cdtns.substring(1) : "" ;

    const getStock = await AxiosInstance.get(
      `stockpetrol` + cdtns,
      {
        cache: {
          ignoreCache: true,
        },
      }
    ).catch((error) => {
      // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
      // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      return false;
    });

    if (getStock) {
      response = getStock?.data;
    }

    setStockPetrol(response);
  }

  useEffect(() => {
    async function fetchData() {
      if (!mounted.current) {
        refreshData();
      }
      mounted.current = false;
    }
    fetchData();
  }, [conditions]);

  const applyConditions = (cond = null) =>{

    let newData = false;
    for (const [key, value] of Object.entries(cond)) {
      if (conditions[key] === undefined || conditions[key] !== value) {
        newData = true;
      }
    }
    if (newData) {
      setConditions(cond);
    }
  }

  return (
    <StockPetrolContext.Provider value={{stockPetrol, conditions, applyConditions, refreshData}}>
      {props.children}
    </StockPetrolContext.Provider>
  )
}