import React, { useState, createContext} from "react";

export const LoanSingleContext = createContext();

export const LoanSingleState = (props) => {
  const [loanSingle, setLoanSingle] = useState([]);

  const addData = (abc) =>{
    setLoanSingle(abc);
  }

  return (
    <LoanSingleContext.Provider value={{loanSingle, addData}}>
      {props.children}
    </LoanSingleContext.Provider>
  )
}