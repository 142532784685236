import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AddPositionModal, EditPositionModal } from "./modals";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { AllContext, PositionContext } from "../contexts";

const Positions = () => {

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  
  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(positions?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [positions, positionContext, refreshCall]);

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await positionContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Position List</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  :
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddPositionModal />
                          </div>
                        </div>
                        <hr />
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Access List</th>
                                <th scope="col">Ability To</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                positions?.length > 0 && positions?.map((p, i) => (
                                  <tr key={i}>
                                    <td>{p.name && p.name}</td>
                                    <td>
                                      <ul>
                                        {
                                          p.access_list && JSON.parse(p.access_list)?.length > 0 && Object.entries(JSON.parse(p.access_list)) && Object.entries(JSON.parse(p.access_list)).map(([key, value]) => (
                                            <li key={key}>{value && allOption["typeOfUserAccess"]?.find(i => i.id === value)?.name}</li>
                                          ))
                                        }
                                      </ul>
                                    </td>
                                    <td>
                                      <ul>
                                        {
                                          p.ability_to && JSON.parse(p.ability_to)?.length > 0 && Object.entries(JSON.parse(p.ability_to)) && Object.entries(JSON.parse(p.ability_to)).map(([key, value]) => (
                                            <li key={key}>{value && allOption["typeOfUserAbility"]?.find(i => i.id === value)?.name}</li>
                                          ))
                                        }
                                      </ul>
                                    </td>
                                    <td>
                                      <EditPositionModal pe={p} />
                                    </td>                          
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Positions;
