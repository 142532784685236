import React from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import useAuth from "./useAuth";
import Layout from "./components/layout/Layout";
import { Login, Dashboard, StockPetrol, StockRepair, StockTransfer } from "./components";
import { Users, Positions, Employees, Blocks, Machines } from "./components";
import { Stocks, StockCategory, StockShop, StockView, StockMovement, StockMovementView } from "./components";
import { Works, WorkView } from "./components";
import { Trips, TripView } from "./components";
import { Payroll, Payscale, Payloan, Paybreak, Paybako } from "./components";
import { History } from "./components";
import { Loans, LoanView } from "./components";
import { Files, FileView } from "./components";

// Bulk Data
import { AllState, BlockState, EmployeeState, PayscaleState, PayrollState, PaybreakState, UserAAState, PositionState, StockState } from "./contexts";
import { StockCategoryState, StockPetrolState, StockRepairState, StockShopState, StockTransferState, UserState, MachineState } from "./contexts";
import { HistoryState, PaybakoState, FileState, LoanState } from "./contexts";
import { FieldState, StockMovementState, TripState, WorkState } from "./contexts";

// Single Data
import { StockImageState, StockFieldState, StockSingleState, LoanSingleState } from "./contexts";
import { FileSingleState, StockMovementSingleState, TripSingleState, WorkSingleState } from "./contexts";


import { ToastProvider } from "./contexts";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";  
import "primeicons/primeicons.css";

function PrivateRoute({ children }) {
  const { authed } = useAuth();
  const location = useLocation();

  return authed === true && (localStorage.getItem("lue") && localStorage.getItem("lue") !== "") ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

const PageAccess = ({ pslug }) => {
  let retVal = false;
  if (localStorage.getItem("lue") && localStorage.getItem("lue") !== "") {
    const lue = JSON.parse(localStorage.getItem("lue"));
    const access = JSON.parse(lue["access"]);
    retVal = access.indexOf(pslug) > -1 ? true : false ;
  }
  return retVal
    ? <Outlet />
    : <Navigate to="/" replace />;
};

function App() {
  return (
    <div className="App">
      <PrimeReactProvider>
        <ToastProvider>
          <Layout>
            <Routes>

              <Route path="/login" element={<Login />} />

              <Route element={<UserAAState><Outlet /></UserAAState>}>

                <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

                <Route element={<PageAccess pslug="files" />}>
                  <Route path="/paybako" element={<PrivateRoute><PaybakoState><Paybako /></PaybakoState></PrivateRoute>} />
                </Route>

                <Route element={
                  <AllState>
                    <FieldState>
                      <UserState>
                        <Outlet />
                      </UserState>
                    </FieldState>
                  </AllState>
                }>

                  <Route element={<FileState><Outlet /></FileState>}>
                    <Route path="/files" element={<PrivateRoute><Files /></PrivateRoute>} />

                    <Route element={
                      <FileSingleState>
                        <Outlet />
                      </FileSingleState>
                    }>
                      <Route path="/file/:file_id" element={<PrivateRoute><FileView /></PrivateRoute>} />
                    </Route>
                    
                  </Route>

                  <Route element={
                    <BlockState>
                      <EmployeeState>
                        <TripState>
                          <PositionState>
                            <StockState>
                              <StockCategoryState>
                                <StockShopState>
                                  <MachineState>
                                    <WorkState>
                                      <Outlet />
                                    </WorkState>
                                  </MachineState>
                                </StockShopState>
                              </StockCategoryState>
                            </StockState>
                          </PositionState>
                        </TripState>
                      </EmployeeState>
                    </BlockState>
                  }>

                    <Route element={<PageAccess pslug="blocks" />}>
                      <Route path="/blocks" element={<PrivateRoute><Blocks /></PrivateRoute>} />
                    </Route> {/* Stock Block End */}
                    
                    <Route element={<PageAccess pslug="positions" />}>
                      <Route path="/positions" element={<PrivateRoute><Positions /></PrivateRoute>} />
                    </Route> {/* Stock Position End */}

                    <Route element={<PageAccess pslug="users" />}>
                      <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
                    </Route> {/* Stock User End */}

                    <Route element={<PageAccess pslug="employees" />}>
                      <Route path="/employees" element={<PrivateRoute><Employees /></PrivateRoute>} />
                    </Route> {/* Stock Employee End */}

                    <Route element={<PageAccess pslug="machines" />}>
                      <Route path="/machines" element={<PrivateRoute><Machines /></PrivateRoute>} />
                    </Route>

                    <Route element={<PageAccess pslug="history" />}>
                      <Route path="/history" element={<PrivateRoute><HistoryState><History /></HistoryState></PrivateRoute>} />
                    </Route>

                    <Route element={<PayscaleState><PayrollState><PaybreakState><Outlet /></PaybreakState></PayrollState></PayscaleState>}>
                      <Route path="/payroll" element={<PrivateRoute><Payroll /></PrivateRoute>} />
                      <Route path="/paybreak" element={<PrivateRoute><Paybreak /></PrivateRoute>} />
                      <Route path="/payloan" element={<PrivateRoute><Payloan /></PrivateRoute>} />
                      <Route path="/payscale" element={<PrivateRoute><Payscale /></PrivateRoute>} />
                    </Route>

                    {/* Divider */}

                    <Route element={<LoanState><Outlet /></LoanState>}>
                      <Route path="/loans" element={<PrivateRoute><Loans /></PrivateRoute>} />

                      <Route element={
                        <LoanSingleState>
                          <Outlet />
                        </LoanSingleState>
                      }>
                        <Route path="/loans/:loan_id" element={<PrivateRoute><LoanView /></PrivateRoute>} />
                      </Route>
                    </Route>

                    <Route element={<PageAccess pslug="stocks" />}>

                      <Route path="/stockcategory" element={<PrivateRoute><StockCategory /></PrivateRoute>} />
                      <Route path="/stockshop" element={<PrivateRoute><StockShop /></PrivateRoute>} />
                      <Route path="/stocks" element={<PrivateRoute><Stocks /></PrivateRoute>} />

                      <Route path="/stockpetrol" element={<PrivateRoute><StockPetrolState><StockPetrol /></StockPetrolState></PrivateRoute>} />
                      <Route path="/stockrepair" element={<PrivateRoute><StockRepairState><StockRepair /></StockRepairState></PrivateRoute>} />
                      <Route path="/stocktransfer" element={<PrivateRoute><StockTransferState><StockTransfer /></StockTransferState></PrivateRoute>} />

                      <Route element={
                        <StockSingleState>
                          <Outlet />
                        </StockSingleState>
                      }>                    
                        <Route path="/stock/:stock_id" element={<PrivateRoute><StockView /></PrivateRoute>} />
                      </Route> {/* Stock Single */}

                      <Route element={
                        <StockMovementState>
                          <Outlet />
                        </StockMovementState>
                      }>
                        <Route path="/stockMovement" element={<PrivateRoute><StockMovement /></PrivateRoute>} />
                      </Route>

                      <Route element={
                        <StockMovementSingleState>
                          <Outlet />
                        </StockMovementSingleState>
                      }>                    
                        <Route path="/stockMovement/:stock_movement_id" element={<PrivateRoute><StockMovementView /></PrivateRoute>} />
                      </Route> {/* Stock Movement Single */}

                      <Route element={<PageAccess pslug="works" />}>
                        <Route path="/works" element={<PrivateRoute><Works /></PrivateRoute>} />

                        <Route element={
                          <WorkSingleState>
                            <Outlet />
                          </WorkSingleState>
                        }>
                          <Route path="/work/:work_id" element={<PrivateRoute><WorkView /></PrivateRoute>} />
                        </Route>
                      </Route>

                      <Route element={<PageAccess pslug="trips" />}>
                        <Route path="/trips" element={<PrivateRoute><Trips /></PrivateRoute>} />

                        <Route element={
                          <TripSingleState>
                            <Outlet />
                          </TripSingleState>
                        }>
                          <Route path="/trip/:trip_id" element={<PrivateRoute><TripView /></PrivateRoute>} />
                        </Route>
                      </Route>

                    </Route>

                  </Route> {/* Many End */}

                </Route> {/* Field End */}

              </Route> {/* Page Access End */}

            </Routes>
          </Layout>
        </ToastProvider>
      </PrimeReactProvider>
    </div>
  );
}

export default App;
