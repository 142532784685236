import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { EmployeeContext, FieldContext, PaybreakContext, useToastContext } from "../../contexts";

const DeleteLoanModal = (props) => {
  
  const { showToast } = useToastContext();

  const paybreakContext = useContext(PaybreakContext);
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  
  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const today = new Date();
  const [eeId, setEEId] = useState("");
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [typeAmount, setTypeAmount] = useState(0);
  
  useEffect(() => {
    async function fetchData() {
      setEEId(props && props.pl && props.pl.id ? props.pl.id : "");
      setSelectedDate(props && props.pl && props.pl.ondate ? props.pl.ondate : "");
      setSelectedEmployee(props && props.pl && props.pl.employee_id ? props.pl.employee_id : "");
      setTypeAmount(props && props.pl && props.pl.expense ? props.pl.expense : "");
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    paybreakContext.refreshData();
    setDisableButton(false);
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedEmployee) {
      notif = "Employee must not be empty.";
    } else if (!typeAmount) {
      notif = "Amount must not be empty.";
    } else if (typeAmount > 0) {
      notif = "Amount must be minus.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {

      let formData = new FormData();
      formData.append("delpb", "yes");

      const callAPI = await AxiosInstance.post(`/payloan/${eeId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        del
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Remove</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="name">
              Remove {employees && selectedEmployee && employees?.find(i => i.id === parseInt(selectedEmployee)) && employees?.find(i => i.id === parseInt(selectedEmployee))?.name} on {selectedDate} with {typeAmount} loan?
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteLoanModal;
