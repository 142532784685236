import React, { useState, createContext} from "react";

export const FileSingleContext = createContext();

export const FileSingleState = (props) => {
  const [fileSingle, setFileSingle] = useState([]);

  const addData = (abc) =>{
    setFileSingle(abc);
  }

  return (
    <FileSingleContext.Provider value={{fileSingle, addData}}>
      {props.children}
    </FileSingleContext.Provider>
  )
}