import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";

const ViewBlockModal = (props) => {

  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [blockHistory, setBlockHistory] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setBlockHistory(props && props.eb ? props.eb : []);
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    setVisible(false);
  }

  const dateTemplate = (dt) => {
    const date = new Date( dt * 1000 );
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('id-ID', options);
  };

  return (
    <>
      {blockHistory && blockHistory?.length > 0 ? <span onClick={handleShow} className="link-isday ms-3">log</span> : ""}

      <Dialog header="Log" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()}>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th>For</th>
                        <th>Expense</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        blockHistory?.length > 0 && blockHistory?.map((sh, i) => (
                          <tr key={i}>
                            <td>{dateTemplate(sh.ondate)}</td>
                            <td>{sh.for}</td>
                            <td>{sh.total_expense}</td>
                            <td>
                              {
                                sh.stock_movement_id
                                  ? (<span onClick={() => {navigate(`/stockmovement/${sh.stock_movement_id}`);}} className="link-isday ms-3">view</span>)
                                    :
                                      (
                                        sh.work_id
                                          ? (<span onClick={() => {navigate(`/work/${sh.work_id}`);}} className="link-isday ms-3">view</span>)
                                            : ""
                                      )
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ViewBlockModal;
