import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, EmployeeContext, FieldContext, MachineContext, PositionContext, TripContext, UserAAContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const AddTripModal = () => {
  
  const { showToast } = useToastContext();

  const tripContext = useContext(TripContext);

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;
  const userAAContext = useContext(UserAAContext);
  const {userField} = userAAContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [selectedField, setSelectedField] = useState("");
  const [selectedHGDate, setSelectedHGDate] = useState("");
  const [selectedMinDate, setSelectedMinDate] = useState("");
  const [selectedMaxDate, setSelectedMaxDate] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");
  const [selectedMachine, setSelectedMachine] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedScaleBy, setSelectedScaleBy] = useState("");
  const [selectedScaleAt, setSelectedScaleAt] = useState("");
  const [typeNotes, setTypeNotes] = useState("");

  useEffect(() => {
    async function fetchData() {
      setDate();
    }
    fetchData();
  }, []);

  const setDate = () => {
    let fileDate = new Date();
    let minTime = new Date(fileDate);
    let maxTime = new Date(fileDate);

    if (minTime) {
      minTime.setDate(fileDate.getDate() - 67);
      minTime.setHours(0, 0, 0, 0);
    }
    
    if (maxTime) {
      maxTime.setHours(23, 59, 59, 0);
    }

    setSelectedHGDate( fileDate );
    setSelectedScaleAt( fileDate );
    setSelectedMinDate( minTime );
    setSelectedMaxDate( maxTime );
  }

  function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  function formatDateTimeToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedField) {
      notif = "Field must not be empty.";
    } else if (!selectedHGDate) {
      notif = "Date must not be empty.";
    } else if (!selectedDestination) {
      notif = "Destination must not be empty.";
    } else if (!selectedMachine) {
      notif = "Machine must not be empty.";
    } else if (!selectedDriver) {
      notif = "Driver must not be empty.";
    } else if (!selectedScaleBy) {
      notif = "Scale By must not be empty.";
    } else if (!selectedScaleAt) {
      notif = "Scale At must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){

      let formData = new FormData();
      formData.append("field_id", selectedField);
      formData.append("trip_date", formatDateToISO(selectedHGDate));
      formData.append("destination", selectedDestination);
      formData.append("machine_id", selectedMachine);
      formData.append("drive_by", selectedDriver.id);
      formData.append("scale_by", selectedScaleBy);
      formData.append("scale_at", formatDateTimeToISO(selectedScaleAt));
      if (typeNotes) { formData.append("notes", typeNotes); }

      const addHG = await AxiosInstance.post("/trips", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addHG) {
        tripContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    setDisableButton(false);
    setSelectedField("");
    setDate();
    setSelectedDestination("");
    setSelectedMachine("");
    setSelectedDriver("");
    setSelectedScaleBy("");
    setTypeNotes("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  const valueTemplate = (employee, props) => {
    if (employee) {
      return (
        <div className="flex align-items-center">
            <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemTemplate = (employee) => {
    return (
      <div className="flex align-items-center">
          <div>{employee.name}{positions?.length > 0 && positions?.find(i => i.id === parseInt(employee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(employee.position_id))?.name + ' )' : '' }</div>
      </div>
    );
  };

  return (
    <>
      <span className="link-isday" onClick={handleShow}>
        add new
      </span>

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="field">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setSelectedField(_.target.value);
              setSelectedDestination("");
              setSelectedMachine("");
              setSelectedDriver("");
              setSelectedScaleBy("");
            }}
            value={selectedField}
            required
          >
            <option value="">Please select</option>
            {
              fields?.length > 0 && (userField
                ? fields?.filter(obj => obj.id === parseInt(userField))?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                : fields?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
              )
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="inputDate">Date</label>
          <Calendar
            id="inputDate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedHGDate}
            minDate={selectedMinDate}
            maxDate={selectedMaxDate}
            onChange={(e) => setSelectedHGDate(e.value)}
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="destination">Destination</label>
          <select
            id="destination"
            name="destination"
            className="form-control"
            onChange={(_) => {
              setSelectedDestination(_.target.value);
            }}
            value={selectedDestination}
            required
            disabled={selectedField ? false : true}
          >
            <option value="">Please select</option>
            {
              allOption["typeOfTripDestination"]?.map((lt, i) => (
                <option key={lt.id} value={lt.id}>{lt.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="machine_id">Machine</label>
          <select
            id="machine_id"
            name="machine_id"
            className="form-control"
            onChange={(_) => {
              setSelectedMachine(_.target.value);
            }}
            value={selectedMachine}
            required
            disabled={selectedField ? false : true}
          >
            <option value="">Please select</option>
            {
              machines?.length > 0 && selectedField && machines?.filter(obj => parseInt(obj.field_id) === parseInt(selectedField))?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="employee_id">Driver</label>
          <Dropdown
            className="form-control"
            value={selectedDriver}
            options={employees}
            onChange={(_) => {
              let ss = _.target.value;
              setSelectedDriver(ss);
            }}
            optionLabel="name"
            filter
            filterBy="name"
            placeholder="Search by Employee"
            valueTemplate={valueTemplate} 
            itemTemplate={itemTemplate}
            required
            disabled={selectedField ? false : true}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="scaleby">Scale By</label>
          <select
            id="scaleby"
            name="scaleby"
            className="form-control"
            onChange={(_) => {
              setSelectedScaleBy(_.target.value);
            }}
            value={selectedScaleBy}
            required
            disabled={selectedField ? false : true}
          >
            <option value="">Please select</option>
            {
              employees?.length > 0 && employees?.filter(obj => parseInt(obj.field_id) === parseInt(selectedField))?.map((p, i) => (
                <option key={p.id} value={p.id}>{p.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="scaleat">Scale At</label>
          <Calendar
            id="scaleat"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedScaleAt}
            minDate={selectedMinDate}
            maxDate={selectedMaxDate}
            onChange={(e) => setSelectedScaleAt(e.value)}
            // timeOnly
            showTime
            hourFormat="24"
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setTypeNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddTripModal;
