import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, FieldContext, PayscaleContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const EditPayscaleModal = (props) => {
  
  const { showToast } = useToastContext();
  
  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const payscaleContext = useContext(PayscaleContext);

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);
  
  const [payscaleId, setPayscaleId] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      setPayscaleId(props && props.ps && props.ps.id ? props.ps.id : "");
      setSelectedField(props && props.ps && props.ps.field_id ? props.ps.field_id : "");
      setSelectedStartDate(props && props.ps && props.ps.start_date ? setDT(props.ps.start_date) : "");
      setSelectedEndDate(props && props.ps && props.ps.end_date ? setDT(props.ps.end_date) : "");
      setSelectedStatus(props && props.ps && props.ps.status ? props.ps.status : "");
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    payscaleContext.refreshData();
    setDisableButton(false);
    setVisible(false);
  }

  const setDT = (dt) => {
    const [day, month, year] = dt.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date;
  };

  const intDT = (dt) => {
    const date = new Date(dt);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-indexed
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedField) {
      notif = "Field must not be empty.";
    } else if (!selectedStartDate) {
      notif = "Start Date must not be empty.";
    } else if (!selectedEndDate) {
      notif = "End Date must not be empty.";
    } else if (!selectedStatus) {
      notif = "Status must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {

      let formData = new FormData();
      formData.append("field_id", selectedField);
      formData.append("start_date", intDT(selectedStartDate, true));
      formData.append("end_date", intDT(selectedEndDate, null, true));
      formData.append("status", selectedStatus);

      const callAPI = await AxiosInstance.post(`/payscale/${payscaleId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        edit
      </span>

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="field">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setSelectedField(_.target.value);
            }}
            value={selectedField}
            required
          >
            <option value="">Please select</option>
            {
              fields?.length > 0 && fields?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="startdate">Start Date</label>
          <Calendar
            id="ondate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedStartDate}
            maxDate={selectedEndDate}
            onChange={(e) => setSelectedStartDate(e.value)}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="enddate">End Date</label>
          <Calendar
            id="ondate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedEndDate}
            minDate={selectedStartDate}
            onChange={(e) => setSelectedEndDate(e.value)}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="status">Status{selectedStatus}</label>
          <select
            id="status"
            name="status"
            className="form-control"
            onChange={(_) => {
              setSelectedStatus(_.target.value);
            }}
            value={selectedStatus}
            required
          >
            <option value="">Please select</option>
            {
              allOption["typeOfStatus"]?.map((p, i) => (
                <option key={p.id} value={p.id}>{p.name}</option>
              ))
            }
          </select>
        </div>
      </Dialog>
    </>
  );
};

export default EditPayscaleModal;
