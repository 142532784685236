import { setup } from "axios-cache-adapter";

// export const baseURL = "http://127.0.0.1:8000/"; // localhost
// export const imageBaseURL = "http://127.0.0.1:8000/"; // localhost

export const baseURL = "https://api.isday.app/"; // au
export const imageBaseURL = "https://isday.s3.ap-southeast-1.amazonaws.com/"; // au

// export const baseURL = "https://apiuat.isday.app/"; // uat
// export const imageBaseURL = "https://isday-uat.s3.ap-southeast-1.amazonaws.com/"; // uat

const AxiosInstance = setup({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  },
  cache: {
    maxAge: 15 * 60 * 1000,
    exclude: {
      methods: ["post", "put", "patch", "delete"],
    },
  },
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  (request) => {
    if(request.url !== "/users/login") {
      if (localStorage.getItem("lue")) {
        const lue = JSON.parse(localStorage.getItem("lue"));

        // set current date time
        const currentTimestamp = new Date().getTime();
        const thirtyMinutesInMilliseconds = 30 * 60 * 1000; // 30 minutes * 60 seconds * 1000 milliseconds
        if (lue.calling && currentTimestamp > (lue.calling + thirtyMinutesInMilliseconds)) {
          localStorage.removeItem("lue");
          window.location.replace("/login");
        }
        lue.calling = currentTimestamp;
        localStorage.setItem("lue", JSON.stringify(lue));

        request.headers["session"] = lue.session;
      }
    }
    return request;
  },
  (error) => Promise.reject(error)
);

// Add a request interceptor
AxiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("lue");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

// const pendings = {}
// const caches = {}
// const cacheUtils = {
//    getUniqueUrl: function (config) {

//      // you can set the rule based on your own requirement
//      return config.url + "&" + config.method
//    },
//    isCached: function (config) {
//      let uniqueUrl = this.getUniqueUrl(config)
//      return caches[uniqueUrl] !== undefined
//    },
//    isPending: function (config) {
//      let uniqueUrl = this.getUniqueUrl(config)
//      if (!pendings[uniqueUrl]) {
//        pendings[uniqueUrl] = [config]
//        return false
//      } else {
//        pendings[uniqueUrl].push(config)
//        return true
//      }
//    },
//    setCachedResponse: function (config, response) {
//      let uniqueUrl = this.getUniqueUrl(config)
//      caches[uniqueUrl] = response
//      if (pendings[uniqueUrl]) {
//        pendings[uniqueUrl].forEach(configItem => {
//          configItem.isFinished = true
//        })
//      }
//    },
//    getError: function(config) {
//      const skipXHRError = new Error("skip")
//      skipXHRError.isSkipXHR = true
//      skipXHRError.requestConfig = config
//      return skipXHRError
//    },
//    getCachedResponse: function (config) {
//      let uniqueUrl = this.getUniqueUrl(config)
//      return caches[uniqueUrl]
//    }
//  }
//  // This should be the *last* request interceptor to add
//  AxiosInstance.interceptors.request.use(function (config) {

//     // to avoid careless bug, only the request that explicitly declares *canCache* parameter can use cache
//    if (config.canCache) {

//      if (cacheUtils.isCached(config)) {
//        let error = cacheUtils.getError(config)
//        throw error
//      }
//      if (cacheUtils.isPending(config)) {
//        return new Promise((resolve, reject) => {
//          let interval = setInterval(() => {
//            if(config.isFinished) {
//              clearInterval(interval)
//              let error = cacheUtils.getError(config)
//              reject(error)
//            }
//          }, 200)
//        });
//      } else {

//        // the head of cacheable requests queue, get the response by http request 
//        return config
//      }
//    } else {
//      return config
//    }
//  });

export default AxiosInstance;
