import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import "bootstrap/dist/css/bootstrap.css";
import { LoginUserEntries } from "../../entries";
import { useNavigate } from "react-router-dom";
import "../assets/css/custom.css";
import useAuth from "../../useAuth";
import { UserAAContext, useToastContext } from "../../contexts";
import { Menubar } from 'primereact/menubar';

const Header = () => {
  
  const { showToast } = useToastContext();

  const userAAContext = useContext(UserAAContext);
  const {userAccess} = userAAContext;

  const [loginUser, setLoginUser] = useState([]);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const items = [
    // { label: 'Home', icon: 'pi pi-fw pi-home', command: () => navigate('/') },
    { label: 'Files', icon: 'pi pi-fw pi-file', command: () => navigate('/files'), visible: userAccess.indexOf('files') > -1 },
    { label: 'Works', icon: 'pi pi-fw pi-briefcase', command: () => navigate('/works'), visible: userAccess.indexOf('works') > -1 },
    { label: 'Trips', icon: 'pi pi-fw pi-map-marker', command: () => navigate('/trips'), visible: userAccess.indexOf('trips') > -1 },
    { label: 'Movement', icon: 'pi pi-fw pi-th-large', command: () => navigate('/stockmovement'), visible: userAccess.indexOf('stocks') > -1 },
    { label: 'Loans', icon: 'pi pi-fw pi-money-bill', command: () => navigate('/loans'), visible: userAccess.indexOf('pays') > -1 },
  ];
  
  const singleItems = [
    {
      label: 'Single',
      icon: 'pi pi-fw pi-palette',
      items: [
        { label: 'Blocks', icon: 'pi pi-fw pi-desktop', command: () => navigate('/blocks'), visible: userAccess.indexOf('blocks') > -1 },
        { label: 'Employees', icon: 'pi pi-fw pi-user', command: () => navigate('/employees'), visible: userAccess.indexOf('employees') > -1 },
        { label: 'Machines', icon: 'pi pi-fw pi-car', command: () => navigate('/machines'), visible: userAccess.indexOf('machines') > -1 },
        { label: 'History', icon: 'pi pi-fw pi-clock', command: () => navigate('/history'), visible: userAccess.indexOf('history') > -1 },
        { separator: true, visible: userAccess.indexOf('users') > -1 },
        { label: 'Users', icon: 'pi pi-fw pi-users', command: () => navigate('/users'), visible: userAccess.indexOf('users') > -1 },
        { label: 'Positions', icon: 'pi pi-fw pi-briefcase', command: () => navigate('/positions'), visible: userAccess.indexOf('positions') > -1 },
      ],
      visible: userAccess.indexOf('blocks') > -1
    },
  ];
  
  const stockItems = [
    {
      label: 'Stocks',
      icon: 'pi pi-fw pi-chart-bar',
      items: [
        { label: 'Stocks', icon: 'pi pi-fw pi-chart-bar', command: () => navigate('/stocks'), visible: userAccess.indexOf('stocks') > -1 },
        { label: 'Stock Petrol', icon: 'pi pi-fw pi-circle-fill', command: () => navigate('/stockpetrol'), visible: userAccess.indexOf('stocks') > -1 },
        { label: 'Stock Repair', icon: 'pi pi-fw pi-wrench', command: () => navigate('/stockrepair'), visible: userAccess.indexOf('stocks') > -1 },
        { label: 'Stock Transfer', icon: 'pi pi-fw pi-sort-alt', command: () => navigate('/stocktransfer'), visible: userAccess.indexOf('stocks') > -1 },
        { separator: true, visible: userAccess.indexOf('stocks') > -1 },
        { label: 'Stock Category', icon: 'pi pi-fw pi-list', command: () => navigate('/stockcategory'), visible: userAccess.indexOf('stocks') > -1 },
        { label: 'Shop', icon: 'pi pi-fw pi-shopping-cart', command: () => navigate('/stockshop'), visible: userAccess.indexOf('stocks') > -1 },
      ],
      visible: userAccess.indexOf('stocks') > -1
    },
  ];
  
  const payItems = [
    {
      label: 'Pays',
      icon: 'pi pi-fw pi-id-card',
      items: [
        { label: 'Payroll', icon: 'pi pi-fw pi-chart-bar', command: () => navigate('/payroll'), visible: userAccess.indexOf('pays') > -1 },
        { label: 'Paybreak', icon: 'pi pi-fw pi-arrow-right', command: () => navigate('/paybreak'), visible: userAccess.indexOf('pays') > -1 },
        { label: 'Paybako', icon: 'pi pi-fw pi-apple', command: () => navigate('/paybako'), visible: userAccess.indexOf('pays') > -1 },
        { separator: true, visible: userAccess.indexOf('pays') > -1 },
        { label: 'Payscale', icon: 'pi pi-fw pi-cog', command: () => navigate('/payscale'), visible: userAccess.indexOf('pays') > -1 },
      ],
      visible: userAccess.indexOf('pays') > -1,
      className: 'hoverable-dropdown', // Add a class for styling
    },
  ];

  const endItems = [
    {
      label: `${loginUser && loginUser.name ? loginUser.name : ''}`,
      icon: 'pi pi-fw pi-user',
      items: [
        { label: 'Logout', icon: 'pi pi-fw pi-power-off', command: () => handleLogout() },
      ],
    },    
  ];

  const filteredItems = items.filter(item => item.visible === undefined || item.visible);

  useEffect(() => {
    async function fetchData() {

      // Login User Access Data
      const lue = await LoginUserEntries();
      setLoginUser(lue);

    }
    fetchData();
  }, []);

  const handleLogout = async () => {

    await AxiosInstance.put(
      "/users/logout"
    ).catch((error) => {
      let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      return false;
    });
    
    localStorage.removeItem("lue");
    logout();
    navigate("/login");

  };

  return (
    <div className="container bg-white content-container">
      <Menubar
        model={[
          ...filteredItems,
          ...singleItems,
          ...stockItems,
          ...payItems,
          ...endItems,
        ]}
        start={
          <div className="me-3">
            {window.location.hostname.includes('uat') ? (
              <span className="navbar-brand fw-bolder text-danger" onClick={() => {navigate('/');}} >
                <img alt="" src="/logo512.png" style={{ width: '40px' }} /> UAT
              </span>
            ) : (
              <span className="navbar-brand" onClick={() => {navigate('/');}} >
                <img alt="" src="/isday.png" style={{ width: '100px' }} />
              </span>
            )}
          </div>
        }
        className="bg-white border-0"
      />
    </div>
  );
};

export default Header;
