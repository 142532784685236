import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.css";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { FieldContext, PositionContext, UserContext } from "../../contexts";

const ViewEmployeeModal = (props) => {

  const userContext = useContext(UserContext);
  const {users} = userContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;

  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [employeeHistory, setEmployeeHistory] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setEmployeeHistory(props && props.he ? props.he : []);
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    setVisible(false);
  }

  return (
    <>
      <span onClick={handleShow} className="link-isday ms-3">log</span>

      <Dialog header="Log" visible={visible} style={{ width: "70vw" }} onHide={() => handleClose()}>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Changed</th>
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                        <th>By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        employeeHistory?.length > 0 && employeeHistory?.map((sh, i) => (
                          <tr key={i}>
                            <td>{new Date(sh.created_at).toLocaleTimeString()}</td>
                            <td>{sh.changed_column}</td>
                            <td>
                              {
                                sh.changed_column && sh.changed_column.toLowerCase() === "field"
                                  ? (
                                    fields?.length > 0 && fields?.find(i => i.id === parseInt(sh.changed_from)) ? fields?.find(i => i.id === parseInt(sh.changed_from))?.name : ""
                                  ) : (
                                    sh.changed_column && sh.changed_column.toLowerCase() === "position"
                                    ? (
                                      positions?.length > 0 && positions?.find(i => i.id === parseInt(sh.changed_from)) ? positions?.find(i => i.id === parseInt(sh.changed_from))?.name : ""
                                    ) : (
                                      sh.changed_from
                                        ? sh.changed_from
                                          : 0
                                    )
                                  )
                              }
                            </td>
                            <td>
                              {
                                sh.changed_column && sh.changed_column.toLowerCase() === "field"
                                  ? (
                                    fields?.length > 0 && fields?.find(i => i.id === parseInt(sh.changed_to)) ? fields?.find(i => i.id === parseInt(sh.changed_to))?.name : ""
                                  ) : (
                                    sh.changed_column && sh.changed_column.toLowerCase() === "position"
                                    ? (
                                      positions?.length > 0 && positions?.find(i => i.id === parseInt(sh.changed_to)) ? positions?.find(i => i.id === parseInt(sh.changed_to))?.name : ""
                                    ) : (
                                      sh.changed_to
                                        ? sh.changed_to
                                          : 0
                                    )
                                  )
                              }
                            </td>
                            <td>{users?.length > 0 && sh.changed_by && users?.find(i => i.id === parseInt(sh.changed_by)) ? users?.find(i => i.id === parseInt(sh.changed_by))?.name : ""}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ViewEmployeeModal;
