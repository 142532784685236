import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { StockContext, StockMovementSingleContext, useToastContext } from "../../contexts";

const DeleteStockMovementListModal = (props) => {
  
  const { showToast } = useToastContext();

  const stockMovementSingleContext = useContext(StockMovementSingleContext);
  const {stockMovementSingle} = stockMovementSingleContext;

  const stockContext = useContext(StockContext);
  const {stocks} = stockContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [smId, setSMId] = useState("");
  const [smStage, setSMStage] = useState("");

  const [smlid, setSmlid] = useState("");
  const [selectedStock, setSelectedStock] = useState("");

  useEffect(() => {
    async function fetchData() {
      setSmlid(props && props.sml && props.sml.id ? props.sml.id : "");
      setSelectedStock(props && props.sml && props.sml.stock_id ? props.sml.stock_id : "");
    }
    fetchData();
  }, [props]);
  
  useEffect(() => {
    async function fetchData() {
      setSMId(stockMovementSingle && stockMovementSingle.id ? stockMovementSingle.id : "");
      setSMStage(stockMovementSingle && stockMovementSingle.stage ? stockMovementSingle.stage : "");
    }
    fetchData();
  }, [stockMovementSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!smlid) {
      notif = "Please refresh the page.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("delsml", "yes");

      const callAPI = await AxiosInstance.post(`/stockmovementlist/${smlid}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Yes
      </Button>
      <Button variant="primary" disabled={disableButton} onClick={() => handleClose()}>
        No
      </Button>
    </div>
  );

  return (
    <>
      {
        smStage !== "approved" && smStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            del
          </span>
        )
      }

      <Dialog header="Delete" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="name">Remove {stocks && selectedStock && stocks?.find(i => i.id === parseInt(selectedStock)) && stocks?.find(i => i.id === parseInt(selectedStock))?.name}?</label>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteStockMovementListModal;
