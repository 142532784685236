import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { EmployeeContext, WorkSingleContext, useToastContext } from "../../contexts";

const AddWorkListAssistanceModal = (props) => {
  
  const { showToast } = useToastContext();

  const workSingleContext = useContext(WorkSingleContext);
  const {workSingle} = workSingleContext;

  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;

  const [disableButton, setDisableButton] = useState(false);
  const [disableGrossAmount, setDisableGrossAmount] = useState(true);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [workStage, setWorkStage] = useState("");
  const [workField, setWorkField] = useState("");

  const [selectedWSL, setSelectedWSL] = useState([]);

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [typeAmount, setTypeAmount] = useState(1);

  useEffect(() => {
    async function fetchData() {
      setSelectedWSL(props && props?.wsl ? props?.wsl : []);
    }
    fetchData();
  }, [props]);
  
  useEffect(() => {
    async function fetchData() {
      if ( workSingle && workSingle.id ) {
        setWorkStage(workSingle?.stage);
        setWorkField(workSingle?.field_id);
        if ( workSingle?.work_type && workSingle?.work_type === "workforperawatan" ) {
          setDisableGrossAmount(false);
        }
      }
    }
    fetchData();
  }, [workSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedWSL && !selectedWSL.id) {
      notif = "Please refresh the page.";
    } else if (!selectedEmployee) {
      notif = "Employee must not be empty.";
    } else if (!typeAmount) {
      notif = "Amount must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("work_list_id", selectedWSL.id);
      formData.append("employee_id", selectedEmployee);
      formData.append("contribution_amount", typeAmount);

      const addWork = await AxiosInstance.post("/worklistassistance", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addWork) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setSelectedEmployee("");
    setTypeAmount(1);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      {
        workStage !== "approved" && workStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            add
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="employee">Employee</label>
          <select
            id="employee"
            name="employee"
            className="form-control"
            onChange={(_) => {
              setSelectedEmployee(_.target.value);
            }}
            value={selectedEmployee}
            required
          >
            <option value="">Please select</option>
            {
              employees?.length > 0 && workField && employees?.filter(obj => parseInt(obj.field_id) === parseInt(workField))?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="amount">Contribution Amount</label>
          <input
            type="number"
            className="form-control"
            id="amount"
            name="amount"
            placeholder="Contribution Amount"
            onChange={(_) => {
              setTypeAmount(_.target.value.replace(/[^0-9-.]/gi, ""));
            }}
            value={typeAmount}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
            disabled={disableGrossAmount}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddWorkListAssistanceModal;
