import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { StockRepairContext, EmployeeContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const CompleteStockRepairModal = (props) => {
  
  const { showToast } = useToastContext();

  const stockRepairContext = useContext(StockRepairContext);

  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  
  const [srid, setSRid] = useState();
  const [smidto, setSMidto] = useState();
  
  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMinDate, setSelectedMinDate] = useState("");
  const [selectedMaxDate, setSelectedMaxDate] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedReceiveBy, setSelectedReceiveBy] = useState("");
  const [typeNotes, setTypeNotes] = useState("");

  const [employeesByField, setEmployeesByField] = useState([]);
  
  useEffect(() => {
    async function fetchData() {
      setSRid(props && props.sr && props.sr.id ? props.sr.id : "");
      setSelectedField(props && props?.sr && props?.sr?.stock_movement_list[0]?.stock_movement?.field_id ? props?.sr?.stock_movement_list[0]?.stock_movement?.field_id : "");
      setSMidto(props && props.sr && props.sr.stock_movement_id_to ? props.sr.stock_movement_id_to : "");
    }
    fetchData();
  }, [props]);
  
  useEffect(() => {
    async function fetchData() {
      setEmployeesByField(employees.filter(obj => { return parseInt(obj.field_id) === parseInt(selectedField); }));
    }
    fetchData();
  }, [selectedField]);

  useEffect(() => {
    async function fetchData() {
      setDate();
    }
    fetchData();
  }, []);

  const setDate = () => {
    let fileDate = new Date();
    let minTime = new Date(fileDate);
    let maxTime = new Date(fileDate);

    if (minTime) {
      minTime.setDate(fileDate.getDate() - 67);
      minTime.setHours(0, 0, 0, 0);
    }
    
    if (maxTime) {
      maxTime.setHours(23, 59, 59, 0);
    }

    setSelectedDate( fileDate );
    setSelectedMinDate( minTime );
    setSelectedMaxDate( maxTime );
  }

  const intDT = (dt, startTime = null, endTime = null) => {
    const date = new Date(dt);
    if (startTime) {
      date.setHours(0, 0, 0, 0);
    }
    if (endTime) {
      date.setHours(23, 59, 59, 0);
    }
    return Math.floor(date.getTime() / 1000);
  };

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedDate) {
      notif = "Date must not be empty.";
    } else if (!selectedField) {
      notif = "Field must not be empty.";
    } else if (!selectedReceiveBy) {
      notif = "Receive By must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("ondate", intDT(selectedDate, true));
      formData.append("field_id", selectedField);
      formData.append("employee_id", selectedReceiveBy);
      if (typeNotes) { formData.append("notes", typeNotes); }

      const callAPI = await AxiosInstance.post(`/stockrepair/verify/${srid}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        stockRepairContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    setDisableButton(false);
    setDate();
    setSelectedField("");
    setSelectedReceiveBy("");
    setTypeNotes("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Confirm
      </Button>
    </div>
  );

  return (
    <>
      {
        !smidto && (
          <span onClick={handleShow} className="link-isday">
            to complete
          </span>
        )
      }

      <Dialog header="Complete" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="name">Date</label>
          <Calendar
            id="enddate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedDate}
            minDate={selectedMinDate}
            maxDate={selectedMaxDate}
            onChange={(e) => setSelectedDate(e.value)}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="reby">Received By</label>
          <select
            id="reby"
            name="reby"
            className="form-control"
            onChange={(_) => {
              setSelectedReceiveBy(_.target.value);
            }}
            value={selectedReceiveBy}
            required
            disabled={ selectedField ? false : true }
          >
            <option value="">Please select</option>
            {
              employeesByField?.length > 0 && employeesByField?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setTypeNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default CompleteStockRepairModal;
