import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { StockContext, WorkSingleContext, useToastContext } from "../../contexts";

const AddWorkListStockModal = (props) => {
  
  const { showToast } = useToastContext();

  const workSingleContext = useContext(WorkSingleContext);
  const {workSingle} = workSingleContext;

  const stockContext = useContext(StockContext);
  const {stocks} = stockContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [workStage, setWorkStage] = useState("");

  const [selectedWSL, setSelectedWSL] = useState([]);

  const [selectedStock, setSelectedStock] = useState("");
  const [typeQty, setTypeQty] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setSelectedWSL(props && props.wsl ? props.wsl : []);
    }
    fetchData();
  }, [props]);
  
  useEffect(() => {
    async function fetchData() {
      if ( workSingle && workSingle.id ) {
        setWorkStage(workSingle?.stage);
      }
    }
    fetchData();
  }, [workSingle]);

  const valueTemplate = (stock, props) => {
    if (stock) {
      return (
        <div className="flex align-items-center">
            <div>{stock.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemTemplate = (stock) => {
    return (
      <div className="flex align-items-center">
          <div>{stock.name}</div>
      </div>
    );
  };

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedWSL && !selectedWSL.id) {
      notif = "Please refresh the page.";
    } else if (!selectedStock) {
      notif = "Stock must not be empty.";
    } else if (!typeQty) {
      notif = "Amount must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("work_list_id", selectedWSL.id);
      formData.append("stock_id", selectedStock.id);
      formData.append("qty", typeQty);

      const addWork = await AxiosInstance.post("/workliststock", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addWork) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setSelectedStock("");
    setTypeQty(0);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      {
        workStage !== "approved" && workStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            add
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label>Stock</label>
          <Dropdown
            className="form-control"
            value={selectedStock}
            options={stocks}
            onChange={(_) => {
              let ss = _.target.value;
              setSelectedStock(ss);
            }}
            optionLabel="name"
            filter
            filterBy="name"
            placeholder="Search by Stock"
            valueTemplate={valueTemplate} 
            itemTemplate={itemTemplate}
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="qty">Qty</label>
          <input
            type="number"
            className={"form-control " + (typeQty < 0 ? "border-danger" : "") }
            id="qty"
            name="qty"
            placeholder="Qty"
            onChange={(_) => {
              setTypeQty(_.target.value.replace(/[^0-9-.]/gi, ""));
            }}
            value={typeQty}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddWorkListStockModal;
