import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { EmployeeContext, PositionContext, FieldContext, useToastContext } from "../../contexts";

const EditEmployeeModal = (props) => {
  
  const { showToast } = useToastContext();

  const employeeContext = useContext(EmployeeContext);

  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [employeeId, setEmployeeId] = useState("");
  const [typeName, setTypeName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedField, setSelectedField] = useState("");
  
  const {fields} = useContext(FieldContext);
  const {positions} = useContext(PositionContext);
  
  useEffect(() => {
    async function fetchData() {
      setEmployeeId(props && props.ee && props.ee.id ? props.ee.id : "");
      setTypeName(props && props.ee && props.ee.name ? props.ee.name : "");
      setSelectedPosition(props && props.ee && props.ee.position_id ? props.ee.position_id : "");
      setSelectedField(props && props.ee && props.ee.field_id ? props.ee.field_id : "");
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    employeeContext.refreshData();
    setDisableButton(false);
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeName) {
      notif = "Name must not be empty.";
    } else if (!selectedPosition) {
      notif = "Position must not be empty.";
    } else if (!selectedField) {
      notif = "Field must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {

      let formData = new FormData();
      formData.append("name", typeName);
      formData.append("position_id", selectedPosition);
      formData.append("field_id", selectedField);

      const callAPI = await AxiosInstance.post(`/employees/${employeeId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        edit
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              onChange={(_) => {
                setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeName}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="field">Field</label>
            <select
              id="field"
              name="field"
              className="form-control"
              onChange={(_) => {
                setSelectedField(_.target.value);
              }}
              value={selectedField}
              required
            >
              <option value="">Please select</option>
              {
                fields?.length > 0 && fields?.map((e, i) => (
                  <option key={e.id} value={e.id}>{e.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="position">Position</label>
            <select
              id="position"
              name="position"
              className="form-control"
              onChange={(_) => {
                setSelectedPosition(_.target.value);
              }}
              value={selectedPosition}
              required
            >
              <option value="">Please select</option>
              {
                positions?.length > 0 && positions?.map((e, i) => (
                  <option key={e.id} value={e.id}>{e.name}</option>
                ))
              }
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditEmployeeModal;
