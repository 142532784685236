import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MachineContext, FieldContext, StockCategoryContext } from "../contexts";
import { AddMachineModal, EditMachineModal, ViewMachineModal } from "./modals";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";

const Machines = () => {

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const stockCategoryContext = useContext(StockCategoryContext);
  const {stockCategory} = stockCategoryContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  const [filters, setFilters] = useState({
    field_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    stock_category_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(stockCategory?.length > 0) && refreshCall) { stockCategoryContext.refreshData(); }
      if (!(machines?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [fields, stockCategory, machines, fieldContext, stockCategoryContext, machineContext, refreshCall]);

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await machineContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const fieldFilter = () => {
    return (
      <Dropdown
        value={fields?.find(i => i.id === parseInt(filters.field_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.field_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={fields}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const fieldBody = (m) => {
    return fields?.length > 0 && m.field_id ? fields?.find(i => i.id === parseInt(m.field_id))?.name : "";
  };

  const stockCategoryFilter = () => {
    return (
      <Dropdown
        value={stockCategory?.find(i => i.id === parseInt(filters.stock_category_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.stock_category_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={stockCategory}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Stock Category"
        className="w-full md:w-14rem"
      />
    );
  }

  const stockCategoryBody = (m) => {
    return stockCategory?.length > 0 && m.stock_category_id ? stockCategory?.find(i => i.id === parseInt(m.stock_category_id))?.name : "";
  };

  const editViewTemplate = (m) => {
    return <>
      <EditMachineModal ve={m} /><ViewMachineModal em={m.expenses ? m.expenses : []} />
    </>
    ;
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Machine</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddMachineModal />
                          </div>
                        </div>
                        <hr />
                        <DataTable value={machines} stripedRows removableSort paginator rows={10} filters={filters} filterDisplay="row" dataKey="id" emptyMessage="No employee found.">
                          <Column
                            field="field_id"
                            header="Fields"
                            sortable
                            filter
                            filterPlaceholder="Search by Field"
                            filterElement={fieldFilter}
                            style={{ minWidth: "12rem" }} 
                            body={fieldBody}
                            showFilterMenu={false}
                          />
                          <Column
                            field="name"
                            header="Name"
                            sortable
                            filter
                            filterPlaceholder="Search by Name"
                            showFilterMenu={false}
                            style={{ minWidth: "12rem" }} 
                          />
                          <Column
                            field="stock_category_id"
                            header="Stock Category"
                            sortable
                            filter
                            filterPlaceholder="Search by Stock Category"
                            filterElement={stockCategoryFilter}
                            style={{ minWidth: "12rem" }} 
                            body={stockCategoryBody}
                            showFilterMenu={false}
                          />  
                          <Column
                            field="notes"
                            header="Notes"
                            sortable
                            filter
                            filterPlaceholder="Search by Notes"
                            showFilterMenu={false}
                            style={{ minWidth: "12rem" }} 
                          />      
                          <Column
                            body={editViewTemplate} style={{ width: "5rem" }}
                          />
                        </DataTable>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Machines;
