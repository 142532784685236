import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { FileContext, FieldContext, UserAAContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const AddFileModal = () => {
  
  const { showToast } = useToastContext();

  const fileContext = useContext(FileContext);
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const userAAContext = useContext(UserAAContext);
  const {userField} = userAAContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [selectedField, setSelectedField] = useState("");
  const [selectedFileDate, setSelectedFileDate] = useState("");
  const [typeNotes, setTypeNotes] = useState("");
  const [selectedMinDate, setSelectedMinDate] = useState("");
  const [selectedMaxDate, setSelectedMaxDate] = useState("");

  useEffect(() => {
    async function fetchData() {
      setDate();
    }
    fetchData();
  }, []);

  const setDate = () => {
    let fileDate = new Date();
    let minTime = new Date(fileDate);
    let maxTime = new Date(fileDate);

    if (minTime) {
      minTime.setDate(fileDate.getDate() - 7);
      minTime.setHours(0, 0, 0, 0);
    }
    
    if (maxTime) {
      maxTime.setHours(23, 59, 59, 0);
    }

    setSelectedFileDate( fileDate );
    setSelectedMinDate( minTime );
    setSelectedMaxDate( maxTime );
  }

  function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedField) {
      notif = "Field must not be empty.";
    } else if (!selectedFileDate) {
      notif = "Date must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){

      let formData = new FormData();
      formData.append("field_id", selectedField);
      formData.append("file_date", formatDateToISO(selectedFileDate));
      if (typeNotes) { formData.append("notes", typeNotes); }

      const addFile = await AxiosInstance.post("/file", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addFile) {
        fileContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    setDisableButton(false);
    setSelectedField("");
    setDate();
    setTypeNotes("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()} className="mt-4">
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span className="link-isday" onClick={handleShow}>
        add new
      </span>

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mt-4 mb-2">
          <label htmlFor="field">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setSelectedField(_.target.value);
            }}
            value={selectedField}
            required
          >
            <option value="">Please select</option>
            {
              fields?.length > 0 && (userField
                ? fields?.filter(obj => obj.id === parseInt(userField))?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                : fields?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
              )
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="inputDate">Date</label>
          <Calendar
            id="inputDate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={selectedFileDate}
            onChange={(e) => setSelectedFileDate(e.value)}
            minDate={selectedMinDate}
            maxDate={selectedMaxDate}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setTypeNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddFileModal;
