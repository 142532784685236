import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { UserContext, PositionContext, FieldContext, useToastContext } from "../../contexts";

const EditUserModal = (props) => {
  
  const { showToast } = useToastContext();

  const userContext = useContext(UserContext);

  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);

  const [editUserSection, setEditUserSection] = useState(true);
  const [userId, setUserId] = useState("");
  const [typeName, setTypeName] = useState("");
  const [typeUsername, setTypeUsername] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [cNewPwd, setCNewPwd] = useState("");
  
  const {positions} = useContext(PositionContext);
  const {fields} = useContext(FieldContext);

  useEffect(() => {
    async function fetchData() {
      setUserId(props && props.ue && props.ue.id ? props.ue.id : "");
      setTypeName(props && props.ue && props.ue.name ? props.ue.name : "");
      setTypeUsername(props && props.ue && props.ue.username ? props.ue.username : "");
      setSelectedPosition(props && props.ue && props.ue.position_id ? props.ue.position_id : "");
      setSelectedField(props && props.ue && props.ue.field_id ? props.ue.field_id : "");
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    userContext.refreshData();
    setDisableButton(false);
    setNewPwd("");
    setCNewPwd("");
    setShow(false);
  }

  const handleShow = (s) => {
    setEditUserSection(s);
    setShow(true);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validatePassword = false;
    let validateDetails = false;
    let notif = "";

    if (newPwd.trim() && cNewPwd.trim()) {
      if (!(newPwd.trim() === cNewPwd.trim())) {
        notif = "New Password do not match";
      } else {
        validatePassword = true;
      }
    } else if (newPwd.trim() || cNewPwd.trim()) {
      notif = "New Password and Confirm New Password do not match";
    } else {
      validatePassword = true;
    }

    if (!typeName) {
      notif = "Name must not be empty.";
    } else if (!selectedPosition) {
      notif = "Position must not be empty.";
    } else if (!typeUsername) {
      notif = "username must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails && validatePassword) {

      let formData = new FormData();
      newPwd.trim() && cNewPwd.trim() && formData.append("npwd", newPwd.trim());
      formData.append("name", typeName.trim());
      formData.append("position_id", selectedPosition);
      formData.append("username", typeUsername.trim());
      if(selectedField) {
        formData.append("field_id", selectedField);
      }

      const callAPI = await AxiosInstance.post(`/users/${userId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  return (
    <>
      <span onClick={() => handleShow(true)} className="link-isday me-4">
        edit
      </span>

      <span onClick={() => handleShow(false)} className="link-isday">
        change password
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>{editUserSection ? "Edit" : "Password"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            editUserSection && <>
              <div className="form-group mb-2">
                <label htmlFor="moredetails">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Name"
                  onChange={(_) => {
                    setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                  }}
                  value={typeName}
                  required
                  onKeyDown={e => (e.key === "Enter") && apiCall()}
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  placeholder="Username"
                  onChange={(_) => {
                    setTypeUsername(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                  }}
                  value={typeUsername}
                  required
                  onKeyDown={e => (e.key === "Enter") && apiCall()}
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="position">Position</label>
                <select
                  id="position"
                  name="position"
                  className="form-control"
                  onChange={(_) => {
                    setSelectedPosition(_.target.value);
                  }}
                  value={selectedPosition}
                  required
                >
                  <option value="">Please select</option>
                  {
                    positions?.length > 0 && positions?.map((p, i) => (
                      <option key={p.id} value={p.id}>{p.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="field">Field ( if office, leave it blank )</label>
                <select
                  id="field"
                  name="field"
                  className="form-control"
                  onChange={(_) => {
                    setSelectedField(_.target.value);
                  }}
                  value={selectedField}
                >
                  <option value="">Please select</option>
                  {
                    fields?.length > 0 && fields?.map((l, i) => (
                      <option key={l.id} value={l.id}>{l.name}</option>
                    ))
                  }
                </select>
              </div>
            </>
          }
          {
            !editUserSection && <>
              <div className="form-group mb-2">
                <label htmlFor="newPwd">New Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="newPwd"
                  name="newPwd"
                  placeholder="New Password"
                  onChange={(_) => {
                    setNewPwd(_.target.value);
                  }}
                  value={newPwd}
                  onKeyDown={e => (e.key === "Enter") && apiCall()}
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="cNewPwd">Confirm New Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="cNewPwd"
                  name="cNewPwd"
                  placeholder="Confirm New Password"
                  onChange={(_) => {
                    setCNewPwd(_.target.value);
                  }}
                  value={cNewPwd}
                  onKeyDown={e => (e.key === "Enter") && apiCall()}
                />
              </div>
            </>
          }
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditUserModal;
