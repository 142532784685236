import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AddEmployeeModal, EditEmployeeModal, DeleteEmployeeModal, EditEmployeeSalaryModal, ViewEmployeeModal } from "./modals";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { EmployeeContext, FieldContext, PositionContext, UserContext } from "../contexts";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';

const Employees = () => {

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const userContext = useContext(UserContext);
  const {users} = userContext;

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(positions?.length > 0) && refreshCall) { positionContext.refreshData(); }
      if (!(users?.length > 0) && refreshCall) { userContext.refreshData(); }
      if (!(employees?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [fields, positions, employees, users, fieldContext, positionContext, employeeContext, userContext, refreshCall]);

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await employeeContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const [filterTypeName, setFilterTypeName] = useState("");
  const [filters, setFilters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    position_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    field_id: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  const nameFilter = (options) => {
    return (
      <InputText
        onInput={(e) => {
          const newFilters = { ...filters };
          newFilters.name.value = e.target.value;
          setFilters(newFilters);
          setFilterTypeName(e.target.value);
        }}
        value={filterTypeName}
        placeholder="Search by Name"
      />
    );
  }

  const fieldFilter = (options) => {
    return (
      <Dropdown
        value={fields?.find(i => i.id === parseInt(filters.field_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.field_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={fields}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const fieldBody = (e) => {
    return fields?.length > 0 && e.field_id ? fields?.find(i => i.id === parseInt(e.field_id))?.name : "";
  };

  const positionFilter = (options) => {
    return (
      <Dropdown
        value={positions?.find(i => i.id === parseInt(filters.position_id.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.position_id.value = e.value.id;
          setFilters(newFilters);
        }}
        options={positions}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Position"
        className="w-full md:w-14rem"
      />
    );
  }

  const positionBody = (stock) => {
    return positions?.length > 0 && stock.position_id ? positions?.find(i => i.id === parseInt(stock.position_id))?.name : "";
  };

  const editViewBody = (e) => {
    return <>
      <EditEmployeeModal ee={e} /><DeleteEmployeeModal ee={e} /><EditEmployeeSalaryModal ee={e} /><ViewEmployeeModal he={e.history && JSON.parse(e.history) ? JSON.parse(e.history) : []} />
    </>
    ;
  };

  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'name', header: 'Name', visible: true },
    { field: 'position_id', header: 'Positions', visible: true },
    { field: 'join_date', header: 'Join Date', visible: true },
    { field: 'field_id', header: 'Fields', visible: true },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const [defaultVisibleColumns, setDefaultVisibleColumns] = useState([
    { field: 'name', header: 'Name', visible: true },
    { field: 'position_id', header: 'Positions', visible: true },
    { field: 'join_date', header: 'Join Date', visible: true },
    { field: 'field_id', header: 'Fields', visible: true },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Employee List</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="visibleColumn">Column</label>
                      <MultiSelect
                        id="visibleColumn"
                        value={visibleColumns.filter((col) => col.visible).map((col) => col.field)}
                        options={visibleColumns.map((col) => ({ label: col.header, value: col.field }))}
                        onChange={(e) => toggleColumnsVisibility(e.value)}
                        placeholder="Select Columns"
                      />
                    </div>
                  </div>
                </div>
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                setVisibleColumns(defaultVisibleColumns);
                              }}
                            >
                              reset column
                            </span>
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                const newFilters = { ...filters };
                                newFilters.name.value = null;
                                newFilters.field_id.value = null;
                                newFilters.position_id.value = null;
                                setFilters(newFilters);
                                setFilterTypeName("");
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddEmployeeModal />
                          </div>
                        </div>
                        <hr />
                        <DataTable
                          value={employees}
                          stripedRows
                          removableSort
                          paginator
                          rows={10}
                          columnResizeMode="expand"
                          resizableColumns
                          filters={filters}
                          filterDisplay="row"
                          filterClearIcon={null}
                          dataKey="id"
                          emptyMessage="No employee found."
                        >
                          {
                            visibleColumns.map((col) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                filter={col.field === 'name' || col.field === 'field_id' || col.field === 'position_id'}
                                filterElement={(rowData) => (col.field === 'field_id' ? fieldFilter(rowData) : (col.field === 'position_id' ? positionFilter(rowData) : (col.field === 'name' ? nameFilter(rowData) : undefined)))}
                                body={(rowData) => (col.field === 'field_id' ? fieldBody(rowData) : (col.field === 'position_id' ? positionBody(rowData) : (col.field === 'edit_view' ? editViewBody(rowData) : rowData[col.field])))}
                                style={{
                                  display: col.visible ? 'table-cell' : 'none'
                                }}
                                sortable={col.field !== 'edit_view'}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employees;
