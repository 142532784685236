import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { HistoryContext, AllContext, FieldContext, StockContext, UserContext } from "../contexts";

// https://primereact.org/datatable/
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Paginator } from "primereact/paginator";

const History = () => {

  const navigate = useNavigate();

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const stockContext = useContext(StockContext);
  const {stocks} = stockContext;
  const userContext = useContext(UserContext);
  const {users} = userContext;
  const historyContext = useContext(HistoryContext);
  const {history, conditions} = historyContext;

  const [selectedPage, setSelectedPage] = useState(conditions && conditions["page"] !== undefined && conditions["page"] !== "" ? conditions["page"] : 1);
  const [startFromRecord, setStartFromRecord] = useState(0);
  const [rowsToFetch, setRowsToFetch] = useState(conditions && conditions["limit"] !== undefined && conditions["limit"] !== "" ? conditions["limit"] : 10);
  const [sortField, setSortField] = useState(conditions && conditions["sort_by"] !== undefined && conditions["sort_by"] !== "" ? conditions["sort_by"] : "");
  const [sortOrder, setSortOrder] = useState(conditions && conditions["sort_order"] !== undefined && conditions["sort_order"] !== "" ? conditions["sort_order"] : "");
  const [selectedField, setSelectedField] = useState(conditions && conditions["field"] !== undefined && conditions["field"] !== "" ? conditions["field"] : "");
  const [selectedStatus, setSelectedStatus] = useState(conditions && conditions["status"] !== undefined && conditions["status"] !== "" ? conditions["status"] : "");

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  const [filters, setFilters] = useState({
    table_name: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(stocks?.length > 0) && refreshCall) { stockContext.refreshData(); }
      if (!(users?.length > 0) && refreshCall) { userContext.refreshData(); }
      if (!(history?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [history, fields, users, stocks]);

  useEffect(() => {
    async function fetchData() {
      let cdtns = [];
      cdtns["page"] = selectedPage;
      cdtns["limit"] = rowsToFetch;
      cdtns["sort_by"] = sortField;
      cdtns["sort_order"] = sortOrder;
      cdtns["field_id"] = selectedField && selectedField.id ? selectedField.id : "";
      cdtns["status"] = selectedStatus && selectedStatus.slug ? selectedStatus.slug : "";
      historyContext.applyConditions(cdtns);
    }
    fetchData();
  }, [selectedPage, /*startFromRecord,*/ rowsToFetch, selectedField, selectedStatus, sortField, sortOrder]);

  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await historyContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const pageFilter = () => {
    return (
      <Dropdown
        value={allOption["typeOfHistory"]?.find(i => i.id === parseInt(filters.table_name.value))}
        onChange={(e) => {
          const newFilters = { ...filters };
          newFilters.table_name.value = e.value.id;
          setFilters(newFilters);
        }}
        options={allOption["typeOfHistory"]}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="name"
        placeholder="Search by Page"
        className="w-full md:w-14rem"
      />
    );
  }

  const recordFrom = (text) => {
    const keyValuePairs = text.split(';');
    const resultObject = {};
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split(',');
      resultObject[key] = value;
    });

    return resultObject;
  };

  const pageBody = (h) => {
    let cc = recordFrom(h.changed_column);
    let tname = cc["w"] ? "historywork" : (cc["sm"] ? "historystockmovement" : h?.table_name);
    let tid = tname === h?.table_name ? h?.table_id : (cc["sm"] ? cc["sm"] : (cc["w"] ? cc["w"] : ""))
    let mainlink = "" ;

    switch (tname) {
      case "historystock":
        mainlink = `/stock/${tid}`;
        break;
      case "historystockmovement":
        mainlink = `/stockmovement/${tid}`;
        break;
      case "historytrip":
        mainlink = `/trip/${tid}`;
        break;
      case "historywork":
        mainlink = `/work/${tid}`;
        break;
    }
    return <span onClick={() => {navigate(mainlink)}} className="link-isday">{tname && allOption["typeOfHistory"]?.find(i => i.id === tname)?.name}</span>;
  };

  const columnBody = (h) => {
    let cc = recordFrom(h.changed_column);
    let tname = cc["w"] || cc["sm"]
      ?
        stocks?.length > 0 && (
          <>
            <span onClick={() => {navigate(`/stock/${h?.table_id}`)}} className="link-isday">
              {stocks?.find(i => i.id === parseInt(h?.table_id))?.name}
            </span>
            { cc["slq"] ? " - " + fields?.find(i => i.id === parseInt(cc["slq"]))?.name : "" }
          </>
        )
      : h.changed_column;
    return tname;
  };

  const userBody = (h) => {
    return users?.length > 0 && h.changed_by ? users?.find((user) => user.id === parseInt(h.changed_by))?.name : "";
  };

  const onPageChange = (event) => {
    setRowsToFetch(event.rows);
    setSelectedPage(parseInt(event.page) + 1);
    setStartFromRecord(event.first);
  };

  const updateSorting = (e) => {
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
  }

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>History</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <DataTable value={history?.data} stripedRows removableSort sortField={sortField} sortOrder={sortOrder} onSort={updateSorting}>
                          <Column
                            field="table_name"
                            header="Page"
                            // sortable
                            filter
                            filterPlaceholder="Search by Page"
                            filterElement={pageFilter}
                            body={pageBody}
                            showFilterMenu={false}
                          />
                          <Column
                            field="changed_column"
                            header="Changed"
                            // sortable
                            // filter
                            // filterPlaceholder="Search by Stock Category"
                            // filterElement={stockCategoryFilter}
                            body={columnBody}
                            showFilterMenu={false}
                          />
                          <Column
                            field="changed_from"
                            header="From"
                            // sortable
                            // filter
                            // filterPlaceholder="Search by Notes"
                            showFilterMenu={false}
                          />
                          <Column
                            field="changed_to"
                            header="To"
                            // sortable
                            // filter
                            // filterPlaceholder="Search by Notes"
                            showFilterMenu={false}
                          />
                          <Column
                            field="changed_by"
                            header="By"
                            // sortable
                            // filter
                            // filterPlaceholder="Search by Notes"
                            body={userBody}
                            showFilterMenu={false}
                          />
                        </DataTable>
                        <Paginator first={startFromRecord} rows={rowsToFetch} totalRecords={history && history.pagination ? history.pagination.total_records : 10 } rowsPerPageOptions={[5, 10, 25]} onPageChange={onPageChange} template={{ layout: "PrevPageLink PageLinks CurrentPageReport NextPageLink RowsPerPageDropdown" }} />
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
