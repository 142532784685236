import React, { useState, useContext, useEffect } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { WorkSingleContext, useToastContext } from "../../contexts";

const AddWorkImageModal = (props) => {
  
  const { showToast } = useToastContext();

  const workSingleContext = useContext(WorkSingleContext);
  const {workSingle} = workSingleContext;
  
  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);
  
  const [workId, setWorkId] = useState("");
  const [workStage, setWorkStage] = useState("");
  const [workImage, setWorkImage] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      if ( workSingle && workSingle.id ) {
        setWorkId(workSingle && workSingle.id ? workSingle.id : "");
        setWorkStage(workSingle && workSingle.stage ? workSingle.stage : "");
      }
    }
    fetchData();
  }, [workSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!workId) {
      notif = "Please refresh the page.";
    } else if (!workImage) {
      notif = "Image must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {
      let formData = new FormData();
      formData.append("work_id", workId);
      formData.append("fimage", workImage);

      const callAPI = await AxiosInstance.post("/workimage", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setWorkImage("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      { workStage !== "approved" && workStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            add new
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="category">Image</label>
        </div>
        <div className="form-group mb-2">
          <input
            type="file"
            onChange={(_) => {
              setWorkImage(_.target.files[0]);
            }} 
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddWorkImageModal;
