import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { PaybakoContext } from "../contexts";
import Button from "react-bootstrap/Button";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

const Paybako = () => {

  const paybakoContext = useContext(PaybakoContext);
  const {paybako, conditions} = paybakoContext;

  const [allowance, setAllowance] = useState(conditions && conditions["allowance"] !== undefined && conditions["allowance"] !== "" ? conditions["allowance"] : "");
  const [minOil, setMinOil] = useState(conditions && conditions["minoil"] !== undefined && conditions["minoil"] !== "" ? conditions["minoil"] : "");
  const [maxOil, setMaxOil] = useState(conditions && conditions["maxoil"] !== undefined && conditions["maxoil"] !== "" ? conditions["maxoil"] : "");
  const [minSugar, setMinSugar] = useState(conditions && conditions["minsugar"] !== undefined && conditions["minsugar"] !== "" ? conditions["minsugar"] : "");
  const [maxSugar, setMaxSugar] = useState(conditions && conditions["maxsugar"] !== undefined && conditions["maxsugar"] !== "" ? conditions["maxsugar"] : "");
  const [minMilk, setMinMilk] = useState(conditions && conditions["minmilk"] !== undefined && conditions["minmilk"] !== "" ? conditions["minmilk"] : "");
  const [maxMilk, setMaxMilk] = useState(conditions && conditions["maxmilk"] !== undefined && conditions["maxmilk"] !== "" ? conditions["maxmilk"] : "");
  const [maxRice, setMaxRice] = useState(conditions && conditions["maxrice"] !== undefined && conditions["maxrice"] !== "" ? conditions["maxrice"] : "");

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [disableClearButton, setDisableClearButton] = useState(false);

  const clearCall = () => {
    setDisableClearButton(true);
    setAllowance("");
    setMinOil("");
    setMaxOil("");
    setMinSugar("");
    setMaxSugar("");
    setMinMilk("");
    setMaxMilk("");
    setMaxRice("");
    apiCall();
    setDisableClearButton(false);
  };

  const apiCall = async () => {

    setDisableSubmitButton(true);
    let validateDetails = false;
    let notif = "";

    if (!allowance) {
      notif = "Allowance must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {
      let cdtns = [];
      cdtns["allowance"] = allowance ? allowance : "";
      cdtns["minoil"] = minOil ? minOil : "";
      cdtns["maxoil"] = maxOil ? maxOil : "";
      cdtns["minsugar"] = minSugar ? minSugar : "";
      cdtns["maxsugar"] = maxSugar ? maxSugar : "";
      cdtns["minmilk"] = minMilk ? minMilk : "";
      cdtns["maxmilk"] = maxMilk ? maxMilk : "";
      cdtns["maxrice"] = maxRice ? maxRice : "";
      paybakoContext.applyConditions(cdtns);
    }
    setDisableSubmitButton(false);
  };

  const formatPriceWithCommas = (price) => {
    return price.toLocaleString(); // Convert number to a string with commas
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Paybako</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-9">
                    <div className="form-group mb-2">
                      <label htmlFor="allowance">Allowance</label>
                      <input
                        type="text"
                        className="form-control"
                        id="allowance"
                        name="allowance"
                        placeholder="allowance"
                        onChange={(_) => {
                          setAllowance(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                        }}
                        value={allowance}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label htmlFor="maxrice">Max Rice</label>
                      <input
                        type="text"
                        className="form-control"
                        id="maxrice"
                        name="maxrice"
                        placeholder="Max Rice"
                        onChange={(_) => {
                          setMaxRice(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                        }}
                        value={maxRice}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label htmlFor="allowance">Min Oil</label>
                      <input
                        type="text"
                        className="form-control"
                        id="allowance"
                        name="allowance"
                        placeholder="Min Oil"
                        onChange={(_) => {
                          setMinOil(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                        }}
                        value={minOil}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label htmlFor="allowance">Max Oil</label>
                      <input
                        type="text"
                        className="form-control"
                        id="allowance"
                        name="allowance"
                        placeholder="Max Oil"
                        onChange={(_) => {
                          setMaxOil(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                        }}
                        value={maxOil}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label htmlFor="allowance">Min Sugar</label>
                      <input
                        type="text"
                        className="form-control"
                        id="allowance"
                        name="allowance"
                        placeholder="Min Sugar"
                        onChange={(_) => {
                          setMinSugar(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                        }}
                        value={minSugar}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label htmlFor="allowance">Max Sugar</label>
                      <input
                        type="text"
                        className="form-control"
                        id="allowance"
                        name="allowance"
                        placeholder="Max Sugar"
                        onChange={(_) => {
                          setMaxSugar(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                        }}
                        value={maxSugar}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label htmlFor="allowance">Min Milk</label>
                      <input
                        type="text"
                        className="form-control"
                        id="allowance"
                        name="allowance"
                        placeholder="Min Milk"
                        onChange={(_) => {
                          setMinMilk(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                        }}
                        value={minMilk}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label htmlFor="allowance">Max Milk</label>
                      <input
                        type="text"
                        className="form-control"
                        id="allowance"
                        name="allowance"
                        placeholder="Max Milk"
                        onChange={(_) => {
                          setMaxMilk(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
                        }}
                        value={maxMilk}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label>&nbsp;</label>
                      <Button variant="primary" className="form-control" disabled={disableSubmitButton} onClick={() => apiCall()}>
                        Submit
                      </Button>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-2">
                      <label>&nbsp;</label>
                      <Button variant="secondary" className="form-control" disabled={disableClearButton} onClick={() => clearCall()}>
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
                <>
                  {
                    paybako && paybako.allowance ? (
                      <>
                        <br />
                        <br />
                        Allowance: {formatPriceWithCommas(paybako.allowance)}
                        <br />
                        <br />
                        <br />
                        Price for Oil: {formatPriceWithCommas(paybako.oilPrice)} | Max Oil: {paybako.maxOil}
                        <br />
                        <br />
                        Price for Sugar: {formatPriceWithCommas(paybako.sugarPrice)} | Max Sugar: {paybako.maxSugar}
                        <br />
                        <br />
                        Price for Milk: {formatPriceWithCommas(paybako.milkPrice)} | Max Milk: {paybako.maxMilk}
                        <br />
                        <br />
                        Price for Rice: {formatPriceWithCommas(paybako.ricePrice)} | Max Rice: No Limit
                        <br />
                        <br />
                        <br />
                        Number of Oil (L): {paybako.bestOil} | Total Price for Oil: {formatPriceWithCommas(paybako.oilTotalPrice)}
                        <br />
                        <br />
                        Number of Sugar (KG): {paybako.bestSugar} | Total Price for Sugar: {formatPriceWithCommas(paybako.sugarTotalPrice)}
                        <br />
                        <br />
                        Number of Milk (can): {paybako.bestMilk} | Total Price for Milk: {formatPriceWithCommas(paybako.milkTotalPrice)}
                        <br />
                        <br />
                        Number of Rice (KG): {paybako.bestRice} | Total Price for Rice: {formatPriceWithCommas(paybako.riceTotalPrice)}
                        <br />
                        <br />
                        <br />
                        Total Price for All: {formatPriceWithCommas(paybako.bestTotalSpend)}<br />
                      </>
                    ) : ""
                  }
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paybako;
