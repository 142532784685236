import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { StockContext, StockSingleContext, useToastContext } from "../../contexts";

const AddStockImageModal = (props) => {
  
  const { showToast } = useToastContext();

  const stockContext = useContext(StockContext);
  const stockSingleContext = useContext(StockSingleContext);
  const {stockSingle} = stockSingleContext;
  
  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);
  
  const [stockId, setStockId] = useState("");
  const [stockImage, setStockImage] = useState("");

  useEffect(() => {
    async function fetchData() {
      if ( stockSingle && stockSingle.id ) {
        setStockId(stockSingle.id)
      }
    }
    fetchData();
  }, [stockSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!stockId) {
      notif = "Please refresh the page.";
    } else if (!stockImage) {
      notif = "Image must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {
      let formData = new FormData();
      formData.append("stock_id", stockId);
      formData.append("fimage", stockImage);

      const callAPI = await AxiosInstance.post("/stockimage", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        stockContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setStockImage("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        add new
      </span>

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="category">Image</label>
        </div>
        <div className="form-group mb-2">
          <input
            type="file"
            onChange={(_) => {
              setStockImage(_.target.files[0]);
            }} 
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddStockImageModal;
