import React, { createContext, useState} from "react";
// import { useToastContext } from "../contexts";
import AxiosInstance from "../axios";

export const UserContext = createContext();

export const UserState = (props) => {
  const [users, setUsers] = useState([]);

  async function refreshData() {
    const response = await UsersEntries();
    setUsers(response);
  }

  return (
    <UserContext.Provider value={{users, refreshData}}>
      {props.children}
    </UserContext.Provider>
  )
}

const UsersEntries = async () => {
  
  // const { showToast } = useToastContext();
  let retVal = [];

  const getUsers = await AxiosInstance.get(
    `users`,
    {
      cache: {
        ignoreCache: true,
      },
    }
  ).catch((error) => {
    // let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
    // showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
    return false;
  });

  if (getUsers) {
    retVal = getUsers?.data;
  }

  return retVal;

}