import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { FieldContext, EmployeeContext, PositionContext, UserAAContext, useToastContext } from "../../contexts";

const DeleteEmployeeModal = (props) => {
  
  const { showToast } = useToastContext();

  const userAAContext = useContext(UserAAContext);
  const {userAbility} = userAAContext;
  const employeeContext = useContext(EmployeeContext);
  // const {employees} = employeeContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [employeeId, setEmployeeId] = useState("");
  const [typeName, setTypeName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedField, setSelectedField] = useState("");

  useEffect(() => {
    async function fetchData() {
      setEmployeeId(props && props.ee && props.ee.id ? props.ee.id : "");
      setTypeName(props && props.ee && props.ee.name ? props.ee.name : "");
      setSelectedPosition(props && props.ee && props.ee.position_id ? props.ee.position_id : "");
      setSelectedField(props && props.ee && props.ee.field_id ? props.ee.field_id : "");
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    employeeContext.refreshData();
    setDisableButton(false);
    setVisible(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!employeeId) {
      notif = "Please refresh the page.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      const callAPI = await AxiosInstance.post(`/employees/del/${employeeId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  return (
    <>
      { userAbility && userAbility.indexOf("addeditsalary") >= 0 && <span onClick={handleShow} className="link-isday ms-3">delete</span> }

      <Modal show={visible} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Remove</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="name">Remove {typeName} of {selectedPosition && positions && positions?.length > 0 && positions?.find(i => i.id === parseInt(selectedPosition)) && positions?.find(i => i.id === parseInt(selectedPosition))?.name} from {selectedField && fields && fields?.length > 0 && fields?.find(i => i.id === parseInt(selectedField)) && fields?.find(i => i.id === parseInt(selectedField))?.name}?
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Yes
          </Button>
          <Button variant="primary" disabled={disableButton} onClick={() => handleClose()}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteEmployeeModal;
