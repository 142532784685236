import React, { useState, createContext} from "react";

export const TripSingleContext = createContext();

export const TripSingleState = (props) => {
  const [tripSingle, setTripSingle] = useState([]);

  const addData = (abc) =>{
    setTripSingle(abc);
  }

  return (
    <TripSingleContext.Provider value={{tripSingle, addData}}>
      {props.children}
    </TripSingleContext.Provider>
  )
}