import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { AddTripModal } from "./modals";
import { AllContext, EmployeeContext, FieldContext, MachineContext, PositionContext, TripContext, UserAAContext } from "../contexts";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

// https://primereact.org/datatable/
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from "primereact/dropdown";

const Trips = () => {

  const navigate = useNavigate();
  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice, userField} = userAAContext;
  const tripContext = useContext(TripContext);
  const {trips, conditions} = tripContext;
  
  const [selectedPage, setSelectedPage] = useState(conditions && conditions["page"] !== undefined && conditions["page"] !== "" ? conditions["page"] : 1);
  const [startFromRecord, setStartFromRecord] = useState(0);
  const [rowsToFetch, setRowsToFetch] = useState(conditions && conditions["limit"] !== undefined && conditions["limit"] !== "" ? conditions["limit"] : 10);
  const [sortField, setSortField] = useState(conditions && conditions["sort_by"] !== undefined && conditions["sort_by"] !== "" ? conditions["sort_by"] : "");
  const [sortOrder, setSortOrder] = useState(conditions && conditions["sort_order"] !== undefined && conditions["sort_order"] !== "" ? conditions["sort_order"] : "");

  const [selectedFilterField, setSelectedFilterField] = useState(conditions && conditions["field_id"] !== undefined && conditions["field_id"] !== "" ? fields?.find(i => i.id === parseInt(conditions["field_id"])) : []);
  const [selectedFilterDestination, setSelectedFilterDestination] = useState(conditions && conditions["destination"] !== undefined && conditions["destination"] !== "" ? allOption["typeOfTripDestination"]?.find(i => i.id === conditions["destination"]) : []);
  const [selectedFilterMachine, setSelectedFilterMachine] = useState(conditions && conditions["machine_id"] !== undefined && conditions["machine_id"] !== "" ? machines?.find(i => i.slug === conditions["machine_id"]) : []);
  
  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);
  
  useEffect(() => {
    async function fetchData() {
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(employees?.length > 0) && refreshCall) { employeeContext.refreshData(); }
      if (!(machines?.length > 0) && refreshCall) { machineContext.refreshData(); }
      if (!(positions?.length > 0) && refreshCall) { positionContext.refreshData(); }
      if (!(trips?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [trips, fields, tripContext, fieldContext, refreshCall]);

  useEffect(() => {
    async function fetchData() {
      let cdtns = [];
      cdtns["page"] = selectedPage;
      cdtns["limit"] = rowsToFetch;
      cdtns["sort_by"] = sortField;
      cdtns["sort_order"] = sortOrder;
      cdtns["field_id"] = selectedFilterField && selectedFilterField.id ? selectedFilterField.id : "";
      cdtns["destination"] = selectedFilterDestination && selectedFilterDestination.id ? selectedFilterDestination.id : "";
      cdtns["machine_id"] = selectedFilterMachine && selectedFilterMachine.id ? selectedFilterMachine.id : "";
      tripContext.applyConditions(cdtns);
    }
    fetchData();
  }, [selectedPage, rowsToFetch, sortField, sortOrder, selectedFilterField, selectedFilterDestination, selectedFilterMachine]);

  useEffect(() => {
    async function fetchData() {
      userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
    }
    fetchData();
  }, [userDevice]);

  // Columns
  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: true },
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'trip_date', header: 'Date', visible: true },
    { field: 'destination', header: 'Destination', visible: true },
    { field: 'machine_id', header: 'Machine', visible: true },
    { field: 'gross_weight', header: 'G.Weight', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: true },
    { field: 'field_id', header: 'Field', visible: true },
    { field: 'trip_date', header: 'Date', visible: true },
    { field: 'destination', header: 'Destination', visible: true },
    { field: 'machine_id', header: 'Machine', visible: true },
    { field: 'gross_weight', header: 'G.Weight', visible: true },
    { field: 'edit_view', header: '', visible: true },
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'stage', header: 'Stage', visible: false },
    { field: 'field_id', header: 'Field', visible: false },
    { field: 'trip_date', header: 'Date', visible: true },
    { field: 'destination', header: 'Destination', visible: true },
    { field: 'machine_id', header: 'Machine', visible: true },
    { field: 'gross_weight', header: 'G.Weight', visible: true },
    { field: 'edit_view', header: '', visible: false },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  // Filters
  // for offline only
  // const [filters, setFilters] = useState({
  //   field_id: { value: null, matchMode: FilterMatchMode.EQUALS },
  //   notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
  // });

  const fieldFilter = () => {
    return (
      <Dropdown
        value={selectedFilterField}
        onChange={(e) => setSelectedFilterField(e.value)}
        options={( userField? fields?.filter(obj => obj.id === parseInt(userField)): fields )}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="Fields"
        placeholder="Search by Field"
        className="w-full md:w-14rem"
      />
    );
  }

  const destinationFilter = () => {
    return (
      <Dropdown
        value={selectedFilterDestination}
        onChange={(e) => setSelectedFilterDestination(e.value)}
        options={allOption["typeOfTripDestination"]}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="Destination"
        placeholder="Search by Destination"
        className="w-full md:w-14rem" />
    );
  }

  const machineFilter = () => {
    return (
      <Dropdown
        value={selectedFilterMachine}
        onChange={(e) => setSelectedFilterMachine(e.value)}
        options={machines}
        valueTemplate={dropdownTemplateValue}
        itemTemplate={dropdownItemValue}
        optionLabel="Machine"
        placeholder="Search by Machine"
        className="w-full md:w-14rem" />
    );
  }

  // Pagination
  const updateSorting = (e) => {
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
  }

  const onPageChange = (event) => {
    setRowsToFetch(event.rows);
    setSelectedPage(parseInt(event.page) + 1);
    setStartFromRecord(event.first);
  };

  // Data
  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await tripContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }
  
  // Template
  const dropdownItemValue = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.name}</div>
        </div>
    );
  };

  const dropdownTemplateValue = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };

  const stageTemplate = (trip) => {
    return trip.stage ? 
              trip.stage === "approved" || trip.stage === "approvedbysystem"
                ? <i className="pi pi-check" style={{ fontSize: "1rem", color: "green" }} />
                  : trip.stage === "verified" || trip.stage === "verifiedbysystem"
                    ? <i className="pi pi-eye" style={{ fontSize: "1rem", color: "orange" }} />
                      : trip.stage === "submitted"
                        ? <i className="pi pi-eye-slash" style={{ fontSize: "1rem", color: "red" }} />
                          : <i className="pi pi-clock" style={{ fontSize: "1rem", color: "grey" }} />
            : ""
  };

  const fieldTemplate = (trip) => {
    return fields?.length > 0 && trip.field_id ? fields?.find(i => i.id === parseInt(trip.field_id))?.name : "";
  };

  const destinationTemplate = (trip) => {
    return trip.destination ? allOption["typeOfTripDestination"]?.find(i => i.id === trip.destination)?.name : "";
  };

  const machineTemplate = (trip) => {
    return machines?.length > 0 && trip.machine_id ? machines?.find(i => i.id === parseInt(trip.machine_id))?.name : "";
  };

  const gweightTemplate = (trip) => {
    return trip && trip?.trip_payload && trip?.trip_payload?.gross_weight? trip?.trip_payload?.gross_weight : "" ;
  };

  const editViewTemplate = (trip) => {
    return <>
      <span onClick={() => {navigate(`/trip/${trip.id}`);}} className="link-isday ms-3">view</span>
    </>
    ;
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Trips</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="visibleColumn">Column</label>
                      <MultiSelect
                        id="visibleColumn"
                        className="w-100"
                        value={visibleColumns.filter((col) => col.visible).map((col) => col.field)}
                        options={visibleColumns.map((col) => ({ label: col.header, value: col.field }))}
                        onChange={(e) => toggleColumnsVisibility(e.value)}
                        placeholder="Select Columns"
                      />
                    </div>
                  </div>
                </div>
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
                              }}
                            >
                              reset column
                            </span>
                            <span
                              className="me-3 link-isday"
                              onClick={() => {
                                setSelectedFilterField("");
                                setSelectedFilterDestination("");
                                setSelectedFilterMachine("");
                              }}
                            >
                              clear filter
                            </span>
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                            <AddTripModal />
                          </div>
                        </div>
                        <hr />
                        <DataTable
                          value={trips?.data}
                          stripedRows
                          removableSort
                          rows={10}
                          columnResizeMode="expand"
                          resizableColumns
                          // filters={filters}
                          filterDisplay="row"
                          filterClearIcon={null}
                          dataKey="id"
                          emptyMessage="No trips found."
                          sortField={sortField}
                          sortOrder={sortOrder}
                          onSort={updateSorting}
                        >
                          {
                            visibleColumns.map((col) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                filter={col.field === 'field_id' || col.field === 'destination' || col.field === 'machine_id'}
                                filterElement={(rowData) => (col.field === 'field_id' ? fieldFilter(rowData) : (col.field === 'destination' ? destinationFilter(rowData) : (col.field === 'machine_id' ? machineFilter(rowData) : undefined)))}
                                body={(rowData) => (col.field === 'stage' ? stageTemplate(rowData) : (col.field === 'field_id' ? fieldTemplate(rowData) : (col.field === 'destination' ? destinationTemplate(rowData) : (col.field === 'machine_id' ? machineTemplate(rowData) : (col.field === 'gross_weight' ? gweightTemplate(rowData) : (col.field === 'edit_view' ? editViewTemplate(rowData) : rowData[col.field]))))))}
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                                sortable={col.field !== 'stage' && col.field !== 'edit_view'}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                        <Paginator first={startFromRecord} rows={rowsToFetch} totalRecords={trips && trips?.pagination ? trips?.pagination.total_records : 10 } rowsPerPageOptions={[5, 10]} onPageChange={onPageChange} template={{ layout: "PrevPageLink PageLinks CurrentPageReport NextPageLink RowsPerPageDropdown" }} />
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trips;
