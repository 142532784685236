import React, { useContext, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { StockCategoryContext, StockContext, StockShopContext, useToastContext } from "../../contexts";

const AddStockModal = () => {
  
  const { showToast } = useToastContext();

  const stockCategoryContext = useContext(StockCategoryContext);
  const {stockCategory} = stockCategoryContext;
  const stockShopContext = useContext(StockShopContext);
  const {stockShop} = stockShopContext;
  const stockContext = useContext(StockContext);

  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  
  const [stockImages, setStockImages] = useState("");
  const [typeName, setTypeName] = useState("");
  const [typeKeyword, setTypeKeyword] = useState("");
  const [selectedStockCategory, setSelectedStockCategory] = useState("");
  const [selectedStockShop, setSelectedStockShop] = useState("");

  const handleClose = () => {
    setDisableButton(false);
    setTypeName("");
    setTypeKeyword("");
    setSelectedStockCategory("");
    setSelectedStockShop("");
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeName.trim()) {
      notif = "Name must not be empty.";
    } else if (!selectedStockCategory) {
      notif = "Category must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      if (stockImages) { formData.append("fimage", stockImages); }
      formData.append("name", typeName.trim());
      formData.append("stock_category_id", selectedStockCategory);
      if (typeKeyword.trim()) { formData.append("keyword", typeKeyword.trim()); }
      if (selectedStockShop) { formData.append("stock_shop_id", selectedStockShop); }

      const callAPI = await AxiosInstance.post("/stocks", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        stockContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        add new
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="category">Images</label>
          </div>
          <div className="form-group mb-2">
            <input
              type="file"
              onChange={(_) => {
                setStockImages(_.target.files[0]);
              }} 
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              onChange={(_) => {
                setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeName}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="category">Stock Category</label>
            <select
              id="category"
              name="category"
              className="form-control"
              onChange={(_) => {
                setSelectedStockCategory(_.target.value);
              }}
              value={selectedStockCategory}
              required
            >
              <option value="">Please select</option>
              {
                stockCategory?.length > 0 && stockCategory?.map((e, i) => (
                  <option key={e.id} value={e.id}>{e.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="keyword">Keyword (comma separated)</label>
            <textarea
              id="keyword"
              name="keyword"
              placeholder="Keyword"
              rows="5"
              className="form-control"
              onChange={(_) => {
                setTypeKeyword(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeKeyword}
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="shop">Shop</label>
            <select
              id="shop"
              name="shop"
              className="form-control"
              onChange={(_) => {
                setSelectedStockShop(_.target.value);
              }}
              value={selectedStockShop}
            >
              <option value="">Please select</option>
              {
                stockShop?.length > 0 && stockShop?.map((e, i) => (
                  <option key={e.id} value={e.id}>{e.name}</option>
                ))
              }
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddStockModal;
