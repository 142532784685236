import React, { useContext, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { UserContext, PositionContext, FieldContext, useToastContext } from "../../contexts";

const AddUserModal = () => {
  
  const { showToast } = useToastContext();

  const userContext = useContext(UserContext);

  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  
  const [typeName, setTypeName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [typeUsername, setTypeUsername] = useState("");
  const [typePassword, setTypePassword] = useState("");

  const {positions} = useContext(PositionContext);
  const {fields} = useContext(FieldContext);

  const handleClose = () => {
    setDisableButton(false);
    setTypeName("");
    setSelectedPosition("");
    setSelectedField("");
    setTypeUsername("");
    setTypePassword("");
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeName.trim()) {
      notif = "Name must not be be empty.";
    } else if (!selectedPosition) {
      notif = "Position must not be be empty.";
    } else if (!typeUsername.trim()) {
      notif = "Username must not be be empty.";
    } else if (!typePassword.trim()) {
      notif = "Password must not be be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("username", typeUsername.trim());
      formData.append("password", typePassword.trim());
      formData.append("name", typeName.trim());
      formData.append("position_id", selectedPosition);
      if(selectedField) {
        formData.append("field_id", selectedField);
      }

      const callAPI = await AxiosInstance.post("/users", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        userContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        add new
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              onChange={(_) => {
                setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeName}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              placeholder="Username"
              onChange={(_) => {
                setTypeUsername(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeUsername}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              placeholder="Password"
              onChange={(_) => {
                setTypePassword(_.target.value);
              }}
              value={typePassword}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="position">Position</label>
            <select
              id="position"
              name="position"
              className="form-control"
              onChange={(_) => {
                setSelectedPosition(_.target.value);
              }}
              value={selectedPosition}
              required
            >
              <option value="">Please select</option>
              {
                positions?.length > 0 && positions?.map((p, i) => (
                  <option key={p.id} value={p.id}>{p.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="field">Field ( if office, leave it blank )</label>
            <select
              id="field"
              name="field"
              className="form-control"
              onChange={(_) => {
                setSelectedField(_.target.value);
              }}
              value={selectedField}
            >
              <option value="">Please select</option>
              {
                fields?.length > 0 && fields?.map((l, i) => (
                  <option key={l.id} value={l.id}>{l.name}</option>
                ))
              }
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUserModal;
