import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { LoanSingleContext, FieldContext, UserAAContext, useToastContext } from "../../contexts";
import { Calendar } from "primereact/calendar";

const EditLoanModal = (props) => {
  
  const { showToast } = useToastContext();

  const loanSingleContext = useContext(LoanSingleContext);
  const {loanSingle} = loanSingleContext;
  
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const userAAContext = useContext(UserAAContext);
  const {userField} = userAAContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [loanId, setLoanId] = useState("");
  const [loanStage, setLoanStage] = useState("");
  const [loanField, setLoanField] = useState("");
  const [loanDate, setLoanDate] = useState("");
  const [loanNotes, setLoanNotes] = useState("");

  const [selectedMinDate, setSelectedMinDate] = useState("");
  const [selectedMaxDate, setSelectedMaxDate] = useState("");

  useEffect(() => {
    async function fetchData() {
      setDate();
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if ( loanSingle && loanSingle.id ) {
        setLoanId(loanSingle.id);
        setLoanStage(loanSingle.stage);
        setLoanField(loanSingle.field_id);
        setLoanDate(new Date(loanSingle.loan_date));
        setLoanNotes(loanSingle.notes);

      }
    }
    fetchData();
  }, [loanSingle]);

  const setDate = () => {
    let loanDate = new Date();
    let minTime = new Date(loanDate);
    let maxTime = new Date(loanDate);

    if (minTime) {
      minTime.setDate(loanDate.getDate() - 67);
      minTime.setHours(0, 0, 0, 0);
    }
    
    if (maxTime) {
      maxTime.setHours(23, 59, 59, 0);
    }

    setSelectedMinDate( minTime );
    setSelectedMaxDate( maxTime );
  }

  function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!loanField) {
      notif = "Field must not be empty.";
    } else if (!loanDate) {
      notif = "Date must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {

      let formData = new FormData();
      formData.append("field_id", loanField);
      formData.append("loan_date", formatDateToISO(loanDate));
      if (loanNotes) { formData.append("notes", loanNotes); }

      const callAPI = await AxiosInstance.post(`/loans/${loanId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      {
        loanStage !== "approved" && loanStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday me-3">
            edit
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mt-4 mb-2">
          <label htmlFor="field">Field</label>
          <select
            id="field"
            name="field"
            className="form-control"
            onChange={(_) => {
              setLoanField(_.target.value);
            }}
            value={loanField}
            required
          >
            <option value="">Please select</option>
            {
              fields?.length > 0 && (userField
                ? fields?.filter(obj => obj.id === parseInt(userField))?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                : fields?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
              )
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="inputDate">Date</label>
          <Calendar
            id="inputDate"
            className="w-100"
            dateFormat="dd/mm/yy"
            value={loanDate}
            onChange={(e) => setLoanDate(e.value)}
            minDate={selectedMinDate}
            maxDate={selectedMaxDate}
            required
            readOnlyInput
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setLoanNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={loanNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditLoanModal;
