import React, { useState, useContext, useEffect } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { MachineContext, FieldContext, StockCategoryContext, useToastContext } from "../../contexts";

const EditMachineModal = (props) => {
  
  const { showToast } = useToastContext();

  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const stockCategoryContext = useContext(StockCategoryContext);
  const {stockCategory} = stockCategoryContext;
  const machineContext = useContext(MachineContext);

  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [vid, setVid] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [typeName, setTypeName] = useState("");
  const [typeNotes, setTypeNotes] = useState("");

  useEffect(() => {
    async function fetchData() {
      setVid(props && props.ve && props.ve.id ? props.ve.id : "");
      setSelectedField(props && props.ve && props.ve.field_id ? props.ve.field_id : "");
      setSelectedCategory(props && props.ve && props.ve.stock_category_id ? props.ve.stock_category_id : "");
      setTypeName(props && props.ve && props.ve.name ? props.ve.name : "");
      setTypeNotes(props && props.ve && props.ve.notes ? props.ve.notes : "");
    }
    fetchData();
  }, [props]);

  const handleClose = () => {
    machineContext.refreshData();
    setDisableButton(false);
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!selectedField) {
      notif = "Field must not be empty.";
    } else if (!selectedCategory) {
      notif = "Category must not be empty.";
    } else if (!typeName.trim()) {
      notif = "Name must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("field_id", selectedField);
      formData.append("stock_category_id", selectedCategory);
      formData.append("name", typeName.trim());
      if (typeNotes && typeNotes.trim()) {
        formData.append("notes", typeNotes.trim());
      }

      const callAPI = await AxiosInstance.post(`/machines/${vid}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        edit
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="field">Field</label>
            <select
              id="field"
              name="field"
              className="form-control"
              onChange={(_) => {
                setSelectedField(_.target.value);
              }}
              value={selectedField}
              required
            >
              <option value="">Please select</option>
              {
                fields?.length > 0 && fields?.map((e, i) => (
                  <option key={e.id} value={e.id}>{e.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="category">Stock Category</label>
            <select
              id="category"
              name="category"
              className="form-control"
              onChange={(_) => {
                setSelectedCategory(_.target.value);
              }}
              value={selectedCategory}
              required
            >
              <option value="">Please select</option>
              {
                stockCategory?.length > 0 && stockCategory?.map((e, i) => (
                  <option key={e.id} value={e.id}>{e.name}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              onChange={(_) => {
                setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeName}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="notes">Notes</label>
            <input
              type="text"
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Notes"
              onChange={(_) => {
                setTypeNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeNotes}
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditMachineModal;
