import React, { useState, useContext } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, StockCategoryContext, useToastContext } from "../../contexts";

const AddStockCategoryModal = () => {
  
  const { showToast } = useToastContext();

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const stockCategoryContext = useContext(StockCategoryContext);
  
  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  
  const [typeName, setTypeName] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const handleClose = () => {
    setDisableButton(false);
    setTypeName("");
    setSelectedType("");
    setShow(false);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeName) {
      notif = "Name must not be empty.";
    } else if (!selectedType) {
      notif = "Type must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("name", typeName);
      formData.append("type", selectedType);

      const callAPI = await AxiosInstance.post("/stockcategory", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        stockCategoryContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        add new
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              onChange={(_) => {
                setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
              }}
              value={typeName}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="type">Stock Category</label>
            <select
              id="type"
              name="type"
              className="form-control"
              onChange={(_) => {
                setSelectedType(_.target.value);
              }}
              value={selectedType}
              required
            >
              <option value="">Please select</option>
              {
                allOption["typeOfStockCategory"]?.map((p, i) => (
                  <option key={p.id} value={p.id}>{p.name}</option>
                ))
              }
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddStockCategoryModal;
