import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { CompleteStockRepairModal } from "./modals";
import { StockRepairContext, StockShopContext, EmployeeContext, FieldContext, StockContext, UserAAContext } from "../contexts";
import Header from "./layout/Header";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

// https://primereact.org/datatable/
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { MultiSelect } from 'primereact/multiselect';

const StockRepair = () => {
  
  const navigate = useNavigate();
  
  const stockRepairContext = useContext(StockRepairContext);
  const {stockRepair, conditions} = stockRepairContext;

  const stockShopContext = useContext(StockShopContext);
  const {stockShop} = stockShopContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const stockContext = useContext(StockContext);
  const {stocks} = stockContext;
  const userAAContext = useContext(UserAAContext);
  const {userDevice, userField} = userAAContext;

  const [selectedPage, setSelectedPage] = useState(conditions && conditions["page"] !== undefined && conditions["page"] !== "" ? conditions["page"] : 1);
  const [startFromRecord, setStartFromRecord] = useState(0);
  const [rowsToFetch, setRowsToFetch] = useState(conditions && conditions["limit"] !== undefined && conditions["limit"] !== "" ? conditions["limit"] : 10);
  const [sortField, setSortField] = useState(conditions && conditions["sort_by"] !== undefined && conditions["sort_by"] !== "" ? conditions["sort_by"] : "");
  const [sortOrder, setSortOrder] = useState(conditions && conditions["sort_order"] !== undefined && conditions["sort_order"] !== "" ? conditions["sort_order"] : "");

  const [showLoading, setShowLoading] = useState(false);
  const [refreshCall, setRefreshCall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!(stockShop?.length > 0) && refreshCall) { stockShopContext.refreshData(); }
      if (!(employees?.length > 0) && refreshCall) { employeeContext.refreshData(); }
      if (!(fields?.length > 0) && refreshCall) { fieldContext.refreshData(); }
      if (!(stocks?.length > 0) && refreshCall) { stockContext.refreshData(); }
      if (!(stockRepair?.length > 0) && refreshCall) { refreshData(); }
      setRefreshCall(false);
    }
    fetchData();
  }, [stockRepair, stockShop, employees, fields, stocks, stockRepairContext, stockShopContext, employeeContext, fieldContext, stockContext, refreshCall]);

  useEffect(() => {
    async function fetchData() {
      let cdtns = [];
      cdtns["page"] = selectedPage;
      cdtns["limit"] = rowsToFetch;
      cdtns["sort_by"] = sortField;
      cdtns["sort_order"] = sortOrder;
      stockRepairContext.applyConditions(cdtns);
    }
    fetchData();
  }, [selectedPage, rowsToFetch, sortField, sortOrder]);

  useEffect(() => {
    async function fetchData() {
      userDevice && userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
    }
    fetchData();
  }, [userDevice]);
  
  // Columns
  const [visibleColumns, setVisibleColumns] = useState([
    { field: 'from', header: 'From', visible: true },
    { field: 'to', header: 'To', visible: true },
    { field: 'items', header: 'Items', visible: true },
  ]);

  const [desktopVisibleColumns, setDesktopVisibleColumns] = useState([
    { field: 'from', header: 'From', visible: true },
    { field: 'to', header: 'To', visible: true },
    { field: 'items', header: 'Items', visible: true },
  ]);

  const [mobileVisibleColumns, setMobileVisibleColumns] = useState([
    { field: 'from', header: 'From', visible: true },
    { field: 'to', header: 'To', visible: true },
    { field: 'items', header: 'Items', visible: true },
  ]);

  const toggleColumnsVisibility = (selectedColumns) => {
    const updatedColumns = visibleColumns.map((col) => ({
      ...col,
      visible: selectedColumns.includes(col.field),
    }));

    setVisibleColumns(updatedColumns);
  };

  // Filters
  // for offline only
  // const [filters, setFilters] = useState({
  //   field_id: { value: null, matchMode: FilterMatchMode.EQUALS },
  //   notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
  // });

  // Pagination
  const updateSorting = (e) => {
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
  }

  const onPageChange = (event) => {
    setRowsToFetch(event.rows);
    setSelectedPage(parseInt(event.page) + 1);
    setStartFromRecord(event.first);
  };

  // Data
  const refreshData = () => {
    new Promise(function(resolve, reject) {
      setShowLoading(true);
      resolve(1);
    }).then(async function(result) {
      await stockRepairContext.refreshData();
      return 1;
    }).finally(function(result) {
      setShowLoading(false);
    });
  }

  // Template
  const dateTemplate = (dt) => {
    const date = new Date(dt * 1000);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };
  
  const stockTemplate = (id) => {
    return stocks?.length > 0 ? stocks?.find(i => i.id === parseInt(id))?.name : "";
  };
  
  const shopTemplate = (id) => {
    return stockShop?.length > 0 ? stockShop?.find(i => i.id === parseInt(id))?.name : "";
  };
  
  const fieldTemplate = (id) => {
    return fields?.length > 0 ? fields?.find(i => i.id === parseInt(id))?.name : "";
  };

  const fromTemplate = (sr) => {
    return sr && sr?.stock_shop_id && (
      <>
        {sr?.stock_movement_list[0]?.stock_movement?.ondate && dateTemplate(sr?.stock_movement_list[0]?.stock_movement?.ondate)}
        <br />
        {sr?.stock_shop_id && shopTemplate(sr?.stock_shop_id)}
      </>
    );
  };

  const toTemplate = (sr) => {
    return sr && sr?.stock_movement_list[0] && sr?.stock_movement_list[0]?.stock_movement && (
      <>
        {sr?.progress === "completed"? dateTemplate(sr?.stock_movement[0]?.ondate) : <CompleteStockRepairModal sr={sr} />}
        <br />
        {sr?.stock_movement_list[0]?.stock_movement?.field_id && fieldTemplate(sr?.stock_movement_list[0]?.stock_movement?.field_id)}
      </>
    );
  };

  const itemTemplate = (sr) => {
    let sItem = sr?.stockids.split(",");;
    return (
      sItem && sItem?.length > 0 && sItem.map((value, index) => (
        <li key={index}>{value && stockTemplate(value)}</li>
      ))
    );
  };

  return (
    <>
      <Header />
      <div className="container bg-white content-container">
        <Navbar bg="none" variant="light">
          <Container>
            <Navbar.Collapse className="h5 d-flex justify-content-center">
              <Navbar.Text>Stock Repair</Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-12">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="visibleColumn">Column</label>
                      <MultiSelect
                        id="visibleColumn"
                        className="w-100"
                        value={visibleColumns.filter((col) => col.visible).map((col) => col.field)}
                        options={visibleColumns.map((col) => ({ label: col.header, value: col.field }))}
                        onChange={(e) => toggleColumnsVisibility(e.value)}
                        placeholder="Select Columns"
                      />
                    </div>
                  </div>
                </div>
                {
                  showLoading
                  ?
                    <div style={{display: "flex", justifyContent:"center", alignItems:"center", height: "50vh"}}>
                      <i className="pi pi-spin pi-spinner" style={{ fontSize: "7rem", color: "grey" }} />
                    </div>
                  : 
                    (
                      <>
                        <div className="row mb-2">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end small">
                          <span
                              className="me-3 link-isday"
                              onClick={() => {
                                userDevice === "Mobile" ? setVisibleColumns(mobileVisibleColumns) : setVisibleColumns(desktopVisibleColumns) ;
                              }}
                            >
                              reset column
                            </span>
                            {/* <span
                              className="me-3 link-isday"
                              onClick={() => {
                                setSelectedFilterField("");
                                setSelectedFilterEmployee("");
                                setTypeFilterNotes("");
                              }}
                            >
                              clear filter
                            </span> */}
                            <span onClick={refreshData} className="me-3 link-isday">refresh</span>
                          </div>
                        </div>
                        <hr />
                        <DataTable
                          value={stockRepair}
                          stripedRows
                          removableSort
                          rows={10}
                          columnResizeMode="expand"
                          resizableColumns
                          // filters={filters}
                          filterDisplay="row"
                          filterClearIcon={null}
                          dataKey="id"
                          emptyMessage="No files found."
                          sortField={sortField}
                          sortOrder={sortOrder}
                          onSort={updateSorting}
                        >
                          {
                            visibleColumns.map((col) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.field === 'edit_view' ? '' : col.header}
                                // filter={col.field === 'field_id' || col.field === 'employee_id' || col.field === 'notes'}
                                // filterElement={(rowData) => (col.field === 'field_id' ? fieldFilter(rowData) : (col.field === 'employee_id' ? employeeFilter(rowData) : (col.field === 'notes' ? notesFilter(rowData) : undefined)))}
                                body={(rowData) => (
                                  col.field === 'from' ?
                                    fromTemplate(rowData) :
                                      (
                                        col.field === 'to' ?
                                          toTemplate(rowData) :
                                            (
                                              col.field === 'items' ?
                                                itemTemplate(rowData) :
                                                  rowData[col.field]
                                            )
                                      )
                                  )
                                }
                                style={{
                                  display: col.visible ? 'table-cell' : 'none',
                                  whiteSpace: 'normal',
                                  maxWidth: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                                sortable={col.field !== 'stage' && col.field !== 'edit_view'}
                                showFilterMenu={false}
                              />
                            ))
                          }
                        </DataTable>
                        <Paginator first={startFromRecord} rows={rowsToFetch} totalRecords={stockRepair && stockRepair?.pagination ? stockRepair?.pagination.total_records : 10 } rowsPerPageOptions={[5, 10]} onPageChange={onPageChange} template={{ layout: "PrevPageLink PageLinks CurrentPageReport NextPageLink RowsPerPageDropdown" }} />
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockRepair;
