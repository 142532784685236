import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { StockContext, StockSingleContext, StockCategoryContext, StockShopContext, useToastContext } from "../../contexts";

const EditStockModal = (props) => {
  
  const { showToast } = useToastContext();
  
  const stockContext = useContext(StockContext);
  const stockSingleContext = useContext(StockSingleContext);
  const {stockSingle} = stockSingleContext;
  
  const stockCategoryContext = useContext(StockCategoryContext);
  const {stockCategory} = stockCategoryContext;
  const stockShopContext = useContext(StockShopContext);
  const {stockShop} = stockShopContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [stockId, setStockId] = useState("");

  const [typeName, setTypeName] = useState("");
  const [selectedStockCategory, setSelectedStockCategory] = useState("");
  const [typeKeyword, setTypeKeyword] = useState("");
  const [selectedStockShop, setSelectedStockShop] = useState("");

  useEffect(() => {
    async function fetchData() {
      if ( stockSingle && stockSingle.id ) {
        setStockId(stockSingle.id)
        setTypeName(stockSingle.name);
        setSelectedStockCategory(stockSingle.stock_category_id);
        setTypeKeyword(stockSingle.keyword);
        setSelectedStockShop(stockSingle.stock_shop_id);
      }
    }
    fetchData();
  }, [stockSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeName.trim()) {
      notif = "Name must not be empty.";
    } else if (!selectedStockCategory) {
      notif = "Category must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {

      let formData = new FormData();
      formData.append("name", typeName.trim());
      formData.append("stock_category_id", selectedStockCategory);
      if (typeKeyword.trim()) { formData.append("keyword", typeKeyword.trim()); }
      if (selectedStockShop) { formData.append("stock_shop_id", selectedStockShop); }

      const callAPI = await AxiosInstance.post(`/stocks/${stockId}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        stockContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        edit
      </span>

      <Dialog header="Edit" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Name"
            onChange={(_) => {
              setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeName}
            required
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="category">Stock Category</label>
          <select
            id="category"
            name="category"
            className="form-control"
            onChange={(_) => {
              setSelectedStockCategory(_.target.value);
            }}
            value={selectedStockCategory}
            required
          >
            <option value="">Please select</option>
            {
              stockCategory?.length > 0 && stockCategory?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mb-2">
          <label htmlFor="keyword">Keyword (comma separated)</label>
          <textarea
            id="keyword"
            name="keyword"
            placeholder="Keyword"
            rows="5"
            className="form-control"
            onChange={(_) => {
              setTypeKeyword(_.target.value);
            }}
            value={typeKeyword}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="shop">Shop</label>
          <select
            id="shop"
            name="shop"
            className="form-control"
            onChange={(_) => {
              setSelectedStockShop(_.target.value);
            }}
            value={selectedStockShop}
          >
            <option value="">Please select</option>
            {
              stockShop?.length > 0 && stockShop?.map((e, i) => (
                <option key={e.id} value={e.id}>{e.name}</option>
              ))
            }
          </select>
        </div>
      </Dialog>
    </>
  );
};

export default EditStockModal;
