const LoginUserEntries = async () => {

  if(localStorage.getItem("lue") && localStorage.getItem("lue") !== null && localStorage.getItem("lue") !== "") {
    return JSON.parse(localStorage.getItem("lue"));
  }

  return null;

}

export default LoginUserEntries;
