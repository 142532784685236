import React, { useContext, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, PositionContext, useToastContext } from "../../contexts";

const AddPositionModal = () => {
  
  const { showToast } = useToastContext();

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const positionContext = useContext(PositionContext);

  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [typeName, setTypeName] = useState("");
  const [selectedAccess, setSelectedAccess] = useState([]);
  const [selectedAbility, setSelectedAbility] = useState([]);
  const [selectedFor, setSelectedFor] = useState([]);
  
  const handleClose = () => {
    setDisableButton(false);
    setTypeName("");
    setSelectedAccess([]);
    setSelectedAbility([]);
    setShow(false);
  }

  const abilityChecklist = (slug) => {
    const newability = [...selectedAbility];
    const index = newability.indexOf(slug);
    if (index === -1) {
      newability.push(slug);
    } else {
      newability.splice(index, 1);
    }
    setSelectedAbility(newability);
  }

  const accessChecklist = (slug) => {
    const newaccess = [...selectedAccess];
    const index = newaccess.indexOf(slug);
    if (index === -1) {
      newaccess.push(slug);
    } else {
      newaccess.splice(index, 1);
    }
    setSelectedAccess(newaccess);
  }

  const forChecklist = (slug) => {
    const newfor = [...selectedFor];
    const index = newfor.indexOf(slug);
    if (index === -1) {
      newfor.push(slug);
    } else {
      newfor.splice(index, 1);
    }
    setSelectedFor(newfor);
  }

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!typeName) {
      notif = "Name must not be empty.";
    } else if (!selectedAccess) {
      notif = "Access must not be empty.";
    } else if (!selectedAbility) {
      notif = "Ability must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("name", typeName);
      formData.append("access_list", JSON.stringify(selectedAccess));
      formData.append("ability_to", JSON.stringify(selectedAbility));
      formData.append("use_for", JSON.stringify(selectedFor));

      const callAPI = await AxiosInstance.post("/positions", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        positionContext.refreshData();
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }

  };

  return (
    <>
      <span onClick={handleShow} className="link-isday">
        add new
      </span>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Name"
              onChange={(_) => {
                setTypeName(_.target.value.replace(/[^A-Za-z0-9_ .,!?/"-()]/gi, ""));
              }}
              value={typeName}
              required
              onKeyDown={e => (e.key === "Enter") && apiCall()}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="access">Access List</label>
            {
              allOption["typeOfUserAccess"]?.map((p, i) => (
                <div className="form-group mb-2 ms-2" key={i}>
                  <label>
                    <input type="checkbox" id={p.id} onClick={() => accessChecklist(p.id)} />
                    {p.name}
                  </label>
                </div>
              ))
            }       
          </div>
          <div className="form-group mb-2">
            <label htmlFor="ability">Ability To</label>
            {
              allOption["typeOfUserAbility"]?.map((p, i) => (
                <div className="form-group mb-2 ms-2" key={i}>
                  <label>
                    <input type="checkbox" id={p.id} onClick={() => abilityChecklist(p.id)} />
                    {p.name}
                  </label>
                </div>
              ))
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPositionModal;
