import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.css";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { AllContext, EmployeeContext, MachineContext, StockMovementSingleContext, FieldContext, StockContext, StockShopContext, useToastContext } from "../../contexts";

const EditStockMovementListModal = (props) => {
  
  const { showToast } = useToastContext();

  const stockMovementSingleContext = useContext(StockMovementSingleContext);
  const {stockMovementSingle} = stockMovementSingleContext;

  const allContext = useContext(AllContext);
  const {allOption} = allContext;
  const fieldContext = useContext(FieldContext);
  const {fields} = fieldContext;
  const stockContext = useContext(StockContext);
  const {stocks} = stockContext;
  const stockShopContext = useContext(StockShopContext);
  const {stockShop} = stockShopContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;
  const machineContext = useContext(MachineContext);
  const {machines} = machineContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [smId, setSMId] = useState("");
  const [smStage, setSMStage] = useState("");
  const [smField, setSMField] = useState("");

  const [smlid, setSmlid] = useState("");
  const [selectedStock, setSelectedStock] = useState("");
  const [typeQty, setTypeQty] = useState(0);
  const [selectedBarbut, setSelectedBarbut] = useState(0);
  const [typeNotes, setTypeNotes] = useState("");
  const [selectedFor, setSelectedFor] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedStockShop, setSelectedStockShop] = useState("");
  const [selectedForp, setSelectedForp] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedMachine, setSelectedMachine] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      setSelectedStock(props && props.sml && props.sml.stock_id && stocks && stocks?.find(i => i.id === parseInt(props.sml.stock_id)) ? stocks?.find(i => i.id === parseInt(props.sml.stock_id)) : "");
      setTypeQty(props && props.sml && props.sml.qty ? props.sml.qty : 0);
      setSelectedBarbut(props && props.sml && props.sml.barbut && props.sml.barbut === "y" ? 1 : 0);
      setTypeNotes(props && props.sml && props.sml.notes ? props.sml.notes : "");

      setSmlid(props && props.sml && props.sml.id ? props.sml.id : "");
      setSelectedFor(props && props.sml && props.sml.for ? props.sml.for : "");
      setSelectedField(props && props.sml && props.sml.transfer_to ? props.sml.transfer_to : "");
      setSelectedStockShop(props && props.sml && props.sml.shop ? props.sml.shop : "");

      setSelectedForp(props && props.sml && props.sml.pfor ? props.sml.pfor : "");
      setSelectedEmployee(props && props.sml && props.sml.pemp ? props.sml.pemp : "");
      setSelectedMachine(props && props.sml && props.sml.pvec ? props.sml.pvec : "");

    }
    fetchData();
  }, [props]);
  
  useEffect(() => {
    async function fetchData() {
      setSMId(stockMovementSingle && stockMovementSingle.id ? stockMovementSingle.id : "");
      setSMStage(stockMovementSingle && stockMovementSingle.stage ? stockMovementSingle.stage : "");
      setSMField(stockMovementSingle && stockMovementSingle.field_id ? stockMovementSingle.field_id : "");
    }
    fetchData();
  }, [stockMovementSingle]);

  // Template
  const valueTemplate = (stock, props) => {
    if (stock) {
      return (
        <div className="flex align-items-center">
            <div>{stock.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemTemplate = (stock) => {
    return (
      <div className="flex align-items-center">
          <div>{stock.name}</div>
      </div>
    );
  };

  const apiCall = async () => {

    console.log("cc" + selectedFor);

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!smlid) {
      notif = "Please refresh the page.";
    } else if (!selectedStock) {
      notif = "Stock must not be be empty.";
    } else if (!typeQty) {
      if (selectedFor === "stockmovementforpetrol") {
        if (!selectedForp) {
          notif = "Fill For must not be empty.";
        } else {
          if (selectedForp === "employees") {
            if (!selectedEmployee) {
              notif = "Employee must not be empty.";
            } else {
              validateDetails = true;
            }
          } else if (selectedForp === "machines") {
            if (!selectedMachine) {
              notif = "Machine must not be empty.";
            } else {
              validateDetails = true;
            }
          } else {
            validateDetails = true;
          }
        }
      } else if (selectedFor === "stockmovementfortransfer") {
        if (!selectedField) {
          notif = "Transfer To must not be empty.";
        } else {
          validateDetails = true;
        }
      } else if (selectedFor === "stockmovementforrepair") {
        if (!selectedStockShop) {
          notif = "Repair Shop must not be empty.";
        } else {
          validateDetails = true;
        }
      } else {
        validateDetails = true;
      }
    } else {
      validateDetails = true;
    }

    if(validateDetails){
      let formData = new FormData();
      formData.append("stock_movement_id", smId);
      formData.append("stock_id", selectedStock.id);
      formData.append("qty", typeQty);
      if (typeNotes) { formData.append("notes", typeNotes); }
      if (selectedBarbut) { formData.append("barbut", selectedBarbut); }
      if (typeQty < 0) {
        if (selectedFor) { formData.append("for", selectedFor); } else { formData.append("for", ""); }
        if (selectedFor === "stockmovementforpetrol") {
          formData.append("forp", selectedForp);
          formData.append("employee_id", selectedEmployee);
          formData.append("machine_id", selectedMachine);
        } else if (selectedFor === "stockmovementfortransfer") {
          formData.append("field_id_to", selectedField);
        } else if (selectedFor === "stockmovementforrepair") {
          formData.append("stock_shop_id", selectedStockShop);
        } else {
          // nothing
        }
      }

      const callAPI = await AxiosInstance.post(`/stockmovementlist/${smlid}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  const changeFor = () => {
    setSelectedEmployee("");
    setSelectedMachine("");
    setSelectedField("");
    setSelectedStockShop("");
  }

  return (
    <>
      {
        smStage !== "approved" && smStage !== "approvedbysystem" &&
        (
          <span onClick={handleShow} className="link-isday me-3">
            edit
          </span>
        )
      }

      <Dialog header="Edit" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label>Stock{selectedFor}</label>
          <Dropdown
            className="form-control"
            value={selectedStock}
            options={stocks}
            onChange={(_) => {
              let ss = _.target.value;
              setSelectedStock(ss);
              if (ss.type && ss.type === "stockcategorypetrol" && typeQty < 0) {
                setSelectedFor("stockmovementforpetrol");
                changeFor();
              } else {
                setSelectedFor("");
                changeFor();
              }
            }}
            optionLabel="name"
            filter
            filterBy="name"
            placeholder="Search by Stock"
            valueTemplate={valueTemplate} 
            itemTemplate={itemTemplate}
            required
          />
        </div>
        <div className="form-group mb-2">
          <label htmlFor="qty">Qty</label>
          <input
            type="number"
            className={"form-control " + (typeQty < 0 ? "border-danger" : "") }
            id="qty"
            name="qty"
            placeholder="Qty"
            onChange={(_) => {
              let qty = _.target.value.replace(/[^0-9-.]/gi, "");
              let qtyint = parseFloat(qty);
              setTypeQty(qtyint);
              setSelectedBarbut("");
              if (selectedStock && selectedStock.type && selectedStock.type === "stockcategorypetrol" && qtyint < 0) {
                setSelectedFor("stockmovementforpetrol");
                changeFor();
              } else {
                setSelectedFor("");
                changeFor();
              }
            }}
            value={typeQty}
            required
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
        {
          typeQty < 0 && (
            <div className="form-group mb-2">
              <label htmlFor="for">For ( if other, leave it blank )</label>
              <select
                id="for"
                name="for"
                className="form-control"
                onChange={(_) => {
                  setSelectedFor(_.target.value);
                  setSelectedForp("");
                  setSelectedBarbut("");
                  changeFor();
                  console.log("ab" + _.target.value);
                }}
                value={selectedFor}
              >
                <option value="">Please select</option>
                {
                  allOption["typeOfStockMovementFor"]?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                }
              </select>
            </div>
          )
        }
        {
          (selectedFor === "stockmovementforpetrol") && (
            <div className="form-group mb-2">
              <label htmlFor="forp">Fill For ( if other, leave it blank )</label>
              <select
                id="forp"
                name="forp"
                className="form-control"
                onChange={(_) => {
                  setSelectedForp(_.target.value);
                }}
                value={selectedForp}
                required
              >
                <option value="">Please select</option>
                {
                  allOption["typeOfStockPetrolFor"]?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                }
              </select>
            </div>
          )
        }
        {
          (selectedForp === "employees") && (
            <div className="form-group mb-2">
              <label htmlFor="employee">Employee</label>
              <select
                id="employee"
                name="employee"
                className="form-control"
                onChange={(_) => {
                  setSelectedEmployee(_.target.value);
                }}
                value={selectedEmployee}
                required
              >
                <option value="">Please select</option>
                {
                  employees?.length > 0 && employees?.filter(obj => parseInt(obj.field_id) === parseInt(smField)).map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                }
              </select>
            </div>
          )
        }
        {
          (selectedForp === "machines") && (
            <div className="form-group mb-2">
              <label htmlFor="machine">Machine</label>
              <select
                id="machine"
                name="machine"
                className="form-control"
                onChange={(_) => {
                  setSelectedMachine(_.target.value);
                }}
                value={selectedMachine}
                required
              >
                <option value="">Please select</option>
                {
                  machines?.length > 0 && machines?.filter(obj => parseInt(obj.field_id) === parseInt(smField)).map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                }
              </select>
            </div>
          )
        }
        {
          (selectedFor === "stockmovementfortransfer") && (
            <div className="form-group mb-2">
              <label htmlFor="field">Transfer To</label>
              <select
                id="field"
                name="field"
                className="form-control"
                onChange={(_) => {
                  setSelectedField(_.target.value);
                }}
                value={selectedField}
                required
              >
                <option value="">Please select</option>
                {
                  fields?.length > 0 && fields?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                }
              </select>
            </div>
          )
        }
        {
          (selectedFor === "stockmovementforrepair") && (
            <div className="form-group mb-2">
              <label htmlFor="shop">Repair Shop</label>
              <select
                id="shop"
                name="shop"
                className="form-control"
                onChange={(_) => {
                  setSelectedStockShop(_.target.value);
                }}
                value={selectedStockShop}
                required
              >
                <option value="">Please select</option>
                {
                  stockShop?.length > 0 && stockShop?.map((e, i) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))
                }
              </select>
            </div>
          )
        }
        {
          typeQty < 0 && (
            <div className="form-group mb-2">
              <label htmlFor="access">Barbut</label>
              <div className="form-group mb-2 ms-2">
                <label>
                  <input
                    type="checkbox"
                    id="barbut"
                    checked={selectedBarbut}
                    onClick={(_) => {
                      setSelectedBarbut(_.target.checked);
                    }}
                  />
                  Yes - I will remember to upload the photos !!!
                </label>
              </div>     
            </div>
          )
        }
        <div className="form-group mb-2">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder="Notes"
            onChange={(_) => {
              setTypeNotes(_.target.value.replace(/[^A-Za-z0-9_ .,!?"-()]/gi, ""));
            }}
            value={typeNotes}
            onKeyDown={e => (e.key === "Enter") && apiCall()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditStockMovementListModal;
