import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, FileSingleContext, useToastContext } from "../../contexts";

const AddFileImageModal = (props) => {
  
  const { showToast } = useToastContext();

  const fileSingleContext = useContext(FileSingleContext);
  const {fileSingle} = fileSingleContext;
  
  const allContext = useContext(AllContext);
  const {allOption} = allContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);
  
  const [fileId, setFileId] = useState("");
  const [fileStage, setFileStage] = useState("");
  const [fileImage, setFileImage] = useState("");
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    function fetchData() {
      if ( fileSingle && fileSingle.id ) {
        setFileId(fileSingle.id);
        setFileStage(fileSingle.stage);
      }
    }
    fetchData();
  }, [fileSingle]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!fileId) {
      notif = "Please refresh the page.";
    } else if (!fileImage) {
      notif = "Image must not be empty.";
    } else if (!selectedType) {
      notif = "Type must not be empty.";
    } else {
      validateDetails = true;
    }

    if (validateDetails) {
      let formData = new FormData();
      formData.append("file_id", fileId);
      formData.append("fimage", fileImage);
      formData.append("type", selectedType);

      const callAPI = await AxiosInstance.post("/fileimage", formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (callAPI) {
        handleClose();
      } else {
        setDisableButton(false);
      }
    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setDisableButton(false);
    setFileImage("");
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()} className="mt-4">
        Submit
      </Button>
    </div>
  );

  return (
    <>
      {
        fileStage !== "approved" && fileStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            add
          </span>
        )
      }

      <Dialog header="Add" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="type">File Category</label>
          <select
            id="type"
            name="type"
            className="form-control"
            onChange={(_) => {
              setSelectedType(_.target.value);
            }}
            value={selectedType}
            required
          >
            <option value="">Please select</option>
            {
              allOption["typeOfFile"]?.map((p, i) => (
                <option key={p.id} value={p.id}>{p.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group mt-4 mb-2">
          <label htmlFor="category">Image</label>
        </div>
        <div className="form-group mb-2">
          <input
            type="file"
            onChange={(_) => {
              setFileImage(_.target.files[0]);
            }} 
          />
        </div>
      </Dialog>
    </>
  );
};

export default AddFileImageModal;
