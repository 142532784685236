import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../axios";
import Button from "react-bootstrap/Button";
import { Dialog } from "primereact/dialog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import { AllContext, EmployeeContext, PositionContext, TripSingleContext, useToastContext } from "../../contexts";

const DeleteTripLoaderModal = (props) => {
  
  const { showToast } = useToastContext();

  const tripSingleContext = useContext(TripSingleContext);
  const {tripSingle} = tripSingleContext;

  const allContext = useContext(AllContext);
  const {allFormula} = allContext;
  const positionContext = useContext(PositionContext);
  const {positions} = positionContext;
  const employeeContext = useContext(EmployeeContext);
  const {employees} = employeeContext;

  const [disableButton, setDisableButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleShow = () => setVisible(true);

  const [tripId, setTripId] = useState("");
  const [tripStage, setTripStage] = useState("");
  
  const [tlid, settlid] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedAction, setSelectedAction] = useState("");

  useEffect(() => {
    async function fetchData() {
      setTripId(tripSingle && tripSingle.id ? tripSingle.id : "");
      setTripStage(tripSingle && tripSingle.stage ? tripSingle.stage : "");
    }
    fetchData();
  }, [tripSingle]);

  useEffect(() => {
    async function fetchData() {
      settlid(props && props.tl && props.tl.id ? props.tl.id : "");
      setSelectedEmployee(props && props.tl && props.tl.employee_id ? employees?.find(i => i.id === parseInt(props.tl.employee_id)) : "");
      setSelectedAction(props && props.tl && props.tl.action ? props.tl.action : "");
    }
    fetchData();
  }, [props, props.tl]);

  const apiCall = async () => {

    setDisableButton(true);
    let validateDetails = false;
    let notif = "";

    if (!tlid) {
      notif = "Unable to continue this process";
    } else if (!selectedEmployee) {
      notif = "Employee must not be empty.";
    } else if (!selectedAction) {
      notif = "Action must not be empty.";
    } else {
      validateDetails = true;
    }

    if(validateDetails){

      let formData = new FormData();
      formData.append("deltl", "yes");
      formData.append("trip_id", tripId);

      const addWork = await AxiosInstance.post(`/triploader/${tlid}`, formData )
        .catch((error) => {
          let notif = error && error?.response && error?.response?.data && error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong, Please try after sometime";
          showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
          return false;
        });
      
      if (addWork) {
        handleClose();
      } else {
        setDisableButton(false);
      }

    } else {
      showToast({severity: "info", summary: "Info", detail: notif, life: 3000});
      setDisableButton(false);
    }
  };

  const handleClose = () => {
    props.sRC(Math.round((new Date()).getTime()));
    setSelectedEmployee("");
    setSelectedAction("");
    setDisableButton(false);
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button variant="primary" disabled={disableButton} onClick={() => apiCall()}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      {
        tripStage !== "approved" && tripStage !== "approvedbysystem" && 
        (
          <span onClick={handleShow} className="link-isday">
            del
          </span>
        )
      }

      <Dialog header="Delete" visible={visible} style={{ minWidth: "30vw" }} onHide={() => handleClose()} footer={footerContent}>
        <div className="form-group mb-2">
          <label htmlFor="name">
            Remove {selectedAction && allFormula[selectedAction]?.name} by {employees && selectedEmployee && selectedEmployee?.name + (positions?.find(i => i.id === parseInt(selectedEmployee.position_id)) ? ' ( ' + positions?.find(i => i.id === parseInt(selectedEmployee.position_id))?.name + ' )' : '' )}?
          </label>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteTripLoaderModal;
